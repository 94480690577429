import React from "react";
import Navbar from "../../components/navbar/navbar";
import { Box, Image, Flex, Text } from "@chakra-ui/react";
import Footer from "../../components/footer/footer";
import DefaultImage from "../../assets/voucher.png";
import MainLayout from "../../components/mainLayout/mainLayout";
import { useState } from "react";
const Wishlist = () => {
  const [searchValue, setSearchValue] = useState("");
  return (
    <MainLayout searchValue={searchValue} setSearchValue={setSearchValue}>
      <Box mt="120px">
        <Box>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Image
              src={DefaultImage}
              height="222px"
              width="222px"
              alt="Not available"
              mb="1rem"
              mt="2rem"
            />
            <Text fontWeight={"400"} fontSize={"14px"} color="#999999">
              Wishlist is currently not available now,
            </Text>
            <Text
              fontWeight={"400"}
              fontSize={"14px"}
              color="#999999"
              mb="64px"
            >
              kindly check back later.
            </Text>
          </Flex>
        </Box>

        <Footer />
      </Box>
    </MainLayout>
  );
};

export default Wishlist;
