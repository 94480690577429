import React, {useEffect} from 'react';
import {
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import Login from '../../assets/attention.png';

const PrepaidLoginModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = () => {
    const { imageUrl } = location.state || {};
    navigate('/login', { state: { from: location, imageUrl } });
    onClose(); 
  };



  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" justifyContent="center">
          <Image src={Login} height="90px" width="90px" />
        </ModalHeader>
        <ModalBody
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          <Text fontWeight="700" fontSize="24px">
            Attention!
          </Text>
          <Text fontWeight="400" fontSize="16px">
            {' '}
            Kindly log in or create an account to
          </Text>
          <Text>purchase airtime or pay bills.</Text>
          <Text>Click on the button below to log in now.</Text>
        </ModalBody>
        <ModalFooter
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          <Button
            bgColor="#002E99"
            textColor="white"
            onClick={handleLogin}
            mb={3}
            width="100%"
          >
            Log in now
          </Button>
          <Button
            bgColor="white"
            border="1px solid #999999"
            textColor="#999999"
            onClick={onClose}
            width="100%"
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PrepaidLoginModal;
