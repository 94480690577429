import { useState, useEffect } from "react";
import {
  Box,
  Text,
  Heading,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import axiosInstance from "../../../services/api";
import CustomInput2 from "../../../components/customInput/customInput";
import PrimaryButton from "../../../components/primaryButton/primarybutton";

function getLastTenDigits(phoneNumber) {

  const digitsOnly = phoneNumber.replace(/\D/g, "");
  
  const lastTenDigits = digitsOnly.slice(-10);

  return lastTenDigits;
}
const purchaseType = ["AIRTIME", "DATA", "VOUCHER"];

const Beneficiary2 = ({ onSelect, phoneNumber, onClose }) => {
  const toast = useToast();
  const [newBeneficiary, setNewBeneficiary] = useState({
    phoneNumber: `234${getLastTenDigits(phoneNumber)}`,
    fullName: "",
    type: purchaseType[0],
  });
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    setNewBeneficiary({
      ...newBeneficiary,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    try {
      await axiosInstance.post("/vas/beneficiary", newBeneficiary);
      onSelect();
      setShowModal(true);
    } catch (error) {
      toast({
        description: "Failed to save beneficiary",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <Box
      maxWidth="100%"
      height="100%"
      width="100%"
      textAlign="left"
      p="18px"
    >
      <Text
        fontWeight="700"
        mt={["20px", "40px"]}
        fontSize={["18px", "20px"]}
        lineHeight="30px"
      >
        Save Beneficiary
      </Text>
      <Box my="10px">
        <Text textColor="#999999" fontSize="12px" fontWeight="400">
          Phone number
        </Text>
        <CustomInput2
          type="tel"
          name="phoneNumber"
          placeholder="Phone Number"
          bg="#F5F8FF"
          onChange={handleChange}
          value={newBeneficiary.phoneNumber}
        />
      </Box>
      <Box>
        <Text textColor="#999999" fontSize="12px" fontWeight="400">
          Name
        </Text>
        <CustomInput2
          type="text"
          name="fullName"
          placeholder="Full Name"
          bg="#F5F8FF"
          onChange={handleChange}
          value={newBeneficiary.fullName}
        />
      </Box>
      <Box my="10px" mt={["20px", "30px"]}>
        <Button
          onClick={handleSave}
          bg="#002E99"
          width="100%"
          textColor="white"
          fontWeight="700"
          fontSize="16px"
          lineHeight="18px"
        >
          Save Beneficiary
        </Button>
      </Box>
      <Box my="10px">
        <Button
          bg="white"
          border="1px solid #999999"
          width="100%"
          textColor="#999999"
          fontWeight="700"
          fontSize="16px"
          lineHeight="18px"
          onClick={onClose}
        >
          Cancel
        </Button>
      </Box>
      <Modal isOpen={showModal} onClose={closeModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Beneficiary Added Successfully</ModalHeader>
          <ModalBody>
            <Text>Your beneficiary has been added successfully!</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" borderColor="#002E99" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Beneficiary2;
