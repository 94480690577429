import { Input, InputGroup, InputLeftElement, Box } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";

export default function SearchBar() {
  return (
    <InputGroup
      width="240px"
      alignItems="center"
      bg="#F5F8FF"
      marginRight="24px"
      borderRadius="20px"
      padding="4px"
    >
      <Box position="relative" width="20px" height="20px" ml="4px">
        <Image src="/images/Searchicon.png" alt="Search Icon" layout="fill" objectFit="contain" />
      </Box>
      <Input
        type="text"
        fontSize="14px"
        placeholder="Search anything"
        borderRadius="20px"
        border="none"
        height="32px"
        paddingLeft="32px" // Adjust padding to make room for the icon
        bg="transparent"
      />
    </InputGroup>
  );
}
