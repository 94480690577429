import axios from 'axios';

const baseURL = process.env.REACT_APP_PORTESHOP_URL;

const axiosInstance3 = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': 'Content-Type',
  },
});

const addTokenToRequest = async (req) => {
  const token = localStorage.getItem('GPV1TK');
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
};

axiosInstance3.interceptors.request.use(addTokenToRequest);

export default axiosInstance3;
