import React from "react";
import { Tabs, TabList, Tab, TabPanel, TabPanels, TabIndicator, Flex, Box, Grid, Text, Divider } from "@chakra-ui/react";
import SpinLoader from "../../../../components/spinLoader/spinLoader";
import { formatWholeNumber } from "../../../../utils/toLocaleString";
import { truncateText } from "../../../../utils/helper";

const DataTabs = ({selectedBiller, loading2, plans, handleBuy, borderTop}) => {
  return (
    <div>
      <Tabs>
        <TabList
          border={"none"}
          gap="1rem"
          mb="1rem"
          overflowX="scroll"
          sx={{
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Tab
            px="auto"
            color="#999999"
            fontWeight={"700"}
            fontSize={"14px"}
            _selected={{
              color: "#002E99",
              fontWeight: "600",
              borderBottom: "3px solid #002E99",
            }}
          >
            Daily
          </Tab>
          <Tab
            px="auto"
            color="#999999"
            fontWeight={"700"}
            fontSize={"14px"}
            _selected={{
              color: "#002E99",
              fontWeight: "600",
              borderBottom: "3px solid #002E99",
            }}
          >
            Weekly
          </Tab>
          {selectedBiller?.attributes?.name === "GLO" ||
          selectedBiller === "GLO" ? (
            <Tab
              px="auto"
              color="#999999"
              fontWeight={"700"}
              fontSize={"14px"}
              _selected={{
                color: "#002E99",
                fontWeight: "600",
                borderBottom: "3px solid #002E99",
              }}
            >
              Special
            </Tab>
          ) : null}
          <Tab
            px="auto"
            color="#999999"
            fontWeight={"700"}
            fontSize={"14px"}
            _selected={{
              color: "#002E99",
              fontWeight: "600",
              borderBottom: "3px solid #002E99",
            }}
          >
            Monthly
          </Tab>
          <Tab
            px="auto"
            whiteSpace={"nowrap"}
            color="#999999"
            fontWeight={"700"}
            fontSize={"14px"}
            _selected={{
              color: "#002E99",
              fontWeight: "600",
              borderBottom: "3px solid #002E99",
            }}
          >
            2-Months
          </Tab>
          <Tab
            px="auto"
            whiteSpace={"nowrap"}
            color="#999999"
            fontWeight={"700"}
            fontSize={"14px"}
            _selected={{
              color: "#002E99",
              fontWeight: "600",
              borderBottom: "3px solid #002E99",
            }}
          >
            3-Months
          </Tab>
          <Tab
            px="auto"
            whiteSpace={"nowrap"}
            color="#999999"
            fontWeight={"700"}
            fontSize={"14px"}
            _selected={{
              color: "#002E99",
              fontWeight: "600",
              borderBottom: "3px solid #002E99",
            }}
          >
            6-Months
          </Tab>
          <Tab
            px="auto"
            whiteSpace={"nowrap"}
            color="#999999"
            fontWeight={"700"}
            fontSize={"14px"}
            _selected={{
              color: "#002E99",
              fontWeight: "600",
              borderBottom: "3px solid #002E99",
            }}
          >
            {selectedBiller?.attributes?.name === "GLO" ||
            selectedBiller === "GLO"
              ? "Mega"
              : "Yearly"}
          </Tab>
        </TabList>
        <TabIndicator
        // mt="-1.5px"
        // height="2px"
        // bg="#02BAF1"
        // borderRadius="2px"
        />
        <TabPanels mt="1.5rem">
          <TabPanel p="0" w="100%">
            {loading2 ? (
              <SpinLoader color={"#000"} />
            ) : (
              <Grid
                templateColumns={[
                  "repeat(auto-fill, minmax(100px, 1fr))",
                  "repeat(auto-fill, minmax(120px, 1fr))",
                ]}
                gap={"10px"}
              >
                {plans
                  ?.filter(
                    (i) =>
                      i?.attributes?.name?.includes("1day") ||
                      i?.attributes?.name?.includes("2 days") ||
                      i?.attributes?.name?.includes("3 days")
                  )
                  ?.map(({ id, type, attributes }) => {
                    return (
                      <Flex
                        key={id}
                        onClick={() => handleBuy({ id, type, attributes })}
                        cursor="pointer"
                        height={["130px", "130px"]}
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="8px"
                        bg="#F5F8FF"
                        borderTop={borderTop}
                      >
                        <Box textAlign="center">
                          {selectedBiller?.attributes?.name === "Airtel" ? (
                            <Text
                              color="#002F3D"
                              fontSize={["1rem", "1.2rem"]}
                              fontWeight="600"
                            >
                              {truncateText(
                                attributes?.name?.split(" ")[3],
                                14
                              ) ||
                                truncateText(
                                  attributes?.name?.split(" ")[4],
                                  14
                                )}
                            </Text>
                          ) : selectedBiller?.attributes?.name === "GLO" ? (
                            <Text
                              color="#002F3D"
                              fontSize={["0.7rem", "1rem"]}
                              fontWeight="600"
                              noOfLines={3}
                              whiteSpace="normal"
                              wordBreak="break-word"
                            >
                              {truncateText(attributes?.name, 8)}
                            </Text>
                          ) : (
                            <Text
                              color="#002F3D"
                              fontSize={["0.8rem", "1rem"]}
                              fontWeight="600"
                              noOfLines={3}
                              whiteSpace="normal"
                              wordBreak="break-word"
                            >
                              {attributes?.name?.split(" ")[0]?.includes("Xtra")
                                ? truncateText(
                                    attributes?.name?.split(" ")[1],
                                    14
                                  )
                                : truncateText(
                                    attributes?.name?.split(" ")[0],
                                    14
                                  )}
                            </Text>
                          )}
                          <Text
                            mt=".5rem"
                            color="#000119"
                            fontSize={[".6rem", ".9rem"]}
                          >
                            1 Day
                          </Text>
                          <Divider mt=".6rem" />
                          <Text
                            mt=".5rem"
                            fontWeight={"700"}
                            color="#002E99"
                            fontSize={[".8rem", "1rem"]}
                          >
                            {attributes?.price?.currency}
                            {formatWholeNumber(
                              attributes?.price?.maximumAmount
                            )}
                          </Text>
                        </Box>
                      </Flex>
                    );
                  })}
              </Grid>
            )}
          </TabPanel>

          <TabPanel p="0">
            {loading2 ? (
              <SpinLoader />
            ) : (
              <Grid
                templateColumns={[
                  "repeat(auto-fill, minmax(100px, 1fr))",
                  "repeat(auto-fill, minmax(120px, 1fr))",
                ]}
                gap={"10px"}
              >
                {plans
                  ?.filter(
                    (i) =>
                      i?.attributes?.name?.includes("7 days") ||
                      i?.attributes?.name?.includes("Weekly") ||
                      i?.attributes?.name?.includes("7days")
                  )
                  ?.map(({ id, type, attributes }) => {
                    return (
                      <Flex
                        key={id}
                        onClick={() => handleBuy({ id, type, attributes })}
                        cursor="pointer"
                        height={["130px", "130px"]}
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="8px"
                        bg="#F5F8FF"
                        borderTop={borderTop}
                      >
                        <Box textAlign="center">
                          {selectedBiller?.attributes?.name === "Airtel" ? (
                            <Text
                              color="#002F3D"
                              fontSize={["1rem", "1.2rem"]}
                              fontWeight="600"
                            >
                              {truncateText(
                                attributes?.name?.split(" ")[3],
                                14
                              ) ||
                                truncateText(
                                  attributes?.name?.split(" ")[4],
                                  14
                                )}
                            </Text>
                          ) : selectedBiller?.attributes?.name === "GLO" ? (
                            <Text
                              color="#002F3D"
                              fontSize={["0.8rem", "1rem"]}
                              fontWeight="600"
                              noOfLines={3}
                              whiteSpace="normal"
                              wordBreak="break-word"
                            >
                              {truncateText(attributes?.name, 8)}
                            </Text>
                          ) : (
                            <Text
                              color="#002F3D"
                              fontSize={["0.8rem", "1rem"]}
                              fontWeight="600"
                              noOfLines={3}
                              whiteSpace="normal"
                              wordBreak="break-word"
                            >
                              {attributes?.name?.split(" ")[0]?.includes("Xtra")
                                ? truncateText(
                                    attributes?.name?.split(" ")[1],
                                    14
                                  )
                                : truncateText(
                                    attributes?.name?.split(" ")[0],
                                    14
                                  )}
                            </Text>
                          )}
                          <Text
                            mt=".5rem"
                            color="#000119"
                            fontSize={[".6rem", ".9rem"]}
                          >
                            7 Days
                          </Text>
                          <Divider mt=".6rem" />
                          <Text
                            mt=".5rem"
                            fontWeight={"700"}
                            color="#002E99"
                            fontSize={[".8rem", "1rem"]}
                          >
                            {attributes?.price?.currency}
                            {formatWholeNumber(
                              attributes?.price?.maximumAmount
                            )}
                          </Text>
                        </Box>
                      </Flex>
                    );
                  })}
              </Grid>
            )}
          </TabPanel>

          {/* special */}
          {selectedBiller?.attributes?.name === "GLO" ||
          selectedBiller === "GLO" ? (
            <TabPanel p="0" w="100%">
              {loading2 ? (
                <SpinLoader color={"#000"} />
              ) : (
                <Grid
                  templateColumns={[
                    "repeat(auto-fill, minmax(100px, 1fr))",
                    "repeat(auto-fill, minmax(120px, 1fr))",
                  ]}
                  gap={"10px"}
                >
                  {plans
                    ?.filter((i) => i?.attributes?.name?.includes("Special"))
                    ?.map(({ id, type, attributes }) => {
                      return (
                        <Flex
                          key={id}
                          onClick={() => handleBuy({ id, type, attributes })}
                          cursor="pointer"
                          height={["130px", "130px"]}
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="8px"
                          bg="#F5F8FF"
                          borderTop={borderTop}
                        >
                          <Box textAlign="center">
                            {selectedBiller?.attributes?.name === "Airtel" ? (
                              <Text
                                color="#002F3D"
                                fontSize={["1rem", "1.2rem"]}
                                fontWeight="600"
                              >
                                {truncateText(
                                  attributes?.name?.split(" ")[3],
                                  14
                                ) ||
                                  truncateText(
                                    attributes?.name?.split(" ")[4],
                                    14
                                  )}
                              </Text>
                            ) : selectedBiller?.attributes?.name === "GLO" ? (
                              <Text
                                color="#002F3D"
                                fontSize={["0.7rem", ".8rem"]}
                                fontWeight="600"
                                noOfLines={3}
                                whiteSpace="normal"
                                wordBreak="break-word"
                              >
                                {attributes?.name}
                              </Text>
                            ) : (
                              <Text
                                color="#002F3D"
                                fontSize={["0.5rem", "0.8rem"]}
                                fontWeight="600"
                                noOfLines={3}
                                whiteSpace="normal"
                                wordBreak="break-word"
                              >
                                {attributes?.name
                                  ?.split(" ")[0]
                                  ?.includes("Xtra")
                                  ? truncateText(
                                      attributes?.name?.split(" ")[1],
                                      14
                                    )
                                  : attributes?.name}
                              </Text>
                            )}
                            <Text
                              mt=".5rem"
                              color="#000119"
                              fontSize={[".6rem", ".9rem"]}
                            >
                              1 Day
                            </Text>
                            <Divider mt=".6rem" />
                            <Text
                              mt=".5rem"
                              fontWeight={"700"}
                              color="#002E99"
                              fontSize={[".8rem", "1rem"]}
                            >
                              {attributes?.price?.currency}
                              {formatWholeNumber(
                                attributes?.price?.maximumAmount
                              )}
                            </Text>
                          </Box>
                        </Flex>
                      );
                    })}
                </Grid>
              )}
            </TabPanel>
          ) : null}

          {/*  */}
          <TabPanel p="0" w="100%">
            {loading2 ? (
              <SpinLoader />
            ) : (
              <Grid
                templateColumns={[
                  "repeat(auto-fill, minmax(100px, 1fr))",
                  "repeat(auto-fill, minmax(120px, 1fr))",
                ]}
                gap={"10px"}
              >
                {plans
                  ?.filter(
                    (i) =>
                      i?.attributes?.name?.includes("30 days") ||
                      i?.attributes?.name?.includes("Monthly") ||
                      i?.attributes?.name?.includes("30days")
                  )
                  ?.map(({ id, type, attributes }) => {
                    return (
                      <Flex
                        key={id}
                        onClick={() => handleBuy({ id, type, attributes })}
                        cursor="pointer"
                        height={["130px", "130px"]}
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="8px"
                        bg="#F5F8FF"
                        borderTop={borderTop}
                      >
                        <Box textAlign="center">
                          {selectedBiller?.attributes?.name === "Airtel" ? (
                            <Text
                              color="#002F3D"
                              fontSize={["1rem", "1.2rem"]}
                              fontWeight="600"
                            >
                              {truncateText(
                                attributes?.name?.split(" ")[3],
                                14
                              ) ||
                                truncateText(
                                  attributes?.name?.split(" ")[4],
                                  14
                                )}
                            </Text>
                          ) : selectedBiller?.attributes?.name === "GLO" ? (
                            <Text
                              color="#002F3D"
                              fontSize={["0.8rem", "1rem"]}
                              fontWeight="600"
                              noOfLines={3}
                              whiteSpace="normal"
                              wordBreak="break-word"
                            >
                              {truncateText(attributes?.name, 8)}
                            </Text>
                          ) : (
                            <Text
                              color="#002F3D"
                              fontSize={["0.8rem", "1rem"]}
                              fontWeight="600"
                              noOfLines={3}
                              whiteSpace="normal"
                              wordBreak="break-word"
                            >
                              {attributes?.name?.split(" ")[0]?.includes("Xtra")
                                ? truncateText(
                                    attributes?.name?.split(" ")[1],
                                    14
                                  )
                                : truncateText(
                                    attributes?.name?.split(" ")[0],
                                    14
                                  )}
                            </Text>
                          )}
                          <Text
                            mt=".5rem"
                            color="#000119"
                            fontSize={[".6rem", ".9rem"]}
                          >
                            30 Days
                          </Text>
                          <Divider mt=".6rem" />
                          <Text
                            mt=".5rem"
                            fontWeight={"700"}
                            color="#002E99"
                            fontSize={[".8rem", "1rem"]}
                          >
                            {attributes?.price?.currency}
                            {formatWholeNumber(
                              attributes?.price?.maximumAmount
                            )}
                          </Text>
                        </Box>
                      </Flex>
                    );
                  })}
              </Grid>
            )}
          </TabPanel>
          <TabPanel p="0">
            {loading2 ? (
              <SpinLoader />
            ) : (
              <Grid
                templateColumns={[
                  "repeat(auto-fill, minmax(100px, 1fr))",
                  "repeat(auto-fill, minmax(120px, 1fr))",
                ]}
                gap={"10px"}
              >
                {plans
                  ?.filter(
                    (i) =>
                      i?.attributes?.name?.includes("60 days") ||
                      i?.attributes?.name?.includes("2 months")
                  )
                  ?.map(({ id, type, attributes }) => {
                    return (
                      <Flex
                        key={id}
                        onClick={() => handleBuy({ id, type, attributes })}
                        cursor="pointer"
                        height={["130px", "130px"]}
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="8px"
                        bg="#F5F8FF"
                        borderTop={borderTop}
                      >
                        <Box textAlign="center">
                          {selectedBiller?.attributes?.name === "Airtel" ? (
                            <Text
                              color="#002F3D"
                              fontSize={["1rem", "1.2rem"]}
                              fontWeight="600"
                            >
                              {truncateText(
                                attributes?.name?.split(" ")[3],
                                14
                              ) ||
                                truncateText(
                                  attributes?.name?.split(" ")[4],
                                  14
                                )}
                            </Text>
                          ) : selectedBiller?.attributes?.name === "GLO" ? (
                            <Text
                              color="#002F3D"
                              fontSize={["0.8rem", "1rem"]}
                              fontWeight="600"
                              noOfLines={3}
                              whiteSpace="normal"
                              wordBreak="break-word"
                            >
                              {truncateText(attributes?.name, 8)}
                            </Text>
                          ) : (
                            <Text
                              color="#002F3D"
                              fontSize={["0.8rem", "1rem"]}
                              fontWeight="600"
                              noOfLines={3}
                              whiteSpace="normal"
                              wordBreak="break-word"
                            >
                              {attributes?.name?.split(" ")[0]?.includes("Xtra")
                                ? truncateText(
                                    attributes?.name?.split(" ")[1],
                                    14
                                  )
                                : truncateText(
                                    attributes?.name?.split(" ")[0],
                                    14
                                  )}
                            </Text>
                          )}
                          <Text
                            mt=".5rem"
                            color="#000119"
                            fontSize={[".6rem", ".9rem"]}
                          >
                            2 Months
                          </Text>
                          <Divider mt=".6rem" />
                          <Text
                            mt=".5rem"
                            fontWeight={"700"}
                            color="#002E99"
                            fontSize={[".8rem", "1rem"]}
                          >
                            {attributes?.price?.currency}
                            {formatWholeNumber(
                              attributes?.price?.maximumAmount
                            )}
                          </Text>
                        </Box>
                      </Flex>
                    );
                  })}
              </Grid>
            )}
          </TabPanel>
          <TabPanel p="0" w="100%">
            {loading2 ? (
              <SpinLoader />
            ) : (
              <Grid
                templateColumns={[
                  "repeat(auto-fill, minmax(100px, 1fr))",
                  "repeat(auto-fill, minmax(120px, 1fr))",
                ]}
                gap={"10px"}
              >
                {plans
                  ?.filter(
                    (i) =>
                      i?.attributes?.name?.includes("90 days") ||
                      i?.attributes?.name?.includes("3 months") ||
                      i?.attributes?.name?.includes("120 days")
                  )
                  ?.map(({ id, type, attributes }) => {
                    return (
                      <Flex
                        key={id}
                        onClick={() => handleBuy({ id, type, attributes })}
                        cursor="pointer"
                        height={["130px", "130px"]}
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="8px"
                        bg="#F5F8FF"
                        borderTop={borderTop}
                      >
                        <Box textAlign="center">
                          {selectedBiller?.attributes?.name === "Airtel" ? (
                            <Text
                              color="#002F3D"
                              fontSize={["1rem", "1.2rem"]}
                              fontWeight="600"
                            >
                              {truncateText(
                                attributes?.name?.split(" ")[3],
                                14
                              ) ||
                                truncateText(
                                  attributes?.name?.split(" ")[4],
                                  14
                                )}
                            </Text>
                          ) : selectedBiller?.attributes?.name === "GLO" ? (
                            <Text
                              color="#002F3D"
                              fontSize={["0.8rem", "1rem"]}
                              fontWeight="600"
                              noOfLines={3}
                              whiteSpace="normal"
                              wordBreak="break-word"
                            >
                              {truncateText(attributes?.name, 8)}
                            </Text>
                          ) : (
                            <Text
                              color="#002F3D"
                              fontSize={["0.8rem", "1rem"]}
                              fontWeight="600"
                              noOfLines={3}
                              whiteSpace="normal"
                              wordBreak="break-word"
                            >
                              {attributes?.name?.split(" ")[0]?.includes("Xtra")
                                ? truncateText(
                                    attributes?.name?.split(" ")[1],
                                    14
                                  )
                                : truncateText(
                                    attributes?.name?.split(" ")[0],
                                    14
                                  )}
                            </Text>
                          )}
                          <Text
                            mt=".5rem"
                            color="#000119"
                            fontSize={[".6rem", ".9rem"]}
                          >
                            3 Months
                          </Text>
                          <Divider mt=".6rem" />
                          <Text
                            mt=".5rem"
                            fontWeight={"700"}
                            color="#002E99"
                            fontSize={[".8rem", "1rem"]}
                          >
                            {attributes?.price?.currency}
                            {formatWholeNumber(
                              attributes?.price?.maximumAmount
                            )}
                          </Text>
                        </Box>
                      </Flex>
                    );
                  })}
              </Grid>
            )}
          </TabPanel>

          <TabPanel p="0">
            {loading2 ? (
              <SpinLoader />
            ) : (
              <Grid
                templateColumns={[
                  "repeat(auto-fill, minmax(100px, 1fr))",
                  "repeat(auto-fill, minmax(200px, 1fr))",
                ]}
                gap={"10px"}
              >
                {plans
                  ?.filter(
                    (i) =>
                      i?.attributes?.name?.includes("180 days") ||
                      i?.attributes?.name?.includes("6 months")
                  )
                  ?.map(({ id, type, attributes }) => {
                    return (
                      <Flex
                        key={id}
                        onClick={() => handleBuy({ id, type, attributes })}
                        cursor="pointer"
                        height={["100px", "170px"]}
                        alignItems="center"
                        justifyContent="center"
                        cursoor="pointer"
                        borderRadius="8px"
                        bg="#02BAF1"
                      >
                        <Box textAlign="center">
                          {selectedBiller?.attributes?.name === "Airtel" ? (
                            <Text
                              color="#002F3D"
                              fontSize={["1rem", "1.4rem"]}
                              fontWeight="600"
                            >
                              {truncateText(
                                attributes?.name?.split(" ")[3],
                                14
                              ) ||
                                truncateText(
                                  attributes?.name?.split(" ")[4],
                                  14
                                )}
                            </Text>
                          ) : selectedBiller?.attributes?.name === "GLO" ? (
                            <Text
                              color="#002F3D"
                              fontSize={["1rem", "1.4rem"]}
                              fontWeight="600"
                            >
                              {truncateText(attributes?.name, 8)}
                            </Text>
                          ) : (
                            <Text
                              color="#002F3D"
                              fontSize={["1rem", "1.4rem"]}
                              fontWeight="600"
                            >
                              {attributes?.name?.split(" ")[0]?.includes("Xtra")
                                ? truncateText(
                                    attributes?.name?.split(" ")[1],
                                    14
                                  )
                                : truncateText(
                                    attributes?.name?.split(" ")[0],
                                    14
                                  )}
                            </Text>
                          )}
                          <Text
                            mt=".5rem"
                            fontWeight={"700"}
                            color="#002F3D"
                            fontSize={[".8rem", "1rem"]}
                          >
                            {attributes?.price?.currency}
                            {formatWholeNumber(
                              attributes?.price?.maximumAmount
                            )}
                          </Text>
                          <Text
                            mt=".5rem"
                            color="#002F3D"
                            fontSize={[".6rem", ".9rem"]}
                          >
                            {attributes?.name}
                          </Text>
                        </Box>
                      </Flex>
                    );
                  })}
              </Grid>
            )}
          </TabPanel>

          <TabPanel p="0">
            {loading2 ? (
              <SpinLoader />
            ) : (
              <Grid
                templateColumns={[
                  "repeat(auto-fill, minmax(100px, 1fr))",
                  "repeat(auto-fill, minmax(120px, 1fr))",
                ]}
                gap={"10px"}
              >
                {plans
                  ?.filter(
                    (i) =>
                      i?.attributes?.name?.includes("365 days") ||
                      i?.attributes?.name?.includes("Yearly") ||
                      (selectedBiller?.attributes?.name === "GLO" &&
                        i?.attributes?.name?.includes("GloMega"))
                  )
                  ?.map(({ id, type, attributes }) => {
                    return (
                      <Flex
                        key={id}
                        onClick={() => handleBuy({ id, type, attributes })}
                        cursor="pointer"
                        height={["130px", "130px"]}
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="8px"
                        bg="#F5F8FF"
                        borderTop={borderTop}
                      >
                        <Box textAlign="center">
                          {selectedBiller?.attributes?.name === "Airtel" ? (
                            <Text
                              color="#002F3D"
                              fontSize={["1rem", "1.2rem"]}
                              fontWeight="600"
                            >
                              {truncateText(
                                attributes?.name?.split(" ")[3],
                                14
                              ) ||
                                truncateText(
                                  attributes?.name?.split(" ")[4],
                                  14
                                )}
                            </Text>
                          ) : selectedBiller?.attributes?.name === "GLO" ? (
                            <Text
                              color="#002F3D"
                              fontSize={["0.8rem", ".8rem"]}
                              fontWeight="600"
                              noOfLines={3}
                              whiteSpace="normal"
                              wordBreak="break-word"
                            >
                              {attributes?.name}
                            </Text>
                          ) : (
                            <Text
                              color="#002F3D"
                              fontSize={["0.8rem", "1rem"]}
                              fontWeight="600"
                              noOfLines={3}
                              whiteSpace="normal"
                              wordBreak="break-word"
                            >
                              {attributes?.name?.split(" ")[0]?.includes("Xtra")
                                ? truncateText(
                                    attributes?.name?.split(" ")[1],
                                    14
                                  )
                                : truncateText(
                                    attributes?.name?.split(" ")[0],
                                    14
                                  )}
                            </Text>
                          )}
                          <Text
                            mt=".5rem"
                            color="#000119"
                            fontSize={[".6rem", ".9rem"]}
                          >
                            1 Year
                          </Text>
                          <Divider mt=".6rem" />
                          <Text
                            mt=".5rem"
                            fontWeight={"700"}
                            color="#002E99"
                            fontSize={[".8rem", "1rem"]}
                          >
                            {attributes?.price?.currency}
                            {formatWholeNumber(
                              attributes?.price?.maximumAmount
                            )}
                          </Text>
                        </Box>
                      </Flex>
                    );
                  })}
              </Grid>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default DataTabs;
