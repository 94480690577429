import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  Divider,
  useBreakpointValue,
} from '@chakra-ui/react';
import FullLogo from './assets/full2.svg';
import Fb from './assets/fb.svg';
import X from './assets/x.svg';
import Discord from './assets/discord.svg';
import Yt from './assets/yt.svg';
import Ig from './assets/ig.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
  const socials = [
    {
      icon: Fb,
      url: 'https://www.facebook.com/gameporte',
    },
    {
      icon: X,
      url: 'https://twitter.com/gameporte',
    },
    {
      icon: Discord,
      url: 'https://discord.gg/9FmPYHB7',
    },
    {
      icon: Yt,
      url: 'https://youtube.com/channel/UCoD1MdZ_SZVGakXIJ3WlSEA',
    },
    {
      icon: Ig,
      url: 'https://instagram.com/gameporte?igshid=YmMyMTA2M2Y=',
    },
  ];

  const flexDirection = useBreakpointValue({ base: 'column', md: 'row' });
  const justifyContent = useBreakpointValue({
    base: 'center',
    md: 'space-between',
  }); // Adjusted justifyContent property

  return (
    <Flex direction="column" px={['1rem', '4rem']} bg="#F8FAFC" py="5rem">
      {/* Top Half */}
      <Flex
        direction={['column', 'row']}
        justifyContent={justifyContent}
        alignItems="center"
        mb="2rem"
      >
        <Box textAlign={['center', 'left']} mb={['2rem', '0']}>
          {' '}
          {/* Adjusted margin bottom for smaller screens */}
          <Image src={FullLogo} width="300px" height="64px" mb="1rem" />
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="24px"
            color="#667185"
          >
            PorteShop is an E-commerce store for gamers.
          </Text>
        </Box>
        <Box mt={['2rem', '0']}>
          {/* <Link to="/">
            <Button
              bg="white"
              border="1px solid #002E99"
              textColor="#002E99"
              mr="1rem"
            >
              Check products
            </Button>
          </Link> */}
          <Link to={'mailto:hello@gameporte.com'}>
            <Button bg="#002E99" border="1px solid #002E99" textColor="white">
              Contact us
            </Button>
          </Link>
        </Box>
      </Flex>

      <Divider borderColor="#E4E7EC" />

      {/* Bottom Half */}
      <Flex
        direction={flexDirection}
        justifyContent="space-between"
        alignItems="center"
        mt="2rem"
      >
        <Flex alignItems={['center', 'flex-start']}>
          {socials.map(({ icon, url }, index) => (
            <a href={url} key={index} target="_blank" rel="noopener noreferrer">
              <Image src={icon} boxSize="24px" mx="0.5rem" />
            </a>
          ))}
        </Flex>
        <Text
          mt={['1rem', '0']}
          textAlign={['center', 'left']}
          fontSize="0.87rem"
          color="#64748B"
        >
          © 2024 Gameporte. All rights reserved.
        </Text>
        <Box mt={['1rem', '0']}>
          <Image src={FullLogo} width="150px" height="32px" />
        </Box>
      </Flex>
    </Flex>
  );
};

export default Footer;
