import React, { createContext, useState, useEffect } from 'react';
import { fetchCartItems } from './fetchCart';
import { useSelector } from 'react-redux';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [cartItemsLength, setCartItemsLength] = useState(0);
  const currentUser = useSelector(({ userData }) => userData?.currentUser);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const cartData = await fetchCartItems();
        if (Array.isArray(cartData)) {
          setCartItems(cartData);
        } else {
          throw new Error('Fetched data is not an array');
        }
      } catch (error) {
        setCartItems([]);
        console.error(error.message); // Log the error for debugging purposes
      }
    };

    if (currentUser) {
      fetchData();
    }
  }, [currentUser, cartItemsLength]);

  useEffect(() => {
    setCartItemsLength(Array.isArray(cartItems) ? cartItems.length : 0);
  }, [cartItems]);

  const updateCartItems = (updater) => {
    setCartItems((prevCartItems) => {
      const updatedCartItems =
        typeof updater === 'function' ? updater(prevCartItems) : updater;
      if (Array.isArray(updatedCartItems)) {
        localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
        return updatedCartItems;
      } else {
        return prevCartItems;
      }
    });
  };

  return (
    <CartContext.Provider value={{ cartItems, updateCartItems }}>
      {children}
    </CartContext.Provider>
  );
};
