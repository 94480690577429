// import React, { useState, useEffect } from 'react';
// import {
//   Drawer,
//   DrawerBody,
//   DrawerFooter,
//   DrawerHeader,
//   DrawerOverlay,
//   DrawerContent,
//   DrawerCloseButton,
//   Button,
//   Box,
//   Image,
//   Text,
//   Flex,
// } from '@chakra-ui/react';
// import { useDisclosure } from '@chakra-ui/react';
// import DefaultImage from '../../assets/voucher.png';
// import axiosInstance from '../../services/api';
// import axiosInstance2 from '../../services/UnAuthApi';

// const CustomDrawer = ({
//   position,
//   title,
//   toggleElement,
//   content,
//   triggerClose,
//   setTriggerClose,
//   itemId,
//   ...props
// }) => {
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const [voucherData, setVoucherData] = useState(null);

//   const handleClose = () => {
//     if (triggerClose?.includes('close')) {
//       onClose();
//     }
//   };

//   useEffect(() => {
//     handleClose();
//   }, [triggerClose]);

//   useEffect(() => {
//     if (isOpen && itemId) {
//       const fetchVoucherDetails = async () => {
//         try {
//           const response = await axiosInstance2.get(`/game-voucher/available-vouchers/${itemId}`);
//           setVoucherData(response.data);
//         } catch (error) {
//         }
//       };

//       fetchVoucherDetails();
//     }
//   }, [isOpen, itemId]);

//   return (
//     <>
//       <Box onClick={onOpen}>{toggleElement}</Box>
//       <Drawer
//         isOpen={isOpen}
//         placement={position}
//         onClose={onClose}
//         size={'sm'}
//       >
//         <DrawerOverlay />
//         <DrawerContent
//           borderRadius={position === 'bottom' ? '16px 16px 0 0' : '0px'}
//           pt={position === 'bottom' ? '' : '2rem'}
//         >
//           <DrawerCloseButton
//             border="2px solid #292D32"
//             borderRadius={'50%'}
//             transform={'scale(.8) translateY(5.5rem)'}
//             fontWeight={'700'}
//           />
//           {position === 'bottom' && (
//             <Box
//               onClick={onClose}
//               cursor={'pointer'}
//               height={'6px'}
//               mx="auto"
//               mt=".7rem"
//               width={'40px'}
//               bg="#EAECF0"
//               borderRadius={'8px'}
//             />
//           )}
//           <DrawerHeader fontWeight={'700'} color={'#475467'}>
//             {title}
//           </DrawerHeader>
//           <DrawerBody>
//             {content ? (
//               content
//             ) : voucherData ? (
//               <Flex direction="column" alignItems="center" justifyContent="center" textAlign="center">
//                 <Image
//                   src={voucherData.imageUrl || DefaultImage}
//                   height="222px"
//                   width="222px"
//                   alt={voucherData.title || 'Voucher'}
//                   mb="1rem"
//                   mt="2rem"
//                 />
//                 <Text fontWeight={'700'} fontSize={'16px'} color="#333333" mb="1rem">
//                   {voucherData.name}
//                 </Text>
//                 <Text fontWeight={'400'} fontSize={'14px'} color="#999999" mb="1rem">
//                   {voucherData.description}
//                 </Text>
//                 <Button
//                   bg="#002E99"
//                   textColor="white"
//                   width="100%"
//                   onClick={onClose}
//                 >
//                   Close
//                 </Button>
//               </Flex>
//             ) : (
//               <Flex direction="column" alignItems="center" justifyContent="center" textAlign="center">
//                 <Image
//                   src={DefaultImage}
//                   height="222px"
//                   width="222px"
//                   alt="Not available"
//                   mb="1rem"
//                   mt="2rem"
//                 />
//                 <Text fontWeight={'400'} fontSize={'14px'} color="#999999">
//                   Voucher is currently not available now,
//                 </Text>
//                 <Text fontWeight={'400'} fontSize={'14px'} color="#999999" mb="64px">
//                   kindly check back later.
//                 </Text>
//                 <Button
//                   bg="#002E99"
//                   textColor="white"
//                   width="100%"
//                   onClick={onClose}
//                 >
//                   Close
//                 </Button>
//               </Flex>
//             )}
//           </DrawerBody>
//           {position === 'bottom' && (
//             <DrawerFooter justifyContent={'center'}>
//               <Button variant="ghost" mr={3} color="#475467" onClick={onClose}>
//                 Cancel
//               </Button>
//             </DrawerFooter>
//           )}
//         </DrawerContent>
//       </Drawer>
//     </>
//   );
// };

// export default CustomDrawer;
import React, { useState } from 'react';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';

const CustomDrawer = ({
  position,
  title,
  triggerClose,
  toggleElement,
  content,
  width = '500px',
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Box onClick={onToggle}>{toggleElement}</Box>
      <Drawer isOpen={isOpen} placement={position} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent maxWidth={width}>
          <DrawerCloseButton />
          <DrawerHeader>{title}</DrawerHeader>
          <DrawerBody>{React.cloneElement(content, { onClose })}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CustomDrawer;
