import './App.css';
import { Box, Flex, useToast } from '@chakra-ui/react';
import { Route, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loaduser, getUserSettings } from './redux/actions/auth';
import { userActionTypes } from './redux/constants/userActionTypes';
import { GetUserAccount } from './services/accountApi.api';
import PorteShopModule from './pages/home/porteShopModule';
import Login from './pages/login/login';
import HomeModule from './pages/home/homePage';
import MyCart from './pages/myCart/myCart';
import VoucherId from './pages/voucherId/VoucherId';
import BoxSkeleton from './components/boxSkeleton';
import { CartProvider } from './pages/myCart/cartContext';
import { RequireAuth } from './api/requireAuth';
import Orderlist from './pages/orderlist/orderlist';
import Wishlist from './pages/wishlist/wishlist';
import GameTopUp from './pages/gameTop-up/gameTopUp';
import Page404 from './pages/page404/page404';
import GiftCardDetails from './pages/giftCardDetails/giftCardDetails';
import GiftCardAvailable from './pages/giftCardDetails/giftCardAvailable/giftCardAvailable';
import { GetUserLocation } from './services/userSetting.api';
import GiftCardsTopUp from './pages/giftCardsTop-up/giftCardsTopup';
import BillsPayment from './pages/billsPayment/billsPayment';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingx, setLoadingx] = useState(false);
  const toast = useToast();
  const loading = useSelector(({ userData }) => userData?.isLoading);
  const currentUser = useSelector(({ userData }) => userData?.currentUser);

  useEffect(() => {
    const loadData = async () => {
      if (localStorage.GPV1TK) {
        await dispatch(loaduser(navigate));
        await dispatch(getUserSettings());
      } else {
        dispatch({ type: userActionTypes.LOADUSER_FAIL, payload: null });
      }

      try {
        const data = await GetUserLocation();
        dispatch({ type: userActionTypes.USER_LOCATION, payload: data });
      } catch (error) {}
    };

    loadData();
  }, []);

  const getUserAccount = async () => {
    try {
      setLoadingx(true);
      const { data } = await GetUserAccount();

      dispatch({ type: userActionTypes.USER_PROFILE_DATA2, payload: data });
    } catch (err) {
      setLoadingx(false);
      const errorMessage = '';
      toast({
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setLoadingx(false);
    }
  };

  useEffect(() => {
    if (localStorage.GPV1TK) {
      getUserAccount();
    }
  }, [currentUser]);

  useEffect(() => {
    const token = localStorage.getItem('GPV1TK');
    if (token) {
      dispatch({ type: userActionTypes.SIGNIN_SUCCESS, payload: token });
    }
  }, [dispatch]);

  return (
    <>
      <Box>
        <CartProvider>
          <Routes>
            <Route path="/" element={<HomeModule />} />
            <Route path="/*" element={<Page404 />} />
            <Route path="/login" element={<Login />} />
            <Route path="/vouchers/:id" element={<VoucherId />} />
            <Route path="/gift-card/:id" element={<GiftCardDetails />} />
            <Route
              path="/gift-card/available/:id/:countryCode"
              element={<GiftCardAvailable />}
            />
            <Route path="/orderlist" element={<Orderlist />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/categories/game-top-up" element={<GameTopUp />} />
            <Route path="/categories/gift-cards" element={<GiftCardsTopUp />} />
            <Route path="/categories/bills-payment" element={<BillsPayment />} />
            <Route element={<RequireAuth />}>
              <Route path="/cart" element={<MyCart />} />
            </Route>
          </Routes>
        </CartProvider>
      </Box>
    </>
  );
}

export default App;
