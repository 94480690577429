import React, { useState, useEffect } from 'react';
import { Flex, Button } from '@chakra-ui/react';
import HomeSectionRow from '../../components/homeSectionRow/homeSectionRow';
import GameVoucherTopUpItem from './gameVoucherTopUpItem';
import axiosInstance3 from '../../services/api2';

const GameVoucherTopUp = ({ searchValue }) => {
  const [gameVouchersTopUp, setGameVouchersTopUp] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const fetchGameVouchers = async (currentPage) => {
    try {
      const response = await axiosInstance3.get('/games', {
        params: {
          // size: 30,
          page: currentPage,
          productType: 'VOUCHER'
        },
      });

      const { content, totalPages, last } = response.data.data;
      
      const mappedData = content.map((item) => ({
        id: item.ulid,
        title: item.name,
        image: item.lightModeImageUrl,
        name: item.name,
        howToRedeem: item.howToRedeem,
      }));

      setGameVouchersTopUp(prevItems => 
        currentPage === 0 
          ? mappedData 
          : [...prevItems, ...mappedData]
      );
      setTotalPages(totalPages);
      setHasMore(!last);
    } catch (error) {
      console.error('Error fetching game vouchers:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGameVouchers(0);
  }, []);

  const filterItems = (items) => {
    return items.filter((item) => 
      item.title.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  const handleViewMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchGameVouchers(nextPage);
  };

  const filteredData = filterItems(gameVouchersTopUp);

  if (filteredData.length === 0 && !loading) {
    return null;
  }

  return (
    <HomeSectionRow
      title="Game Voucher Top-up"
      loading={loading}
      data={filteredData}
      renderItem={(item) => (
        <Flex
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          gap="1.2rem"
        >
          <GameVoucherTopUpItem
            id={item.id}
            title={item.title}
            image={item.image}
            name={item?.name}
            howToRedeem={item?.howToRedeem}
          />
        </Flex>
      )}
      emptyMessage={
        filteredData.length === 0 && !loading
          ? 'No vouchers available for this game.'
          : null
      }
      routePath="/categories/game-top-up"
      footer={
        hasMore && !loading && (
          <Flex justifyContent="center" width="full" mt={4}>
            <Button 
              onClick={handleViewMore}
              colorScheme="blue"
              variant="outline"
            >
              View More
            </Button>
          </Flex>
        )
      }
    />
  );
};

export default GameVoucherTopUp;