import { Box, Flex, Text, useBreakpointValue, IconButton } from "@chakra-ui/react";
import React, { useState } from "react";
import { Image } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import SearchBar from "../searchBar/searchBar";

const NavbarTwo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  const display = useBreakpointValue({ base: "none", md: "flex" });
  const mobileDisplay = useBreakpointValue({ base: "flex", md: "none" });

  return (
    <Box bg="white" mx={{ base: "10px", md: "100px" }} borderRadius="24px" px="12px" py="8px">
      <Flex direction="row" align="center" justifyContent="space-between">
        <Flex direction="row" align="center">
          <Image
            src="/images/Logo1.png"
            boxSize="10"
            alt="Gameporte Logo"
            objectFit="contain"
          />
          <Text
            align="center"
            ml="4px"
            color="#002E99"
            fontSize="28"
            fontWeight="bold"
          >
            Porte<span style={{ fontWeight: "400" }}>shop</span>
          </Text>
        </Flex>

        <IconButton
          display={mobileDisplay}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          onClick={toggleMenu}
          variant="outline"
          aria-label="Toggle Navigation"
        />

        {/* <Flex direction="row" align="center" display={display} gap="32px">
          <Text fontSize="small" color="#999999">Homepage</Text>
          <Text fontSize="small" color="#999999">Wishlist</Text>
          <Text fontSize="small" color="#999999">Order list</Text>
          <Text fontSize="small" color="#999999">My cart</Text>
        </Flex> */}

        <Flex display={display} alignItems="center">
          <SearchBar />
          <Image
            src="/images/profile.png"
            boxSize="10"
            alt="Profile"
            ml="16px"
          />
        </Flex>
      </Flex>

      {isOpen && (
        <Box pb={4} display={mobileDisplay} mt={2}>
          <Flex direction="column" align="start">
            <Text fontSize="small" color="#999999" mb={2}>Homepage</Text>
            <Text fontSize="small" color="#999999" mb={2}>Wishlist</Text>
            <Text fontSize="small" color="#999999" mb={2}>Order list</Text>
            <Text fontSize="small" color="#999999" mb={2}>My cart</Text>
            <SearchBar mb={2} />
            <Image
              src="/images/profile.png"
              boxSize="10"
              alt="Profile"
              mt="8px"
            />
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default NavbarTwo;
