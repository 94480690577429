import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Heading,
  Container,
  Text,
  Image,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Divider,
  useToast,
  Button,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Grid,
  Input,
  InputGroup,
  InputRightElement,
  Switch,
  IconButton,
} from '@chakra-ui/react';
import Navbar from '../../components/navbar/navbar';
import { FaRegCopy } from 'react-icons/fa6';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as BackIcon } from '../../assets/previous.svg';
import axiosInstance2 from '../../services/UnAuthApi';
import Delete from '../../assets/Delete.png';
import CartModal from '../../assets/cartModal.svg';
import EmptyCartImage from '../../assets/emptyCart.png';
import Dropdown from '../../assets/dropDown.png';
import Card from '../../assets/payCard.png';
import Wallet from '../../assets/payWallet.png';
import { GetUserAccount } from '../../services/accountApi.api';
import { useNavigate } from 'react-router-dom';
import { GetWallet } from '../../services/wallet.api';
import OtpInput from 'react-otp-input';
import EnterPin from '../../assets/enterPin.png';
import PrimaryButton from '../../components/primaryButton/primarybutton';
import { CartContext } from './cartContext';
import MainLayout from '../../components/mainLayout/mainLayout';
import Footer from '../../components/footer/footer';
import PinSuccess from '../../assets/pinSuccess.png';

const MyCart = ({ searchValue, setSearchValue }) => {
  const [cartItem, setCartItem] = useState([]);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeButton, setActiveButton] = useState(false);
  const [otp, setOtp] = useState('');
  const [wallet, setWallet] = useState(0);
  const [itemToRemove, setItemToRemove] = useState(null);
  const [showDeliveryDetails, setShowDeliveryDetails] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [address, setAddress] = useState('');
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [selectedGiftCard, setSelectedGiftCard] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showOtpDrawer, setShowOtpDrawer] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);
  const [recipientName, setRecipientName] = useState('');
  const [recipientPhoneNumber, setRecipientPhoneNumber] = useState('');
  const [isSaveDeliveryDetailsChecked, setIsSaveDeliveryDetailsChecked] =
    useState(false);
  const [isStateDropdownOpen, setIsStateDropdownOpen] = useState(false);
  const [showWalletCheckout, setShowWalletCheckout] = useState(false);
  const [email, setEmail] = useState('');
  const [payLoading, setPayLoading] = useState(false);
  const [isCartLoading, setIsCartLoading] = useState(true);
  const [totalCost, setTotalCost] = useState(0);
  const { cartItems, updateCartItems } = useContext(CartContext);
  const [showWallet, setShowWallet] = useState(false);
  const [walletDetails, setWalletDetails] = useState(null);
  const [copied, setCopied] = useState(false);
  const [triesLeft, setTriesLeft] = useState(5);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const currentEmail = useSelector(
    ({ userData }) => userData?.userProfile?.userProfile?.email
  );

  // const totalPrice = cartItems
  // ? cartItems.reduce((total, item) => {
  //     return total + (item?.price - (item?.price * item?.discountPercent) / 100) * item?.quantity;
  //   }, 0)
  // : 0;

  const totalPrice = cartItems
    ? cartItems.reduce((total, item) => {
        const discountPercent = item.discountPercent || 0;

        const itemTotal = Math.round(
          (item.price - (item.price * discountPercent) / 100) * item.quantity
        );

        return total + itemTotal;
      }, 0)
    : 0;

  const handleItemClick = (voucher, card) => {
    setSelectedVoucher(voucher);
    setSelectedGiftCard(card);
    setShowSidebar(true);
  };

  const handleChange = (e) => {
    setOtp(e);
  };

  const states = [
    'Abia',
    'Adamawa',
    'Akwa Ibom',
    'Anambra',
    'Bauchi',
    'Bayelsa',
    'Benue',
    'Borno',
    'Cross River',
    'Delta',
    'Ebonyi',
    'Edo',
    'Ekiti',
    'Enugu',
    'FCT - Abuja',
    'Gombe',
    'Imo',
    'Jigawa',
    'Kaduna',
    'Kano',
    'Katsina',
    'Kebbi',
    'Kogi',
    'Kwara',
    'Lagos',
    'Nasarawa',
    'Niger',
    'Ogun',
    'Ondo',
    'Osun',
    'Oyo',
    'Plateau',
    'Rivers',
    'Sokoto',
    'Taraba',
    'Yobe',
    'Zamfara',
  ];

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const balance = await GetWallet();
        setWallet(balance?.data?.balance?.availableBalance);
        setWalletDetails(balance?.data?.details);
        setEmail(currentEmail);
      } catch (error) {}
    };
    fetchUserData();
  }, []);

  const handlePayVoucher = async () => {
    setPayLoading(true);

    try {
      const data = {
        walletPin: otp,
        checkoutType: 'MANUAL',
      };

      const response = await axiosInstance2.post('/cart/checkout', data);

      if (response.status === 200) {
        setShowSuccessModal(true);
        setPayLoading(false);
      } else {
        throw new Error('Payment failed');
      }
    } catch (error) {
      if (error?.response?.status === 500 || error?.response?.status === 501) {
        toast({
          title: '',
          description:
            'An error occurred while processing this request, please contact administrator',
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'An Error Occurred',
          description: error?.response?.data?.errors[0],
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setPayLoading(false);
      setIsPaymentConfirmed(false);
      setOtp('');
    }
  };

  const handleQuantityChange = (index, newQuantity) => {
    if (newQuantity < 1) return;

    updateCartItems((prevCartItems) => {
      const updatedCartItems = [...prevCartItems];
      updatedCartItems[index].quantity = newQuantity;
      return updatedCartItems;
    });
  };

  const handlePaymentConfirmation = () => {
    setIsPaymentConfirmed(true);
    setShowOtpDrawer(true);
  };

  const handleDeleteItem = async () => {
    if (itemToRemove === null) return;

    const { index, item } = itemToRemove;

    try {
      setIsLoading(true);
      const { itemType, name } = item;
      const response = await axiosInstance2.put(`/cart/remove`, {
        itemType,
        name,
      });

      if (response.data.status === 200) {
        setShowRemoveModal(false);

        // Update the cart items in the context
        updateCartItems((prevCartItems) => {
          const updatedCartItems = [...prevCartItems];
          updatedCartItems.splice(index, 1);
          return updatedCartItems;
        });
      } else {
        toast({
          title: 'Payment Error',
          description: response.data.errors,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Payment Error',
        description: 'Something went wrong',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeliveryDetails = (deliveryDetails) => {};

  const handleBoxClick = () => {
    setSelectedPaymentMethod('wallet');
    setShowWalletCheckout(true);
    handleContinue();
  };

  const handleContinue = async () => {
    try {
      const deliveryDetails = {
        state: selectedState,
        address,
        recipientName,
        email,
        recipientPhoneNumber,
        isSaveDeliveryDetailsChecked,
      };

      if (totalPrice <= 0) {
        throw new Error(
          'Total price must be greater than 0 to proceed with payment.'
        );
      }

      const canPay = wallet >= totalPrice;
      handleDeliveryDetails(deliveryDetails);
      setActiveButton(canPay);
      setShowDeliveryDetails(false);
      setShowWalletCheckout(true);
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const isCartEmpty = !cartItems || cartItems.length === 0;

  return (
    <>
      <MainLayout cartItems={cartItems}>
        <Container maxW="full" px={{ base: '4', md: '8' }} mt="120px">
          <IconButton
            icon={<BackIcon />}
            mr="20px"
            ml="10px"
            cursor="pointer"
            onClick={() => navigate(-1)}
          />
          <Box mt={'1rem'}>
            <Grid
              templateColumns={{
                base: '1fr',
                md: !isCartEmpty ? '2fr 1fr' : '1fr',
              }}
              gap={{ base: '10', md: '6' }}
            >
              {/* Left Side: Cart Items */}
              <Box overflowX="hidden">
                <Flex
                  direction="row"
                  justify="space-between"
                  mx={{ base: '10px', md: '20px' }}
                >
                  <Heading fontWeight="700" fontSize="24px">
                    Your Cart
                  </Heading>
                  <Text>
                    {isCartEmpty ? 'No' : cartItems.length} item
                    {!isCartEmpty && cartItems.length !== 1 && 's'}
                  </Text>
                </Flex>
                <Divider my="24px" borderBottom="1px solid #E2E8F0" />
                {isLoading ? (
                  <Flex
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    height="300px"
                  >
                    <Spinner size="xl" color="#002E99" />
                  </Flex>
                ) : isCartEmpty ? (
                  <Flex direction="column" alignItems="center">
                    <Image
                      src={EmptyCartImage}
                      height="200px"
                      width="200px"
                      alt="Empty Cart"
                    />
                    <Text
                      textAlign="center"
                      mt="4"
                      color="#999999"
                      fontWeight="400"
                      fontSize="16px"
                    >
                      Ready to turn this empty cart into your shopping list?
                    </Text>
                    <Text
                      textAlign="center"
                      color="#999999"
                      fontWeight="400"
                      fontSize="16px"
                    >
                      Let's embark on this journey together!
                    </Text>
                    <Button
                      my="120px"
                      onClick={() => navigate('/')}
                      variant="solid"
                      bgColor="#002E99"
                      width="312px"
                      color="white"
                      fontWeight="700"
                      fontSize="16px"
                    >
                      Browse products
                    </Button>
                  </Flex>
                ) : (
                  <>
                    <Box>
                      <Box maxWidth="768px" overflowX="auto">
                        <Table variant="simple">
                          <Thead>
                            <Tr bgColor="#F5F8FF" borderRadius="10px">
                              <Th
                                maxWidth="150px"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                Item Image
                              </Th>
                              <Th
                                maxWidth="150px"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                Item Name
                              </Th>
                              <Th
                                maxWidth="150px"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                Price
                              </Th>
                              <Th
                                maxWidth="150px"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                Quantity
                              </Th>
                              <Th
                                maxWidth="150px"
                                minWidth="50px"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                Delete
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {cartItems?.map((item, index) => (
                              <Tr key={index}>
                                <Td>
                                  <Image
                                    borderRadius="10px"
                                    src={item.itemImageUrl}
                                    boxSize="50px"
                                  />
                                </Td>
                                <Td>{item.name}</Td>
                                <Td>₦{Math.round(item.price).toLocaleString()}</Td>
                                <Td>
                                  <Flex alignItems="center">
                                    <Box
                                      bgColor="#F5F8FF"
                                      display="flex"
                                      borderRadius="20px"
                                    >
                                      <Button
                                        size="sm"
                                        onClick={() =>
                                          handleQuantityChange(
                                            index,
                                            item.quantity - 1
                                          )
                                        }
                                        disabled={item.quantity <= 1}
                                      >
                                        -
                                      </Button>
                                      <Text mt="1" mx="2">
                                        {item.quantity}
                                      </Text>
                                      <Button
                                        size="sm"
                                        onClick={() =>
                                          handleQuantityChange(
                                            index,
                                            item.quantity + 1
                                          )
                                        }
                                      >
                                        +
                                      </Button>
                                    </Box>
                                  </Flex>
                                </Td>
                                <Td px={{ base: 2, sm: 4 }}>
                                  <Image
                                    onClick={() => {
                                      setItemToRemove({ index, item });
                                      setShowRemoveModal(true);
                                    }}
                                    cursor="pointer"
                                    src={Delete}
                                    // width={{ base: '32px', md: '24px' }}
                                    // height={{ base: '32px', md: '24px' }}
                                    boxSize="25px"
                                  />
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
              {!isCartEmpty && (
                <Box>
                  {showDeliveryDetails ? (
                    <Box p="10px" borderRadius="10px">
                      <Text
                        mt="10px"
                        ml="25px"
                        fontWeight="700"
                        fontSize="28px"
                        mb="0"
                      >
                        Delivery Details
                      </Text>
                      <Text
                        ml="25px"
                        fontWeight="400"
                        fontSize="14px"
                        mt="0"
                        mb="4"
                        textColor="#999999"
                      >
                        Enter delivery details below to proceed.
                      </Text>
                      <Flex direction="column" gap="4">
                        {/* State */}
                        <Box position="relative">
                          <Text
                            mb="2"
                            fontWeight="400"
                            fontSize="12px"
                            color="#999999"
                          >
                            State
                          </Text>
                          <InputGroup>
                            <Input
                              placeholder="Select state"
                              value={selectedState}
                              onChange={(e) => setSelectedState(e.target.value)}
                              pr="40px"
                              backgroundColor="#F5F8FF"
                              border="none"
                              _placeholder={{
                                color: '#999999',
                                fontWeight: '400',
                                fontSize: '14px',
                              }}
                            />
                            <InputRightElement
                              width="40px"
                              onClick={() =>
                                setIsStateDropdownOpen(!isStateDropdownOpen)
                              }
                              cursor="pointer"
                            >
                              <Image
                                aria-label="Select state"
                                src={Dropdown}
                                variant="ghost"
                                width="24px"
                                height="24px"
                              />
                            </InputRightElement>
                          </InputGroup>
                          {isStateDropdownOpen && (
                            <Box
                              position="absolute"
                              zIndex="modal"
                              bg="white"
                              boxShadow="md"
                              maxHeight="200px"
                              overflowY="auto"
                            >
                              {states.map((state) => (
                                <Text
                                  key={state}
                                  px="4"
                                  py="2"
                                  cursor="pointer"
                                  onClick={() => {
                                    setSelectedState(state);
                                    setIsStateDropdownOpen(false);
                                  }}
                                >
                                  {state}
                                </Text>
                              ))}
                            </Box>
                          )}
                        </Box>

                        {/* Address */}
                        <Box>
                          <Text
                            mb="2"
                            fontWeight="400"
                            fontSize="12px"
                            color="#999999"
                          >
                            Address
                          </Text>
                          <Input
                            placeholder="Enter address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            backgroundColor="#F5F8FF"
                            border="none"
                            _placeholder={{
                              color: '#999999',
                              fontWeight: '400',
                              fontSize: '14px',
                            }}
                          />
                        </Box>

                        {/* Recipient Name */}
                        <Box>
                          <Text
                            mb="2"
                            fontWeight="400"
                            fontSize="12px"
                            color="#999999"
                          >
                            Recipient Name
                          </Text>
                          <Input
                            placeholder="Enter recipient name"
                            value={recipientName}
                            onChange={(e) => setRecipientName(e.target.value)}
                            backgroundColor="#F5F8FF"
                            border="none"
                            _placeholder={{
                              color: '#999999',
                              fontWeight: '400',
                              fontSize: '14px',
                            }}
                          />
                        </Box>

                        {/* Recipient Phone Number */}
                        <Box>
                          <Text
                            mb="2"
                            fontWeight="400"
                            fontSize="12px"
                            color="#999999"
                          >
                            Recipient Phone Number
                          </Text>
                          <Input
                            placeholder="Enter recipient phone number"
                            value={recipientPhoneNumber}
                            onChange={(e) =>
                              setRecipientPhoneNumber(e.target.value)
                            }
                            backgroundColor="#F5F8FF"
                            border="none"
                            _placeholder={{
                              color: '#999999',
                              fontWeight: '400',
                              fontSize: '14px',
                              paddingTop: '17px',
                              paddingBottom: '17px',
                            }}
                          />
                        </Box>

                        {/* Toggle */}
                        <Flex alignItems="center">
                          <Switch
                            isChecked={isSaveDeliveryDetailsChecked}
                            onChange={() =>
                              setIsSaveDeliveryDetailsChecked(
                                !isSaveDeliveryDetailsChecked
                              )
                            }
                          />
                          <Text
                            ml="2"
                            fontWeight="400"
                            fontSize="14px"
                            color="#999999"
                          >
                            Save delivery details
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex direction="column" mt="56px">
                        <Button
                          backgroundColor="#002E99"
                          textColor="white"
                          mb="24px"
                        >
                          Continue
                        </Button>
                        <Button
                          backgroundColor="white"
                          border="1px solid #999999"
                          textColor="#999999"
                          onClick={() => setShowDeliveryDetails(false)}
                        >
                          Go Back
                        </Button>
                      </Flex>
                    </Box>
                  ) : showWalletCheckout ? (
                    <Box p="10px" borderRadius="10px">
                      <Text
                        mt="10px"
                        ml="25px"
                        fontWeight="700"
                        fontSize="28px"
                        mb="0"
                      >
                        Wallet Checkout
                      </Text>
                      <Text fontWeight="400" fontSize="14px" ml="25px">
                        Confirm purchase details below before you proceed.
                      </Text>
                      <Box p="10px">
                        <Flex direction="column" gap="4">
                          {/* Top Part: Wallet Balance */}
                          <Box boxShadow="md" p="4" borderRadius="md">
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Flex alignItems="center">
                                <Image
                                  src={Wallet}
                                  alt="Wallet Icon"
                                  boxSize="50px"
                                  mr="2"
                                />
                                <Box>
                                  <Text
                                    fontWeight="400"
                                    fontSize="12px"
                                    color="#999999"
                                  >
                                    Wallet Balance
                                  </Text>
                                  <Text
                                    fontWeight="500"
                                    fontSize="16px"
                                    color="#333333"
                                  >
                                    ₦{wallet?.toLocaleString()}
                                  </Text>
                                </Box>
                              </Flex>
                              <Text
                                size="sm"
                                backgroundColor="#002E99"
                                textColor="white"
                                fontSize="12px"
                                fontWeight="700"
                                borderRadius="20px"
                                padding="6px 8px"
                                display="inline-block"
                                onClick={() => setShowWallet(true)}
                                cursor={'pointer'}
                              >
                                Top Up Wallet
                              </Text>
                            </Flex>
                          </Box>

                          {/* Second Part: Item Details */}
                          <Box bg="#F5F8FF" p="4" borderRadius="md">
                            <Grid templateColumns="1fr 1fr" gap="3">
                              {/* <Text
                                fontWeight="400"
                                fontSize="12px"
                                color="#999999"
                              >
                                Voucher Name
                              </Text>
                              <Text
                                fontWeight="400"
                                fontSize="12px"
                                color="#333333"
                                textAlign="right"
                              >
                                {selectedVoucher?.name}
                              </Text> */}

                              <Text
                                fontWeight="400"
                                fontSize="12px"
                                color="#999999"
                              >
                                Total Cost
                              </Text>
                              <Text
                                fontWeight="400"
                                fontSize="0.9rem"
                                color="#333333"
                                textAlign="right"
                              >
                                ₦{Math.round(totalPrice)?.toLocaleString()}
                              </Text>

                              <Text
                                fontWeight="400"
                                fontSize="12px"
                                color="#999999"
                              >
                                Wallet Balance After Payment
                              </Text>
                              <Text
                                fontWeight={
                                  wallet - totalPrice < 0 ? '600' : '700'
                                }
                                fontSize={
                                  wallet - totalPrice < 0 ? '12px' : '0.9rem'
                                }
                                color={
                                  wallet - totalPrice < 0
                                    ? 'crimson'
                                    : '#333333'
                                }
                                textAlign="right"
                              >
                                {wallet - totalPrice < 0
                                  ? 'Insufficient Balance'
                                  : `₦${(
                                      wallet - totalPrice
                                    )?.toLocaleString()}`}
                              </Text>
                            </Grid>
                          </Box>

                          {/* Delivery Details */}
                          <Text fontSize="14px" fontWeight="400">
                            Delivery Details
                          </Text>
                          <Box
                            bg="#F5F8FF"
                            p="4"
                            borderRadius="md"
                            boxShadow="md"
                          >
                            <Grid templateColumns="1fr 1fr" gap="3">
                              {/* <Text fontWeight="400" fontSize="12px" color="#999999">
                    Recipient's Name
                  </Text>
                  <Text
                    fontWeight="400"
                    fontSize="14px"
                    color="#333333"
                    textAlign="right"
                  >
                    {recipientName}
                  </Text> */}
                              <Text
                                fontWeight="400"
                                fontSize="12px"
                                color="#999999"
                              >
                                Email Address
                              </Text>
                              <Text
                                fontWeight="400"
                                fontSize="14px"
                                color="#333333"
                                textAlign="right"
                              >
                                {email}
                              </Text>
                              {/* <Text fontWeight="400" fontSize="12px" color="#999999">
                    Phone Number
                  </Text>
                  <Text
                    fontWeight="400"
                    fontSize="14px"
                    color="#333333"
                    textAlign="right"
                  >
                    {recipientPhoneNumber}
                  </Text> */}

                              {/* <Text fontWeight="400" fontSize="12px" color="#999999">
                    Address
                  </Text>
                  <Text
                    fontWeight="400"
                    fontSize="14px"
                    color="#333333"
                    textAlign="right"
                  >
                    {address}
                  </Text> */}
                            </Grid>
                          </Box>
                        </Flex>

                        {/* Bottom Part: Buttons */}
                        <Flex direction="column" mt="56px">
                          <Button
                            backgroundColor={
                              activeButton ? '#002E99' : '#CCCCCC'
                            }
                            textColor={activeButton ? 'white' : '#666666'}
                            mb="24px"
                            isDisabled={!activeButton || payLoading}
                            onClick={handlePaymentConfirmation}
                          >
                            Pay ₦{totalPrice?.toLocaleString()} now
                          </Button>
                          <Button
                            backgroundColor="white"
                            border="1px solid #999999"
                            textColor="#999999"
                            onClick={() => {
                              setShowWalletCheckout(false);
                            }}
                          >
                            Go Back
                          </Button>
                        </Flex>
                      </Box>
                    </Box>
                  ) : !isCartEmpty ? (
                    <Box p="10px" borderRadius="10px">
                      <Text
                        mt="10px"
                        ml="25px"
                        fontWeight="700"
                        fontSize="28px"
                        mb="0"
                      >
                        Payment Method
                      </Text>
                      <Text
                        ml="25px"
                        fontWeight="400"
                        fontSize="14px"
                        mt="0"
                        mb="4"
                        textColor="#999999"
                      >
                        Choose payment mode below to proceed.
                      </Text>
                      <Flex direction="column" mb="80px">
                        <Box
                          cursor={
                            selectedPaymentMethod === 'wallet'
                              ? 'pointer'
                              : 'not-allowed'
                          }
                          border="1px solid white"
                          borderRadius="8px"
                          p="10px"
                          m="5px"
                          mb="35px"
                          flex="1"
                          boxShadow="md"
                          onClick={
                            selectedPaymentMethod === 'wallet'
                              ? () => setSelectedPaymentMethod('card')
                              : undefined
                          }
                          opacity="0.5"
                          filter="grayscale(20%)
              "
                        >
                          <Flex alignItems="center">
                            <Image src={Card} w="50px" h="50px" mr="10px" />
                            <Box>
                              <Text fontSize="16px" fontWeight="700">
                                Pay with Card
                              </Text>
                              <Text
                                fontSize="14px"
                                fontWeight="400"
                                color="#999999"
                              >
                                Pay with your debit or credit card for fast
                              </Text>
                              <Text
                                fontSize="14px"
                                fontWeight="400"
                                color="#999999"
                              >
                                and secure payment.
                              </Text>
                            </Box>
                          </Flex>
                        </Box>

                        <Box
                          cursor="pointer"
                          border={
                            selectedPaymentMethod === 'wallet'
                              ? '1px solid #002E99'
                              : '1px solid white'
                          }
                          borderRadius="8px"
                          p="10px"
                          m="5px"
                          flex="1"
                          boxShadow="md"
                          onClick={handleBoxClick}
                        >
                          <Flex alignItems="center">
                            <Image src={Wallet} w="50px" h="50px" mr="10px" />
                            <Box>
                              <Text fontSize="16px" fontWeight="700">
                                Pay with Wallet
                              </Text>
                              <Text
                                fontSize="14px"
                                fontWeight="400"
                                color="#999999"
                              >
                                Pay with your porteshop in-app wallet for
                              </Text>
                              <Text
                                fontSize="14px"
                                fontWeight="400"
                                color="#999999"
                              >
                                seamless checkout.
                              </Text>
                            </Box>
                          </Flex>
                        </Box>
                      </Flex>

                      <Flex direction="column">
                        <Button
                          border="1px solid #999999"
                          backgroundColor="white"
                          textColor="#999999"
                          mt="20px"
                          borderRadius="10px"
                          // onClick={() => setShowDeliveryDetails(true)}
                        >
                          Go Back
                        </Button>
                      </Flex>
                    </Box>
                  ) : null}{' '}
                </Box>
              )}
            </Grid>
          </Box>
        </Container>

        {showWallet && (
          <Modal isOpen={showWallet} onClose={() => setShowWallet(false)}>
            <ModalOverlay />
            <ModalContent
              width="500px"
              height="auto"
              pt={'3rem'}
              mx={['1rem', 0]}
            >
              <Text textAlign="center" fontWeight="700" fontSize="24px">
                Top Up
              </Text>
              <Text
                textAlign="center"
                fontWeight="400"
                fontSize="16px"
                px={'2rem'}
              >
                Proceed to your Bank app to top-up your Gameporte Wallet
              </Text>

              <ModalCloseButton />
              <ModalBody>
                <Box p="1">
                  <Box bg="#F5F8FF" p="2" m="1" borderRadius="md">
                    <Flex
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Text fontWeight="400" fontSize="12px" color="#999999">
                        Account Number
                        <Text fontWeight="400" fontSize="12px" color="#333333">
                          {walletDetails?.virtualBankAccountNumber}
                        </Text>
                      </Text>

                      <Text
                        size="sm"
                        backgroundColor="#002E99"
                        textColor="white"
                        fontSize="12px"
                        fontWeight="700"
                        borderRadius="20px"
                        padding="6px 8px"
                        w={'fit-content'}
                        // onClick={() => setCopied(true)}
                        cursor={'pointer'}
                        textAlign={'right'}
                      >
                        <Flex
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          onClick={() => {
                            if (
                              navigator?.clipboard &&
                              walletDetails?.virtualBankAccountNumber
                            ) {
                              navigator.clipboard
                                .writeText(
                                  walletDetails.virtualBankAccountNumber
                                )
                                .then(() => {
                                  setCopied(true);
                                  setTimeout(() => {
                                    setCopied(false);
                                  }, 2000);
                                });
                            }
                          }}
                        >
                          {!copied ? (
                            <>
                              {' '}
                              <FaRegCopy
                                style={{ marginRight: '.3rem' }}
                              />{' '}
                              Copy
                            </>
                          ) : (
                            'Copied!'
                          )}
                        </Flex>
                      </Text>
                    </Flex>
                  </Box>
                  <Box bg="#F5F8FF" p="2" m="1" borderRadius="md">
                    <Text fontWeight="400" fontSize="12px" color="#999999">
                      Bank Name
                    </Text>
                    <Text fontWeight="400" fontSize="0.9rem" color="#333333">
                      {walletDetails?.virtualBankName}
                    </Text>
                  </Box>
                  <Box bg="#F5F8FF" p="2" m="1" borderRadius="md">
                    <Text fontWeight="400" fontSize="12px" color="#999999">
                      Account Name
                    </Text>
                    <Text fontWeight="400" fontSize="0.9rem" color="#333333">
                      {walletDetails?.virtualBankAccountName}
                    </Text>
                  </Box>
                </Box>

                <PrimaryButton
                  bg="#002E99 !important"
                  color="#FFF"
                  text="Close"
                  width="100%"
                  padding="1rem !important"
                  marginBottom="30px"
                  fontWeight="700"
                  fontSize="14px"
                  mt=".5rem"
                  onClick={() => setShowWallet(false)}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        )}

        {showOtpDrawer && (
          <Modal isOpen={showOtpDrawer} onClose={() => setShowOtpDrawer(false)}>
            <ModalOverlay />
            <ModalContent width="500px" height="516px">
              <Flex align="center">
                <Image
                  textAlign="center"
                  justifyContent="center"
                  src={EnterPin}
                  height="80px"
                  width="80px"
                  mx="auto"
                  my="2rem"
                  objectFit="cover"
                />
              </Flex>
              <Text textAlign="center" fontWeight="700" fontSize="24px">
                Enter Pin
              </Text>
              <Text textAlign="center" fontWeight="400" fontSize="16px">
                Enter your PIN to proceed and
              </Text>
              <Text
                textAlign="center"
                fontWeight="400"
                fontSize="16px"
                mb="24px"
              >
                complete your payment.{' '}
              </Text>
              <ModalCloseButton />
              <ModalBody>
                <Box margin="auto" width={['85%', '70%']}>
                  <Flex direction="row" justifyContent="center" gap="1rem">
                    <OtpInput
                      containerStyle={{
                        display: 'flex',
                        margin: '0 auto',
                        justifyContent: 'space-between',
                        gap: '1rem',
                        marginBottom: '30px',
                      }}
                      inputStyle={{
                        type: 'password',
                        height: '58px',
                        width: '58px',
                        background: '#C2D4FF',
                        color: '#000',
                        fontSize: '1.1rem',
                        fontWeight: '600',
                        borderRadius: '12px',
                        border: 'none',
                      }}
                      focusStyle={{
                        boxShadow: 'none',
                        outline: 'none',
                      }}
                      value={otp}
                      onChange={handleChange}
                      numInputs={4}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      isInputSecure={true}
                    />
                  </Flex>
                </Box>
                <Flex justifyContent={'center'} my="1.3rem" direction="column">
                  <PrimaryButton
                    bg="#002E99 !important"
                    color="#FFF"
                    text="Pay now"
                    width="100%"
                    padding="1rem !important"
                    marginBottom="30px"
                    fontWeight="700"
                    fontSize="14px"
                    onClick={() => handlePayVoucher()}
                  />
                  <PrimaryButton
                    border="1px solid #999999"
                    bg="white !important"
                    color="#999999"
                    text="Cancel"
                    width="100%"
                    padding="1rem !important"
                    fontWeight="700"
                    fontSize="14px"
                    onClick={() => setShowOtpDrawer(false)}
                  />
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
        <Modal
          isOpen={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
        >
          <ModalOverlay />
          <ModalContent width="500px" height="416px">
            <Flex align="center">
              <Image
                textAlign="center"
                justifyContent="center"
                src={PinSuccess}
                height="100px"
                width="100px"
                mx="auto"
                my="2rem"
                objectFit="cover"
              />
            </Flex>
            <ModalBody>
              <Box flexDirection="column" textAlign="center">
                <Text fontWeight="700" fontSize="24px">
                  Payment Successful
                </Text>
                <Text fontWeight="400" fontSize="14px">
                  Payment made successfully and game voucher
                </Text>
                <Text fontWeight="400" fontSize="14px">
                  has been sent to your email address.
                </Text>
                <Button
                  mt="56px"
                  backgroundColor="#002E99"
                  textColor="white"
                  width="100%"
                  onClick={() => setShowSuccessModal(false)}
                >
                  Close
                </Button>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={showRemoveModal}
          onClose={() => setShowRemoveModal(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction="column" align="center" justify="center">
                <Image src={CartModal} height="80px" width="80px" />
                <Box textAlign="center">
                  <Text fontWeight="700" fontSize="24px">
                    Are you sure you want to
                  </Text>
                  <Text fontWeight="700" fontSize="24px" m="0">
                    remove item from
                  </Text>
                  <Text fontWeight="700" fontSize="24px">
                    cart?
                  </Text>

                  <Text fontWeight="400" fontSize="16px">
                    Item will be removed from cart and
                  </Text>
                  <Text fontWeight="400" fontSize="16px">
                    can be added back from product
                  </Text>
                  <Text fontWeight="400" fontSize="16px">
                    page
                  </Text>
                </Box>

                <Flex direction="column" width="100%" align="center">
                  <Button
                    bgColor="white"
                    border="1px solid #FF5252"
                    textColor="#FF5252"
                    onClick={handleDeleteItem}
                    mb={4}
                    mt={8}
                    width="100%"
                  >
                    Remove item
                  </Button>
                  <Button
                    bgColor="white"
                    border="1px solid #999999"
                    textColor="#999999"
                    onClick={() => setShowRemoveModal(false)}
                    mb={4}
                    width="100%"
                  >
                    Cancel
                  </Button>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </MainLayout>
      <Footer />
    </>
  );
};

export default MyCart;
