import React from 'react';
import HomeSectionRow from '../../components/homeSectionRow/homeSectionRow';
import { Box, Text, Flex, Image, Button } from '@chakra-ui/react';
import DefaultImage from '../../assets/voucher.png';
import Ps5 from '../../assets/ps5.png';
import Bills from '../../assets/bill.png';
import Game from '../../assets/game.png';
import Gift from '../../assets/giftcards.png';
import Itunes from '../../assets/itunes.png';
import CustomDrawer from '../customDrawer/customDrawer';
import CustomDrawerContent from '../customDrawer/customDrawerContent';
import { useNavigate } from 'react-router-dom';

const staticCategories = [
  { id: 'static-3', title: 'Game Top-up', image: Game },
  { id: 'static-4', title: 'Gift Cards', image: Itunes },
  { id: 'static-2', title: 'Bills payment & Top-up', image: Bills },
  { id: 'static-1', title: 'Game Consoles', image: Ps5 },
];

const Categories = ({ searchValue }) => {
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    if (category.title === 'Game Top-up') {
      navigate('/categories/game-top-up');
    } else if (category.title === 'Gift Cards') {
      navigate('/categories/gift-cards');
    } else if (category.title === 'Bills payment & Top-up') {
      navigate('/categories/bills-payment');
    }
  };
  
  const filterItems = (items) => {
    return items.filter((item) => 
      item.title.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  const filteredData = filterItems(staticCategories);

  if (filteredData.length === 0) {
    return null;
  }

  return (
    <HomeSectionRow
      title="Categories"
      data={filterItems(staticCategories)}
      renderItem={(category, index) => (
        <Box
          key={category.id}
          cursor={'pointer'}
          onClick={() => handleCategoryClick(category)}
        >
          {category.title === 'Game Top-up' ? (
            <Box>
              <Box
                height={['165px', '245px']}
                width={['129px', '190px']}
                // overflow="hidden"
                backgroundImage={`url(${category.image})`}
                backgroundSize="cover"
                backgroundPosition="center"
                borderRadius="md"
              />
              <Text
                fontSize={['.8rem', '16px']}
                mt=".6rem"
                textAlign={'center'}
                cursor={'pointer'}
                color="#000119"
                fontWeight={'700'}
                maxW={'171px'}
                height="50px"
              >
                {category.title}
              </Text>
            </Box>
          ) : (
            <CustomDrawer
              key={category.id}
              position={'right'}
              title={'Category Details'}
              triggerClose={false}
              width="600px"
              toggleElement={
                <Box flex={'0 0 200px'} cursor={'pointer'}>
                  <Box
                    height={['165px', '245px']}
                    width={['129px', '190px']}
                    overflow="hidden"
                    backgroundImage={`url(${category.image})`}
                    backgroundSize="cover"
                    backgroundPosition="center"
                    borderRadius="md"
                  />
                  <Text
                    fontSize={['.8rem', '16px']}
                    mt=".6rem"
                    textAlign={'center'}
                    cursor={'pointer'}
                    color="#000119"
                    fontWeight={'700'}
                    maxW={'171px'}
                    height="50px"
                  >
                    {category.title}
                  </Text>
                </Box>
              }
              content={
                <CustomDrawerContent
                  title={category.title}
                  onClose={() => {}}
                />
              }
            />
          )}
        </Box>
      )}
    />
  );
};

export default Categories;
