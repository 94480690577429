import { Flex, Spinner } from '@chakra-ui/react';
import React from 'react';

const SpinLoader = ({ color }) => {
  return (
    <Flex mt="8rem" justifyContent={'center'}>
      <Spinner size={'lg'} color={color || '#fff'} />
    </Flex>
  );
};

export default SpinLoader;
