import React from 'react';
import HomeSectionRow from "../../components/homeSectionRow/homeSectionRow";
import { Box, Text } from "@chakra-ui/react";
import Ie from "../../assets/ie.png";
import Cable from "../../assets/dstv.png";
import Betting from "../../assets/b9.png";
import CustomDrawer from "../customDrawer/customDrawer";
import CustomDrawerContent from "../customDrawer/customDrawerContent";

const paymentsData = [
  { title: "Electricity", image: Ie },
  { title: "Cable/TV", image: Cable },
  { title: "Betting", image: Betting },
];

const Payments = ({ searchValue, setSelectedIndex }) => {
  const filterItems = (items) => {
    return items.filter((item) => 
      item.title.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  const filteredData = filterItems(paymentsData);

  if (filteredData.length === 0) {
    return null;
  }


  return (
    <HomeSectionRow
      title="Bill Payments"
      data={filterItems(paymentsData)}
      renderItem={(item, index) => (
        <CustomDrawer
          key={item.title}
          position={"right"}
          title={"Bill Payment Details"}
          triggerClose={false}
          toggleElement={
            <Box
              flex={"0 0 200px"}
              cursor={"pointer"}
            >
              <Box
                height={["165px", "245px"]}
                width={["129px", "190px"]}
                overflow="hidden"
                backgroundImage={`url(${item.image})`}
                backgroundSize="cover"
                backgroundPosition="center"
                borderRadius="md"
              />
              <Text
                fontSize={[".8rem", "16px"]}
                mt=".6rem"
                textAlign={"center"}
                cursor={"pointer"}
                color="#000119"
                fontWeight={"700"}
                maxW={"171px"}
              >
                {item.title}
              </Text>
            </Box>
          }
          content={<CustomDrawerContent title={paymentsData.title} onClose={() => {}} />}
        />
      )}
    />
  );
};

export default Payments;
