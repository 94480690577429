import React from "react";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/react";
import Beneficiary from "../../beneficiary/beneficiary";

const BeneficiaryModal = ({isBeneficiaryOpen, onBeneficiaryClose, handleBeneficiarySelect, beneficiaries, hasMore, loading, setPage}) => {
  return (
    <div>
      <Modal
        isCentered
        motionPreset="slideInBottom"
        isOpen={isBeneficiaryOpen}
        onClose={onBeneficiaryClose}
      >
        <ModalOverlay />
        <ModalContent height="auto" mx={["1rem", 0]}>
          <ModalBody>
            <Beneficiary
              onSelect={handleBeneficiarySelect}
              beneficiaries={beneficiaries}
              hasMore={hasMore}
              loading={loading}
              setPage={setPage}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default BeneficiaryModal;
