import { Flex, Skeleton } from '@chakra-ui/react';
import React from 'react';

const BoxSkeleton = ({ num, width, height, borderRadius, mb, mx }) => {
  const arr = [...Array(num).keys()];

  return (
    <Flex
      gap={'1rem'}
      flexWrap={'wrap'}
      justifyContent="center"
      mb={mb}
      mx={mx}
    >
      {arr?.map((i, index) => {
        return (
          <Skeleton
            key={index}
            height={height}
            width={['300px', width]}
            mx={'auto'}
            borderRadius={borderRadius}
            // flex={['1 1 150px', '1 1 450px']}
            startColor="#c9c9c7"
            endColor="#bababa"
          />
        );
      })}
    </Flex>
  );
};

export default BoxSkeleton;
