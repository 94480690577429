import React from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

const CustomTabs = ({ data }) => {
  return (
    <>
      <Tabs>
        <TabList border={'none'} gap="1rem" mb=".5rem">
          {data?.map((i, index) => (
            <Tab
              px="auto"
              color="#D0D5DD"
              fontSize={'14px'}
              _selected={{
                color: '#002E99',
                fontWeight: '600',
                borderBottom: '3px solid #002E99',
              }}
              key={index}
            >
              {i?.title}
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          {data?.map((i, index) => (
            <TabPanel key={index} px="0">
              <p>{i?.render}</p>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
};

export default CustomTabs;
