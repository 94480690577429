import axiosInstance from './api';

export const GetUserAccount = async () => {
  try {
    const url = '/user_setting';
    const res = await axiosInstance.get(url);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const GetUserLocation = async () => {
  try {
    // Use a free IP geolocation API
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
