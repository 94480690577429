import { useSelector } from 'react-redux';
import CustomTabs from '../../../components/customTabs/customTabs';
import Airtime from './airtime/airtime';
import DataPurchase from './dataPurchase/dataPurchase';
import MiniLogin from '../../../components/miniLogin/miniLogin';
import { useState } from 'react';

const AirtimeData = ({ selectedIndex }) => {
  // const [view, setView] = useState("auth")
  const currentUser = useSelector(({ userData }) => userData?.currentUser);

  const datax = [
    {
      title: 'Airtime Purchase',
      render: <Airtime selectedIndex={selectedIndex} />,
    },
    {
      title: 'Data Bundles',
      render: <DataPurchase selectedIndex={selectedIndex} />,
    },
  ];

  return (
    // <>
    // {currentUser ?
    <>
      <CustomTabs data={datax} />
    </>
    // :
    // <MiniLogin />
    // }

    // </>
  );
};

export default AirtimeData;
