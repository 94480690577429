import React, { useState } from "react";
import MainLayout from "../../components/mainLayout/mainLayout";
import {
  Box,
  Image,
  Flex,
  Text,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import Footer from "../../components/footer/footer";
import Page404image from "../../assets/page404.png";
import { useNavigate } from "react-router-dom";
import Page404background from "../../assets/page404background.png";

const Page404 = () => {
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleReload = () => {
    window.location.reload();
  };

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <MainLayout searchValue={searchValue} setSearchValue={setSearchValue}>
      <Box
        mt={{ base: "80px", md: "120px" }}
        position="relative"
        height="100vh"
        width="100%"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bgImage={`url(${Page404background})`}
          bgSize={"cover"}
          bgPosition="top"
          bgRepeat="no-repeat"
          height="100%"
          width="100%"
          filter="grayscale(100%)"
          zIndex="-1"
        />

        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="100%"
        >
          <Image
            src={Page404image}
            height={{ base: "210px", md: "292px" }}
            width={{ base: "235px", md: "342px" }}
            alt="Not available"
          />
          <Text
            fontWeight="700"
            fontSize={{ base: "24px", md: "32px" }}
            color="#000"
          >
            Uh oh! Your cart looks lost.
          </Text>
          <Text
            fontWeight="400"
            fontSize={{ base: "16px", md: "20px" }}
            color="#000"
            mb="32px"
          >
            Don't worry{isMobile && <br />} let's get you back on track.
          </Text>
          <Flex direction={{ base: "column", md: "row" }} gap="16px" mb="64px">
            <Button
              onClick={handleGoHome}
              bgColor="#002E99"
              textColor="white"
              width="312px"
              height="50px"
              borderRadius="12px"
              paddingY="10px"
              paddingX="16px"
            >
              Keep shopping
            </Button>
          </Flex>
        </Flex>

        <Footer />
      </Box>
    </MainLayout>
  );
};

export default Page404;
