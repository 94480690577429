import { userActionTypes } from '../constants/userActionTypes';

const INIT_STATE = {
  token: localStorage.getItem('GPV1TK'),
  isLoading: true,
  currentUser: null,
  isAuthenticated: false,
  bikereqloader: false,
  notificationCount: null,
  wallet: {},
  transactions: {},
  authError: {
    signUpError: '',
    signInError: '',
    signOutError: '',
    loadUserError: '',
  },
  transactionResponse: {},
  userProfile: {},
  userEmail: '',
  userSettings: null,
  userLocation: null,
};

const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case userActionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    // case userActionTypes.SIGNUP_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //   };
    case userActionTypes.SIGNUP_FAIL:
      return {
        ...state,
        authError: {
          signUpError: action.payload,
        },
      };
    case userActionTypes.SIGNIN_SUCCESS:
      return {
        ...state,
        isLoading: true,
        currentUser: action.payload,
        isAuthenticated: true,
      };
    case userActionTypes.SIGNIN_SUCCESS2:
      return {
        ...state,
        isLoading: false,
        currentUser: action.payload,
        isAuthenticated: true,
      };
    case userActionTypes.SIGNIN_FAIL:
      return {
        ...state,
        isLoading: false,
        authError: {
          signInError: action.payload,
        },
      };
    case userActionTypes.LOGOUT_FAIL:
      return {
        ...state,
        isLoading: false,
        authError: {
          signOutError: action.payload,
        },
      };
    case userActionTypes.LOGOUT_SUCCESS:
      localStorage.removeItem('PRV1TK');
      return {
        ...state,
        isLoading: false,
        currentUser: null,
        userProfile: null,
        isAuthenticated: false,
      };
    case userActionTypes.LOADUSER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentUser: action.payload,
        isAuthenticated: true,
      };
    case userActionTypes.LOADUSER_SUCCESS2:
      return {
        ...state,
        isLoading: false,
        currentUser: action.payload,
        isAuthenticated: true,
      };
    case userActionTypes.LOADUSER_FAIL:
      localStorage.removeItem('PRV1TK');
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        token: null,
        authError: {
          loadUserError: action.payload,
        },
      };
    case userActionTypes.BIKEREQLOADER:
      return {
        ...state,
        bikereqloader: action.payload,
      };
    case userActionTypes.WALLET_DETAILS:
      return {
        ...state,
        wallet: action.payload,
      };
    case userActionTypes.TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };
    case userActionTypes.ADD_TRANSACTIONS_DATA:
      return {
        ...state,
        transactionResponse: action.payload,
      };
    case userActionTypes.UPDATE_NOTIFICATIONS_COUNT:
      return {
        ...state,
        notificationCount: action.payload,
      };
    case userActionTypes.USER_PROFILE_DATA:
      return {
        ...state,
        userProfile: action.payload,
      };
    case userActionTypes.USER_PROFILE_DATA2:
      return {
        ...state,
        isLoading: false,
        userProfile: action.payload,
      };
    case userActionTypes.SET_USER_SETTINGS:
      return {
        ...state,
        userSettings: action.payload,
      };
    case userActionTypes.USER_LOCATION:
      return {
        ...state,
        userLocation: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
