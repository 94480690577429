import axios from "axios";

export const signInApi = async (data) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://gameporte-core-service.herokuapp.com/v1/auth/gamer/login',
        // url: 'https://gameporte-core-service-dev-2b03cfc55e64.herokuapp.com/v1/auth/gamer/login',
        headers: { 
          'Content-Type': 'application/json'
        },
        withCredentials: true,
        data : {
            username: data.email,
            password: data.password
        }
      };

     const response = await axios.request(config)

     return response.data;
}