export function formatWholeNumber(number, options = {}) {
    const defaultOptions = {
      locale: 'en-US',
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    };
  
    const mergedOptions = { ...defaultOptions, ...options };
  
    return Math.round(number).toLocaleString(mergedOptions.locale, mergedOptions);
  }