import React, { useState } from "react";
import { Box, Flex, Grid, IconButton, Text } from "@chakra-ui/react";
import MainLayout from "../../components/mainLayout/mainLayout";
import Footer from "../../components/footer/footer";
import { ReactComponent as BackIcon } from "../../assets/previous.svg";
import SkeletonLayout from "../../components/SkeletonLayout";
import { useNavigate } from "react-router-dom";
import Glo from "../../assets/glo.png";
import Airtel from "../../assets/airtel.png";
import Mobile9 from "../../assets/9mobile.png";
import Mtn from "../../assets/mtn.png";
import CustomDrawer from "../../components/customDrawer/customDrawer";
import AirtimeData from "../../pages/products/airtimeData/airtimeData";
import PrepaidLoginModal from "../../components/prepaidTopUp/prepaidLoginModal";

const prepaidData = [
  { title: "GLO", image: Glo },
  { title: "Airtel", image: Airtel },
  { title: "9mobile", image: Mobile9 },
  { title: "MTN", image: Mtn },
];

const BillsPayment = () => {
  const [selectedIndex, setSelectedIndex] = useState("");
  const [loading, setLoading] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const filterItems = (items) => {
    return items.filter((item) =>
      item.title.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  const isLoggedIn = () => {
    const token = localStorage.getItem("GPV1TK");
    return !!token;
  };

  return (
    <MainLayout searchValue={searchValue} setSearchValue={setSearchValue}>
      <Box mt="120px">
        <Box px={{ base: "10px", md: "30px" }}>
          <Flex align="center" mb={4}>
            <IconButton
              icon={<BackIcon />}
              mr="20px"
              cursor="pointer"
              onClick={handleBackClick}
            />
            <Text fontSize="24px" fontWeight="700">
              Bills Payment & Top-Up
            </Text>
          </Flex>

          {loading ? (
            <Box mt="50px">
              <SkeletonLayout />
            </Box>
          ) : (
            <Grid
              templateColumns={{
                base: "repeat(2, 1fr)",
                md: "repeat(4, 1fr)",
                lg: "repeat(6, 1fr)",
              }}
              gap={3}
              justifyContent={"center"}
            >
              {filterItems(prepaidData).map((item, index) => (
                <CustomDrawer
                  key={item.title}
                  position={"right"}
                  title={"Prepaid Top-Up"}
                  triggerClose={false}
                  isOpen
                  toggleElement={
                    <Box
                      cursor={"pointer"}
                      onClick={() => {
                        localStorage.setItem(
                          "airtimeId",
                          JSON.stringify(index)
                        );
                        setSelectedIndex(item.title);
                      }}
                      px={{ base: "30px", md: "0" }}
                    >
                      <Box
                        height={["165px", "245px"]}
                        width={["129px", "190px"]}
                        overflow="hidden"
                        backgroundImage={`url(${item.image})`}
                        backgroundSize="cover"
                        backgroundPosition="center"
                        borderRadius="md"
                      />
                      <Text
                        fontSize={[".8rem", "16px"]}
                        mt=".6rem"
                        textAlign={"center"}
                        cursor={"pointer"}
                        color="#000119"
                        fontWeight={"700"}
                        maxW={"171px"}
                      >
                        {item.title}
                      </Text>
                    </Box>
                  }
                  content={<AirtimeData selectedIndex={selectedIndex} />}
                />
              ))}
            </Grid>
          )}
        </Box>
      </Box>
      <Box mt={8}>
        <Footer />
      </Box>

      <PrepaidLoginModal
        isOpen={showLoginModal}
        onClose={() => setShowLoginModal(false)}
      />
    </MainLayout>
  );
};

export default BillsPayment;
