import React from 'react';
import { Flex, Image, Text, Button } from '@chakra-ui/react';
import DefaultImage from '../../assets/voucher.png';
const CustomDrawerContent = ({ title, onClose }) => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Image
        src={DefaultImage}
        height="222px"
        width="222px"
        alt="Not available"
        mb="1rem"
        mt="2rem"
      />
      <Text fontWeight={'400'} fontSize={'14px'} color="#999999">
        {title} is currently not available now,
      </Text>
      <Text fontWeight={'400'} fontSize={'14px'} color="#999999" mb="64px">
        kindly check back later.
      </Text>
      <Button bg="#002E99" textColor="white" width="100%" onClick={onClose}>
        Close
      </Button>
    </Flex>
  );
};

export default CustomDrawerContent;
