import {
  Box,
  Text,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Link,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';
import React, { useState } from 'react';
import NavbarTwo from '../../components/navbarTwo/navbarTwo';
import { signInApi } from '../../api/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userActionTypes } from '../../redux/constants/userActionTypes';
import { useContext } from 'react';
import { CartContext } from '../myCart/cartContext';
import { fetchCartItems } from '../myCart/fetchCart';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();
  const { updateCartItems } = useContext(CartContext);

  const { imageUrl } = location.state || {};

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      email,
      password,
    };

    try {
      const response = await signInApi(data);

      if (response.status === 200) {
        localStorage.setItem('GPV1TK', response.data.accessToken);
        dispatch({
          type: userActionTypes.SIGNIN_SUCCESS,
          payload: response?.data,
        });

        try {
          const cartData = await fetchCartItems();
          updateCartItems(cartData);
        } catch (cartError) {}

        // Get the redirect path from location state or default to home
        const redirectTo = location.state?.from?.pathname || '/';
        navigate(redirectTo, { replace: true, state: { imageUrl } });
      } else {
        handleSignInError(response);
      }
    } catch (error) {
      handleSignInError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSignInError = (error) => {
    const response = error?.response;

    if (response?.status === 500 || response?.status === 501) {
      toast({
        title: '',
        description:
          'An error occurred while processing this request, please contact administrator',
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    } else if (response?.data?.errors[0]?.includes('profile inactive')) {
      // onOpen();
    } else {
      toast({
        title: 'An Error Occurred',
        description: response?.data?.errors[0],
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      minHeight="100vh"
      bgImage="/images/bg.png"
      bgSize="contain"
      bgRepeat="no-repeat"
      pt="24px"
      px={{ base: '16px', md: '100px' }}
    >
      <NavbarTwo mb="144px" />
      <Box
        bg="white"
        height="528px"
        maxWidth="500px"
        mx="auto"
        mt="144px"
        mb="60px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        px="16px"
        borderRadius="8px"
        boxShadow="md"
      >
        <Heading fontSize="24px" mb="16px" mt="40px">
          Log in
        </Heading>
        <Text fontWeight="400" fontSize="14px" color="#999999" mb="32px">
          Enter log in credentials below to continue.
        </Text>
        <Box width="100%">
          <form onSubmit={handleSignIn}>
            <Box mb="24px">
              <Text fontWeight="400" fontSize="14px" color="#999999" mb="8px">
                Email / Username
              </Text>
              <InputGroup>
                <Input
                  fontWeight="400"
                  fontSize="14px"
                  color="#999999"
                  bg="#F5F8FF"
                  value={email}
                  border="none"
                  placeholder="Enter your email / username"
                  pr="40px"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <InputRightElement pointerEvents="none">
                  <Box position="relative" width="16px" height="16px">
                    <Image
                      src="/images/email.png"
                      alt="Email Icon"
                      layout="fill"
                      objectFit="contain"
                    />
                  </Box>
                </InputRightElement>
              </InputGroup>
            </Box>
            <Box mb="24px">
              <Text fontWeight="400" fontSize="14px" color="#999999" mb="8px">
                Password
              </Text>
              <InputGroup>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fontWeight="400"
                  fontSize="14px"
                  color="#999999"
                  bg="#F5F8FF"
                  border="none"
                  placeholder="Enter your password"
                  pr="40px"
                />
                <InputRightElement>
                  <Box
                    position="relative"
                    width="16px"
                    height="16px"
                    onClick={togglePasswordVisibility}
                    cursor="pointer"
                  >
                    <Image
                      src={
                        showPassword
                          ? '/images/password.png'
                          : '/images/password.png'
                      } // Change icon based on state
                      alt="Password Icon"
                      layout="fill"
                      objectFit="contain"
                    />
                  </Box>
                </InputRightElement>
              </InputGroup>
            </Box>
            <Button
              type="submit"
              width="100%"
              bg="#002E99"
              color="white"
              _hover={{ bg: '#001F6B' }}
              mb="24px"
              cursor="pointer"
              disabled={loading}
            >
              {loading ? <Spinner size="sm" /> : 'Log in'}
            </Button>
          </form>
          <Text
            fontWeight="400"
            fontSize="14px"
            color="#999999"
            textAlign="center"
            mb="24px"
          >
            Don't have an account?
          </Text>
          <Link href="https://app.gameporte.com/" isExternal>
            <Text
              fontWeight="bold"
              fontSize="16px"
              color="#002E99"
              textAlign="center"
              cursor="pointer"
              mb="40px"
            >
              Sign up on Gameporte
            </Text>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
