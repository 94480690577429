'use client';

import {
  Flex,
  Image,
  Text,
  Box,
  IconButton,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  CloseButton,
  useToast,
} from '@chakra-ui/react';
import {
  AiOutlineMenu,
  AiOutlineClose,
  AiOutlineShoppingCart,
} from 'react-icons/ai';
import { GetUserAccount } from '../../services/accountApi.api';
import { FaUser } from 'react-icons/fa';
import Logo from '../../assets/logo.svg';
import blueUser from '../../assets/blueUser.svg';
import NavbarLogo from '../../assets/navbarlogo.png';
import CustomInput2 from '../customInput/customInput';
import navIcon from '../../assets/navIcon.svg';
import PrepaidLoginModal from '../../components/prepaidTopUp/prepaidLoginModal';
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCartItems } from '../../pages/myCart/fetchCart';
import { CartContext } from '../../pages/myCart/cartContext';
import { userActionTypes } from '../../redux/constants/userActionTypes';
import PropTypes from 'prop-types';

const Navbar = ({ searchValue, setSearchValue }) => {
  const [loading, setLoading] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const toast = useToast();

  const currentUser = useSelector(({ userData }) => userData?.currentUser);
  const userProfile = useSelector(({ userData }) => userData?.userProfile);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { cartItems, updateCartItems } = useContext(CartContext);
  const cartLength = cartItems?.length;

  const handleMenuToggle = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  const handleLogout = async () => {
    updateCartItems([]);
    localStorage.removeItem('cartItems');
    localStorage.removeItem('GPV1TK');
    dispatch({ type: userActionTypes.LOGOUT_SUCCESS });
    navigate('/');
  };

  const handleCartClick = () => {
    if (currentUser) {
      navigate('/cart');
    } else {
      setIsModalOpen(true);
    }
  };

  const fetchAndSetCartItems = async () => {
    setLoading(true);
    try {
      if (currentUser) {
        const cartData = await fetchCartItems();
        updateCartItems(cartData);
      }
    } catch (error) {
      // toast({
      //   title: 'Error fetching cart items',
      //   description: 'An error occurred while fetching your cart items.',
      //   status: 'error',
      //   duration: 5000,
      //   isClosable: true,
      // });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAndSetCartItems();
  }, []);

  const DesktopMenu = () => (
    <Flex gap="1.5rem" alignItems="center">
      <NavLink title="Homepage" path="/" currentPath={location.pathname} />
      <NavLink
        title="Wishlist"
        path="/wishlist"
        currentPath={location.pathname}
      />
      <NavLink
        title="Order list"
        path="/orderlist"
        currentPath={location.pathname}
      />
    </Flex>
  );

  const MobileMenu = () => (
    <Flex
      direction="column"
      position="absolute"
      top="75px"
      left="0"
      width="100%"
      background="#002E99"
      p="1rem"
      zIndex="10"
    >
      <NavLink
        title={currentUser ? 'Logout' : 'Login'}
        onClick={currentUser ? handleLogout : () => navigate('/login')}
      />
      <NavLink title="Homepage" path="/" currentPath={location.pathname} />
      <NavLink
        title="Wishlist"
        path="/wishlist"
        currentPath={location.pathname}
      />
      <NavLink
        title="Order list"
        path="/orderlist"
        currentPath={location.pathname}
      />
    </Flex>
  );

  return (
    <Flex
      height={['90px', '75px']}
      p={['2rem 1rem', '1rem']}
      borderRadius={['0', '36px']}
      bg={['#fff', '#002E99']}
      alignItems="center"
      justifyContent="space-between"
      position="fixed"
      top={['0', '4']}
      left={['0', '1.5rem']}
      right={['0', '1.5rem']}
      zIndex="1000"
      boxShadow={['', 'md']}
    >
      {isMobile && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          border={'none'}
        >
          {userProfile?.userProfile?.imageUrl ? (
            <Image
              src={userProfile.userProfile.imageUrl}
              width="48px"
              height="48px"
              objectFit="cover"
              borderRadius=".8rem"
              border={'none'}
            />
          ) : (
            <Box
              w={'48px'}
              h={'48px'}
              p={'.8rem'}
              borderRadius={'.8rem'}
              bg={'#E5EBFF3D'}
            >
              <Image
                transform={'scale(1) translateX(.2rem)'}
                cursor={'pointer'}
                src={blueUser}
              />
            </Box>
          )}
        </Box>
      )}
      <Flex
        cursor="pointer"
        onClick={() => navigate('/')}
        alignItems="center"
        gap=".3rem"
      >
        {!isMobile ? (
          <Image src={NavbarLogo} transform="scale(.9)" alt="PorteShop Logo" />
        ) : (
          <Flex direction="row" align="center">
            <Image
              src="/images/Logo1.png"
              boxSize="10"
              alt="Gameporte Logo"
              objectFit="contain"
            />
            <Text
              align="center"
              ml="4px"
              color="#002E99"
              fontSize="28"
              fontWeight="bold"
            >
              Porte<span style={{ fontWeight: '400' }}>shop</span>
            </Text>
          </Flex>
        )}
      </Flex>

      {!isMobile && <DesktopMenu />}
      {isMobile && isMobileMenuOpen && <MobileMenu />}

      <Flex gap={['1rem', '1.5rem']} alignItems="center">
        {!isMobile && (
          <CartIcon cartLength={cartLength} handleCartClick={handleCartClick} />
        )}

        {!isMobile && (
          <CustomInput2
            type="text"
            required
            value={searchValue}
            placeholder="Search products"
            onChange={(e) => setSearchValue(e.target.value)}
            mt="0"
            py="1.4rem"
            bg="linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(204, 204, 204, 0.24) 100%)"
            border="0"
            sType="search"
            borderRadius="36px"
            color="#FFF"
            _placeholder={{ color: '#FFF', fontSize: '.85rem' }}
          />
        )}

        {currentUser && !isMobile && (
          <UserMenu userProfile={userProfile} handleLogout={handleLogout} />
        )}
        {!currentUser && !isMobile && (
          <Text
            fontSize="1rem"
            fontWeight="700"
            color="#FFF"
            cursor="pointer"
            onClick={() => navigate('/login')}
            px="8px"
          >
            Login
          </Text>
        )}
        {isMobile && (
          <Menu>
            <MenuButton>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                // width="40px"
                // height="40px"
                // borderRadius="100%"
                // bg="linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(204, 204, 204, 0.24) 100%)"
                // border="2px solid #1D5E82"
              >
                <Box
                  w={'48px'}
                  h={'48px'}
                  p={'.8rem'}
                  borderRadius={'.8rem'}
                  bg={'#E5EBFF3D'}
                >
                  <Image
                    transform={'scale(1) translate(.1rem, .2rem)'}
                    cursor={'pointer'}
                    src={navIcon}
                  />
                </Box>
              </Box>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => navigate('/')}>Homepage</MenuItem>
              <MenuItem onClick={handleCartClick}>My Cart</MenuItem>
              <MenuItem onClick={() => navigate('/wishlist')}>
                Wishlist
              </MenuItem>
              <MenuItem onClick={() => navigate('/orderlist')}>
                Order list
              </MenuItem>
              <MenuItem
                onClick={currentUser ? handleLogout : () => navigate('/login')}
              >
                {currentUser ? 'Logout' : 'Login'}
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>

      <PrepaidLoginModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </Flex>
  );
};

const NavLink = ({ title, path, currentPath, onClick }) => {
  const navigate = useNavigate();
  return (
    <Text
      onClick={onClick || (() => navigate(path))}
      borderBottom={
        currentPath?.includes('orders')
          ? '1px solid #FFF'
          : '1px solid transparent'
      }
      pb=".1rem"
      transform="translateY(.1rem)"
      borderRadius="0 0 2px 2px"
      cursor="pointer"
      transition="250ms ease"
      _hover={{ borderBottom: '1px solid #FFF' }}
      fontSize=".9rem"
      fontWeight="300"
      color="#FFF"
    >
      {title}
    </Text>
  );
};

const CartIcon = ({ cartLength, handleCartClick }) => (
  <Box
    cursor="pointer"
    position="relative"
    color="#FFFFFF"
    bg="linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(204, 204, 204, 0.24) 100%)"
    width={['40px', '50px']}
    height={['40px', '35px']}
    borderRadius="50%"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    {cartLength > 0 && (
      <Text
        position="absolute"
        fontWeight="700"
        fontSize=".6rem"
        p=".07rem .32rem .05rem .32rem"
        zIndex="18"
        top={['-10%', '-20%']}
        right={['-10%', '-15%']}
        borderRadius="50%"
        bg="#FFF"
        color="#333"
      >
        {cartLength}
      </Text>
    )}
    <AiOutlineShoppingCart
      onClick={handleCartClick}
      color="#FFFFFF"
      style={{ display: 'inline', transform: 'scale(1.2)' }}
    />
  </Box>
);

const UserMenu = ({ userProfile, handleLogout }) => (
  <Menu>
    <MenuButton>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="40px"
        height="40px"
        borderRadius="100%"
        bg="linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(204, 204, 204, 0.24) 100%)"
        border="2px solid #1D5E82"
      >
        {userProfile?.userProfile?.imageUrl ? (
          <Image
            src={userProfile.userProfile.imageUrl}
            width="40px"
            height="40px"
            objectFit="cover"
            borderRadius="100%"
          />
        ) : (
          <FaUser
            color="#FFFFFF"
            style={{ display: 'inline', transform: 'scale(1.2)' }}
          />
        )}
      </Box>
    </MenuButton>
    <MenuList>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </MenuList>
  </Menu>
);

Navbar.propTypes = {
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
};

export default Navbar;
