import React from "react"
import { Navigate, Outlet } from "react-router-dom"
import { useLocation } from "react-router-dom"

export const RequireAuth = ({ children }) => {
    const location = useLocation()

    return localStorage.getItem("GPV1TK") ? (
        <Outlet/>
    ) : (
        <Navigate to="/login" state={{from: location}} replace/>
    )
}