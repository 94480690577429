import React, { useState } from 'react';
import TopDeals from '../../components/topDeals/topDeals';
import GameVoucherTopUp from '../../components/gameVoucherTopUp/gameVoucherTopUp';
import Categories from '../../components/categories/categories';
import PrepaidTopUp from '../../components/prepaidTopUp/prepaidTopUp';
import Payments from '../../components/payments/payments';
import GiftCards from '../../components/giftcards/giftcards';

const ShopHome = ({ searchValue = '' }) => {
  const [selectedIndex, setSelectedIndex] = useState('');

  return (
    <>
      <TopDeals
        searchValue={searchValue}
        setSelectedIndex={setSelectedIndex}
        selectedIndex={selectedIndex}
      />
      <Categories
        searchValue={searchValue}
        setSelectedIndex={setSelectedIndex}
      />
      <GameVoucherTopUp
        searchValue={searchValue}
        setSelectedIndex={setSelectedIndex}
      />
      <PrepaidTopUp
        searchValue={searchValue}
        setSelectedIndex={setSelectedIndex}
        selectedIndex={selectedIndex}
      />
      <GiftCards
        searchValue={searchValue}
        setSelectedIndex={setSelectedIndex}
      />
      <Payments searchValue={searchValue} setSelectedIndex={setSelectedIndex} />
    </>
  );
};

export default ShopHome;
