import React, { useState, useEffect } from 'react';
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { Box, Text, Flex, IconButton, Image, Grid } from '@chakra-ui/react';
import MainLayout from '../../components/mainLayout/mainLayout';
import SkeletonLayout from '../../components/SkeletonLayout';
import Footer from '../../components/footer/footer';
import { ReactComponent as BackIcon } from '../../assets/previous.svg';
import Flag from 'react-world-flags';
import axiosInstance3 from '../../services/api2';
import DefaultImage from '../../assets/voucher.png';


const GiftCardDetails = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [giftCardDetails, setGiftCardDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { imageUrl } = location.state || {};

  useEffect(() => {
    const fetchGiftCardDetails = async () => {
      try {
        const response = await axiosInstance3.get(`/gift-card/${id}`);
        const uniqueDetails = response.data.data.filter(
          (detail, index, self) =>
            index ===
            self.findIndex((d) => d.country.code === detail.country.code)
        );
        setGiftCardDetails(uniqueDetails);
      } catch (error) {
        console.error('Error fetching gift card details:', error);
      } finally {
        setLoading(false);
        setIsSkeletonLoading(false);
      }
    };

    fetchGiftCardDetails();
  }, [id]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleFlagClick = (countryCode, imageUrl) => {
    navigate(
      `/gift-card/available/${id}/${countryCode === 'GB' ? 'UK' : countryCode}`,
      {
        state: { imageUrl,  giftCardName: giftCardDetails[0]?.product?.name || 'Gift Card', giftCardDesciption: giftCardDetails[0]?.product?.howToRedeem || "" },
      }
    );
  };

  return (
    <Flex direction="column" minHeight="100vh">
      <MainLayout
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        flex="1"
      >
        {isSkeletonLoading ? (
          <Box mt="120px">
            <SkeletonLayout />
          </Box>
        ) : (
          <>
            <Box m={{ base: '10px', md: '30px' }}>
              <Flex align="center" mt="120px" zIndex="1000">
                <IconButton
                  icon={<BackIcon />}
                  mr="20px"
                  ml="10px"
                  cursor="pointer"
                  onClick={handleBackClick}
                />
                <Text fontSize="24px" fontWeight="700">
                  {giftCardDetails[0]?.product?.name || 'Gift Cards'}
                </Text>
              </Flex>
              {giftCardDetails.length > 0 ? (
                <Grid
                templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(6, 1fr)' }}
                spacing={4}
                mt="30px"
                >
                  {giftCardDetails.map((detail, index) => (
                    <Box
                      key={index}
                      m={4}
                      position="relative"
                      textAlign="center"
                      width={{ base: '160px', md: '170px' }}
                      border="1px solid none"
                      borderRadius="10px"
                      paddingX="2px" 
                      paddingY="5px"
                      boxShadow="md"
                      cursor="pointer"
                      onClick={() =>
                        handleFlagClick(
                          detail.country.code === 'UK'
                            ? 'GB'
                            : detail.country.code,
                          imageUrl
                        )
                      }
                    >
                      <Box
                        backgroundImage={imageUrl ? `url(${imageUrl})` : 'none'}
                        backgroundSize="cover"
                        backgroundPosition="center"
                        height="200px"
                        width="100%"
                        borderRadius="10px"
                        zIndex="1"
                        position="relative"
                      >
                        <Box
                          position="absolute"
                          bottom="0"
                          right="1"
                          zIndex="2"
                          backgroundColor="transparent"
                          p={1}
                          borderRadius="full"
                        >
                          <Flag
                            code={
                              detail.country.code === 'UK'
                                ? 'GB'
                                : detail.country.code
                            }
                            height="25px"
                            width="25px"
                          />
                        </Box>
                      </Box>

                      <Text
                        mt={3}
                        fontSize={['.8rem', '.9rem']}
                        color="#000119"
                        fontWeight={'600'}
                        maxW={'171px'}
                      >
                        {`${detail.product.name} Gift Card (${detail.country.code})`}
                      </Text>
                    </Box>
                  ))}
                </Grid>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="250px"
                  m={{ base: '10px', md: '30px' }}
                >
                  <Flex
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    my="40px"
                  >
                    <Image
                      src={DefaultImage}
                      height="222px"
                      width="222px"
                      alt="Not available"
                      mb="1rem"
                      mt="2rem"
                    />

                    <Text
                      fontWeight={'400'}
                      fontSize={'16px'}
                      color="#999999"
                      ml="40px"
                      mb="40px"
                    >
                      There are currently no gift cards available .
                    </Text>
                  </Flex>
                </Box>
              )}
            </Box>
          </>
        )}
      </MainLayout>
      <Footer mt="auto" />
    </Flex>
  );
};

export default GiftCardDetails;
