import React, { useState } from 'react';
import Navbar from '../navbar/navbar';
import { Outlet } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

const MainLayout = ({ searchValue, setSearchValue, children }) => {
  return (
    <main
      style={{
        width: '100%',
        overflowX: 'hidden',
      }}
    >
      <Box m={{ base: '10px', md: '30px' }}>
        <Navbar searchValue={searchValue} setSearchValue={setSearchValue} />
      </Box>
      <Box
      width={'100%'}
        // display={'flex'}
        // justifyContent={'center'}
        // // alignItems={'center'}
        // flexDirection={'column'}
        // margin={'0 auto'}
        // padding={'0 1rem'}
      >
        {children}
      </Box>
    </main>
  );
};

export default MainLayout;
