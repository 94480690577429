import User from '../components/sidebar/icons/user.svg';

export default function LightenDarkenColor(col, amt) {
  var num = parseInt(col, 16);
  var r = (num >> 16) + amt;
  var b = ((num >> 8) & 0x00ff) + amt;
  var g = (num & 0x0000ff) + amt;
  var newColor = g | (b << 8) | (r << 16);
  return newColor.toString(16);
}

export function truncateText(text, length) {
  let newText = text;
  if (text?.length > length) {
    newText = text?.slice(0, length);
    return newText + '...';
  }
  return newText;
}

export const readableDate = (date) => {
  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'Africa/Lagos',
  };

  const formattedDate = new Date(date).toLocaleDateString('en-US', options);
  const [month, day, year] = formattedDate.split(' ');
  return ` ${month} ${day} ${year}`;
};

export function convertToProperObject(data) {
  const formattedData = {};

  for (const key in data) {
    const unescapedKey = JSON.parse(key); // Remove the extra quotes using JSON.parse
    formattedData[unescapedKey] = data[key];
  }

  return formattedData;
}

export function removeQuotes(inputString) {
  return inputString.replace(/"/g, '');
}

export const getErrorMessge = (error) => {
  const message = error?.response?.data?.errors[0];

  return message;
};

export const getIntials = (str) => {
  const stringOne = str.split(' ')[0];
  const stringTwo = str.split(' ')[1];

  return stringOne.slice(0, 1) + stringTwo.slice(0, 1);
};

export const getWalletName = (str) => {
  return str?.slice(4, str.length - 1);
};

export const defaultImage = User;
