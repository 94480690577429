import {
  Box,
  Text,
  Flex,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Switch,
  Image,
  Link,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import CustomMenu from '../../../../components/customMenu/customMenu';
import axiosInstance from '../../../../services/api';
import MTN from '../icons/mtn.svg';
import GLO from '../icons/glo.svg';
import Airtel from '../icons/airtel.svg';
import mobile from '../icons/9mobile.svg';
import { ChevronDownIcon } from '@chakra-ui/icons';
import SpinLoader from '../../../../components/spinLoader/spinLoader';
import CustomInput2 from '../../../../components/customInput/customInput';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import { useSelector } from 'react-redux';
import { GetWallet } from '../../../../services/wallet.api';
import Warn from '../../../../assets/warn.svg';
import Success from '../../../../assets/success.svg';
import OtpInput from 'react-otp-input';
import Beneficiary from '../../beneficiary/beneficiary';
import Beneficiary2 from '../beneficiary';
import { formatWholeNumber } from '../../../../utils/toLocaleString';
import PrepaidLoginModal from '../../../../components/prepaidTopUp/prepaidLoginModal';
// import { Link } from 'react-router-dom';

const quickPicks = [100, 200, 500, 1000, 2000];
const purchaseType = ['AIRTIME', 'DATA', 'VOUCHER'];

const Airtime = ({ selectedIndex }) => {
  const [billers, setBillers] = useState([]);
  const [loading, setLoading] = useState(false);
  const airtimeId = JSON.parse(localStorage.getItem('airtimeId'));
  const [selectedBiller, setSelectedBiller] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const currentUser = useSelector(({ userData }) => userData?.currentUser);
  const [error, setError] = useState('');
  const [loadingx, setLoadingx] = useState(false);
  const [succx, setSuccx] = useState(false);
  const [phone, setPhone] = useState('');
  const [amount, setAmount] = useState('');
  const [fullName, setFullName] = useState('');
  const [type, setType] = useState(purchaseType[0]);
  const [saveBeneficiary, setSaveBeneficiary] = useState(false);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [success, setSuccess] = useState(false);
  const [request, setRequest] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [showLoginModal, setShowLoginModal] = useState(
    currentUser ? false : true
  );

  const [otp, setOtp] = useState('');
  const handleSaveBeneficiary = async (data) => {
    let { phoneNumber, fullName, type } = data;
    const response = axiosInstance.post('/vas/beneficiary', data);
  };
  const isLoggedIn = () => {
    const token = localStorage.getItem('GPV1TK');
    return !!token;
  };
  const {
    isOpen: isBeneficiaryOpen,
    onOpen: onBeneficiaryOpen,
    onClose: onBeneficiaryClose,
  } = useDisclosure();

  const {
    isOpen: isNewBeneficiaryOpen,
    onOpen: onNewBeneficiaryOpen,
    onClose: onNewBeneficiaryClose,
  } = useDisclosure();

  const handleChange = (e) => {
    setOtp(e);
  };

  const beneficiaryDisplay = () => {
    onBeneficiaryOpen();
  };

  const getBillers = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get('vas/billers?category=airtime');
      setBillers(data?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getBillerImage = (name) => {
    switch (name) {
      case 'MTN':
        return MTN;
      case 'GLO':
        return GLO;
      case 'Airtel':
        return Airtel;
      case '9mobile':
        return mobile;
      default:
        return null;
    }
  };
  const selectedBillerName =
    selectedBiller?.attributes?.name ||
    billers.find((biller) => biller.attributes.name === selectedIndex)
      ?.attributes?.name;

  const selectedBillerImage = getBillerImage(selectedBillerName);
  const getWallet = async () => {
    setLoading(true);
    try {
      const res = await GetWallet();
      setWallet(res?.data?.balance);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getBeneficiaries = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/vas/beneficiary');
      setBeneficiaries(response?.data?.data?.content);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  function getLastTenDigits(phoneNumber) {
    const digitsOnly = phoneNumber.replace(/\D/g, '');

    const lastTenDigits = digitsOnly.slice(-10);

    return lastTenDigits;
  }

  const handleBeneficiarySelect = (beneficiary) => {
    if (beneficiary && beneficiary.phoneNumber) {
      setPhone(`${getLastTenDigits(beneficiary.phoneNumber)}`);
    } else {
      setPhone('');
    }
    onBeneficiaryClose();
  };

  useEffect(() => {
    getWallet();
  }, [request]);

  useEffect(() => {
    getBillers();
    getBeneficiaries();
  }, []);

  useEffect(() => {
    if (success) {
      onOpen();
    }
  }, [success, request]);

  const billersData = billers
    ?.filter((i) => {
      if (selectedBiller?.id) {
        return i.id !== selectedBiller.id;
      } else if (
        airtimeId !== null &&
        airtimeId !== undefined &&
        billers[airtimeId]
      ) {
        return i.id !== billers[airtimeId].id;
      }

      return true; // Include all items if both selectedBiller?.id and airtimeId are null
    })
    ?.map((i) => ({
      title: i?.attributes?.name,
      value: {
        id: i?.id,
        type: i?.type,
        attributes: i?.attributes,
      },
      icon:
        i?.attributes?.name === 'MTN'
          ? MTN
          : i?.attributes?.name === 'GLO'
          ? GLO
          : i?.attributes?.name === 'Airtel'
          ? Airtel
          : i?.attributes?.name === '9mobile'
          ? mobile
          : null || MTN,
    }));

  const handleBuy = (val) => {
    if (phone !== '') {
      setAmount(val);
    } else {
      toast({
        description: 'Enter recipient phone number',
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const purchase = async () => {
    setLoadingx(true);
    const datax = {
      amount: parseInt(amount),
      provider:
        selectedBiller?.attributes?.name ||
        billers[airtimeId]?.attributes?.name,
      phoneNumber:
        phone.charAt(0) === '0' ? '234' + phone.slice(1) : '234' + phone,
      walletPin: otp,
    };
    if (saveBeneficiary) {
      datax.fullName = fullName;
    }
    try {
      const { data } = await axiosInstance.post('vas/airtime', datax);

      const isBeneficiary = beneficiaries.some(
        (beneficiary) => beneficiary.phoneNumber === datax.phoneNumber
      );

      if (isBeneficiary) {
        setSaveBeneficiary(false);
      } else {
        if (saveBeneficiary) {
          await handleSaveBeneficiary({
            ...datax,
            type: purchaseType[0],
          });
        } else {
          onNewBeneficiaryOpen();
        }
      }
      onOpen();
      setSuccx(true);
      setSuccess(true);

      setFullName('');
    } catch (error) {
      // Handle errors...
      toast({
        title: error?.response?.data?.status,
        description: error?.response?.data?.errors?.[0],
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoadingx(false);
    }
    setRequest(Math.floor(Math.random() * 90) + 10);
    onClose();
    // setAmount('');
    // setPhone('');
  };

  const isContinueDisabled = phone === '' || amount === '';

  return (
    <>
      {loading ? (
        <SpinLoader color="#002E99" />
      ) : (
        <>
          {!isLoggedIn() ? (
            <Text>
              Kindly{' '}
              <Link
                href={'/login'}
                style={{
                  fontWeight: '700',
                  cursor: 'pointer',
                  color: '#002E99',
                  opacity: '1 !important',
                }}
              >
                {' '}
                Log in{' '}
              </Link>{' '}
              or{' '}
              <Link
                href="https://app.gameporte.com/"
                isExternal
                style={{
                  fontWeight: '700',
                  cursor: 'pointer',
                  color: '#002E99',
                  opacity: '1 !important',
                }}
              >
                Create an account{' '}
              </Link>{' '}
              to purchase Airtime
            </Text>
          ) : (
            <Text
              mt="-.5rem"
              fontSize={'.87rem'}
              color="#000119"
              opacity={'.5'}
            >
              Enter purchase details below to proceed.
            </Text>
          )}

          <br />
          <br />
          <CustomMenu
            label={'Network Provider'}
            setSelected={setSelectedBiller}
            data={billersData}
            selected={
              <Flex
                backgroundSize="cover"
                bg="#F5F8FF"
                height="50px"
                alignItems="center"
                borderRadius={'8px !important'}
                px=".65rem"
                justifyContent={'space-between'}
              >
                <Flex gap=".5rem" alignItems="center">
                  <Box
                    height={'25px'}
                    width="25px"
                    borderRadius="50%"
                    backgroundImage={`url(${selectedBillerImage})`}
                    backgroundSize="cover"
                  />
                  <Text
                    fontSize={['.85rem', '.9rem']}
                    fontWeight="500"
                    color="#000119"
                  >
                    {selectedBillerName}
                  </Text>
                </Flex>
                <ChevronDownIcon />
              </Flex>
            }
          />
          {/* main */}

          <CustomInput2
            type={'tel'}
            required
            bg="#F5F8FF"
            placeholder={'Enter phone number'}
            onChange={(e) => setPhone(e.target.value)}
            border="0"
            borderRadius={'8px !important'}
            _placeholder={{ color: '#999999', fontSize: '.85rem' }}
            label="Phone number"
            label3={currentUser && 'Select Beneficiary'}
            beneficialOnClick={beneficiaryDisplay}
            minLength={11}
            maxLength={11}
            value={phone || ''}
          />

          {saveBeneficiary && (
            <>
              <CustomInput2
                value={fullName}
                label={'Full Name'}
                type={'text'}
                bg="#F5F8FF"
                placeholder={'Enter full name'}
                border="0"
                borderRadius={'8px !important'}
                _placeholder={{ color: '#999999', fontSize: '.85rem' }}
                setValue={(e) => setFullName(e.target.value)}
                onChange={(e) => setFullName(e.target.value)}
              />
            </>
          )}

          <CustomInput2
            type="tel"
            required
            bg="#F5F8FF"
            placeholder="0.00"
            onChange={(e) => {
              const value = e.target.value;

              if (/^\d*$/.test(value)) {
                setAmount(value);
                if (value && parseInt(value, 10) < 100) {
                  setError('Minimum amount is 100');
                } else {
                  setError(''); 
                }
              }
            }}
            border="0"
            borderRadius="8px !important"
            _placeholder={{ color: '#999999', fontSize: '.85rem' }}
            label="Amount"
            label3="Fee: 0"
            value={amount}
            inputMode="numeric"
          />

          {error && (
            <Text color="red.500" fontSize=".8rem" fontWeight="600" mt="1">
              {error}
            </Text>
          )}

          <Flex mt="1rem" justifyContent="space-between">
            {quickPicks?.map((i, index) => (
              <Text
                key={index}
                onClick={() => handleBuy(i)}
                cursor="pointer"
                alignItems="center"
                justifyContent="center"
                borderRadius="16px"
                border={'1px solid #CBD5E0'}
                p=".4rem .9rem"
                m={'0 .2rem'}
                fontSize=".8rem"
                color="#999999"
                fontWeight="600"
              >
                {formatWholeNumber(i)}
              </Text>
            ))}
          </Flex>

          <br />
          {currentUser && (
            <Flex alignItems="center">
              <Switch
                isChecked={saveBeneficiary}
                onChange={(e) => setSaveBeneficiary(e.target.checked)}
              />
              <Text ml={2} fontSize="14px" fontWeight="400" color="#999999">
                Save Beneficiary
              </Text>
            </Flex>
          )}
          <br />
          {currentUser && (
            <Text fontSize={'.87rem'} color="#000119" opacity={'.5'}>
              Wallet Balance:{' '}
              <strong
                style={{
                  color: wallet?.availableBalance < amount ? 'red' : '',
                  opacity: '1 !important',
                }}
              >
                {' '}
                {wallet
                  ? `₦${formatWholeNumber(wallet?.availableBalance)}`
                  : 'loading...'}
              </strong>
            </Text>
          )}

          <br />
          <br />
          <PrimaryButton
            onClick={onOpen}
            isDisabled={isContinueDisabled || wallet?.availableBalance < amount || parseInt(amount, 10) < 100}
            text="Continue"
            loading={false}
            bg="#002E99 !important"
            color="#FFF"
          />
        </>
      )}

      <Modal
        isCentered
        motionPreset="slideInBottom"
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setAmount('');
          setPhone('');
          setSuccx(false);
          setOtp('');
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={'1rem'} textTransform={'capitalize'}>
            {}
          </ModalHeader>
          <ModalBody>
            {success && succx ? (
              <>
                <Image src={Success} width={'25%'} mb="1rem" mx="auto" />
                <Text
                  textAlign={'center'}
                  fontSize={'1.2rem'}
                  mb=".2rem"
                  color={'#000119'}
                  fontWeight={'700'}
                >
                  Payment Successful!
                </Text>
                <Text
                  mb="3rem"
                  textAlign={'center'}
                  color={'#475467'}
                  fontWeight={'500'}
                >
                  Top-up of ₦{formatWholeNumber(amount)} to {phone} has been
                  processed successfully.
                </Text>
                <Flex justifyContent={'center'} my="1.3rem">
                  <PrimaryButton
                    onClick={() => {
                      onClose();
                      setAmount('');
                      setPhone('');
                      setSuccx(false);
                      setOtp('');
                    }}
                    bg="#002E99 !important"
                    color="#FFF"
                    text="Close"
                    width="100%"
                    padding="1rem !important"
                    loading={false}
                  />
                </Flex>
              </>
            ) : (
              <>
                <Image
                  src={Warn}
                  width={'15%'}
                  mb="1rem"
                  bg="#F2994A1F"
                  borderRadius={'50%'}
                  p=".8rem"
                  mx="auto"
                />
                <Text
                  textAlign={'center'}
                  fontSize={'1.2rem'}
                  mb=".2rem"
                  color={'#000119'}
                  fontWeight={'700'}
                >
                  Top-Up ₦{formatWholeNumber(amount)} airtime
                </Text>
                <Text
                  textAlign={'center'}
                  color={'#475467'}
                  fontWeight={'500'}
                  mb="1rem"
                >
                  Do you want to proceed and topup ₦{formatWholeNumber(amount)}{' '}
                  airtime on {phone}
                </Text>

                <Box margin="2.5rem auto" width={['85%', '70%']}>
                  <Text
                    textAlign={'center'}
                    mb=".5rem"
                    fontWeight={'500'}
                    fontSize={'.9rem'}
                  >
                    Enter wallet pin
                  </Text>
                  <OtpInput
                    containerStyle={{
                      display: 'flex',
                      margin: '0 auto',
                      justifyContent: 'space-between',
                      gap: '1rem',
                    }}
                    inputStyle={{
                      type: 'password',
                      height: '58px',
                      width: '58px',
                      background: '#F5F8FF',
                      color: '#000',
                      fontSize: '1.1rem',
                      fontWeight: '600',
                      borderRadius: '12px',
                      border: 'none',
                    }}
                    focusStyle={{
                      boxShadow: 'none',
                      outline: 'none',
                    }}
                    value={otp}
                    onChange={handleChange}
                    numInputs={4}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    isInputSecure={true}
                  />
                </Box>

                <Flex justifyContent={'center'} my="1.3rem">
                  <PrimaryButton
                    onClick={purchase}
                    bg="#002E99 !important"
                    color="#FFF"
                    text="Top Up"
                    width="100%"
                    padding="1rem !important"
                    loading={loadingx}
                  />
                </Flex>
              </>
            )}
            {success === false && (
              <Text
                pb="1rem"
                textAlign={'center'}
                fontSize={'.9rem'}
                fontWeight={'500'}
                cursor={'pointer'}
                onClick={onClose}
              >
                Cancel
              </Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isCentered
        motionPreset="slideInBottom"
        isOpen={isBeneficiaryOpen}
        onClose={onBeneficiaryClose}
      >
        <ModalOverlay />
        <ModalContent height="auto" mx={['1rem', 0]}>
          <ModalBody>
            <Beneficiary
              onSelect={handleBeneficiarySelect}
              beneficiaries={beneficiaries}
              hasMore={hasMore}
              loading={loading}
              setPage={setPage}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isNewBeneficiaryOpen}
        onClose={onNewBeneficiaryClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent >
          <ModalBody p={0}>
            <Beneficiary2
              onSelect={onNewBeneficiaryClose}
              phoneNumber={phone}
              onClose={onNewBeneficiaryClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>


      {/* <PrepaidLoginModal
        isOpen={showLoginModal}
        onClose={() => setShowLoginModal(false)}
      /> */}
    </>
  );
};

export default Airtime;
