import React from 'react';
import { Flex } from '@chakra-ui/react';
import BoxSkeleton from './boxSkeleton';

const SkeletonLayout = () => {
  return (
    <Flex flexDirection="column" m="30px" mb="25px">
      <Flex
        direction="row"
        justifyContent="start"
        flexWrap="wrap"
        gap="10px"
        mb="8rem"
      >
        <BoxSkeleton num={4} height="250px" width="200px" borderRadius="12px" />
      </Flex>
      <Flex justifyContent="start" flexWrap="wrap" gap="1rem">
        <BoxSkeleton
          num={3}
          height="250px"
          width={['200px']}
          borderRadius="12px"
        />
      </Flex>
    </Flex>
  );
};

export default SkeletonLayout;
