import React, { useState, useEffect } from 'react';
import HomeSectionRow from '../../components/homeSectionRow/homeSectionRow';
import { Box, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Mtn from '../../assets/mtn.png';
import Game from '../../assets/game.png';
import Itunes from '../../assets/itunes.png';
import Airtel from '../../assets/airtel.png';
import CustomDrawer from '../customDrawer/customDrawer';
import CustomDrawerContent from '../customDrawer/customDrawerContent';
import AirtimeData from '../../pages/products/airtimeData/airtimeData';
import axiosInstance3 from '../../services/api2';
import axios from 'axios';

const TopDeals = ({ searchValue, selectedIndex, setSelectedIndex }) => {
  const [playstationData, setPlaystationData] = useState(null); 
  const [freefireData, setFreefireData] = useState(null);

  const topDealsData = [
    { title: '1% Cashback on Data & Airtime', image: Mtn, id: 3, name: 'MTN' },
    { title: '1% Cashback on Data & Airtime', image: Airtel, id: 4, name: 'Airtel' },
    { title: '10% Discount on PlayStation', image: Itunes, id: 1, name: 'PlayStation' },
    { title: '10% Discount on Free fire', image: Game, id: 2, name: 'Free fire' }
  ];

  const playstationId = '01J3GG4VEA8J65QQGJPVD2M8RN';
  const freefireId = '01HXFXAQQFX98WN3BJSP9R1ED3';

  const navigate = useNavigate();

  const fetchPlaystationImage = async () => {
    try {
      const response = await axiosInstance3.get('/games',  {
        params: {
          size: 30, 
        },
      });
      const playstation = response.data.data.content.find(
        (item) => item.ulid === playstationId
      );

      const freefire = response.data.data.content.find(
        (item) => item.ulid === freefireId
      );

      if (playstation) {
        const playstationData = {
          id: playstation.ulid,
          title: playstation.name,
          imageUrl: playstation.lightModeImageUrl,
        };
        setPlaystationData(playstationData); 
      
      } else {
        console.error('PlayStation data not found');
      }

      if (freefire) {
        const freefireData = {
          id: freefire.ulid,
          title: freefire.name,
          imageUrl: freefire.lightModeImageUrl,
        };
        setFreefireData(freefireData);
       
      } else {
        console.error('Free Fire data not found');
      }
    } catch (error) {
      console.error('Error fetching game images:', error);
    }
  };

  useEffect(() => {
    fetchPlaystationImage();
  }, []);

  const handleTopDealClick = (topDeal) => {
    if (topDeal.name === 'PlayStation' && playstationData) {
      navigate(`/gift-card/${playstationData.id}`, {
        state: { imageUrl: playstationData.imageUrl },
      });
    } else if (topDeal.name === 'Free fire' && freefireData) {
      navigate(`/vouchers/${freefireData.id}`, {
        state: { imageUrl: freefireData.imageUrl },
      });
    }
  };
  
  const filterItems = (items) => {
    return items.filter((item) => 
      item.title.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  const filteredData = filterItems(topDealsData);

  if (filteredData.length === 0) {
    return null;
  }

  return (
    <HomeSectionRow
      title="Top Deals"
      data={filterItems(topDealsData)}
      renderItem={(topDeal, i) => (
        <Box
          key={topDeal.id}
          cursor={'pointer'}
          onClick={() => handleTopDealClick(topDeal)}
        >
          {topDeal.name === 'PlayStation' || topDeal.name === 'Free fire' ? (
            <Box>
              <Box
                height={['165px', '245px']}
                width={['129px', '190px']}
                backgroundImage={`url(${topDeal.image})`}
                backgroundSize="cover"
                backgroundPosition="center"
                borderRadius="md"
              />
              <Text
                fontSize={['.8rem', '16px']}
                mt=".6rem"
                textAlign={'center'}
                color="#000119"
                fontWeight={'700'}
                maxW={'171px'}
              >
                {topDeal.title}
              </Text>
            </Box>
          ) : (
            <CustomDrawer
              key={topDeal.id}
              position={'right'}
              title={'Top Deals'}
              triggerClose={false}
              toggleElement={
                <Box
                  flex={'0 0 200px'}
                  cursor={'pointer'}
                  onClick={() => {
                    setSelectedIndex(topDeal.name);
                  }}
                >
                  <Box
                    height={['165px', '245px']}
                    width={['129px', '190px']}
                    backgroundImage={`url(${topDeal.image})`}
                    backgroundSize="cover"
                    backgroundPosition="center"
                    borderRadius="md"
                  />
                  <Text
                    fontSize={['.8rem', '16px']}
                    mt=".6rem"
                    textAlign={'center'}
                    color="#000119"
                    fontWeight={'700'}
                    maxW={'171px'}
                  >
                    {topDeal.title}
                  </Text>
                </Box>
              }
              content={
                topDeal.name === 'MTN' || topDeal.name === 'Airtel' ? (
                  <AirtimeData selectedIndex={selectedIndex} />
                ) : (
                  <CustomDrawerContent title={topDeal.title} onClose={() => {}} />
                )
              }
            />
          )}
        </Box>
      )}
    />
  );
};

export default TopDeals;
