import { Box, Flex, Image, Text } from "@chakra-ui/react";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { useState } from "react";
import ShopHome from "./shopHome";
import BoxSkeleton from "../../components/boxSkeleton";
import MainLayout from "../../components/mainLayout/mainLayout";
import Freefire from "../../assets/freefirehome.png";
import GTAV from "../../assets/gtavhome.png";
import Fortnite from "../../assets/fortnitehome.png";
import CODM from "../../assets/codmhome.png";
import Sims from "../../assets/simshome.png";
import FIFA from "../../assets/fifahome.png";
import PUBG from "../../assets/pubghome.png";

const HomeModule = () => {
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const cardData = [
    { image: Freefire, name: "Free Fire" },
    { image: GTAV, name: "GTA V" },
    { image: Fortnite, name: "Fortnite" },
    { image: CODM, name: "Call of Duty Mobile" },
    { image: Sims, name: "The SIMS 4" },
    { image: FIFA, name: "EAFC 25" },
    { image: PUBG, name: "PUBG" },
  ];

  return (
    <>
      {isLoading ? (
        <Flex flexDirection="column" m="30px" mb="25px">
          <BoxSkeleton
            num={1}
            height="50px"
            width="90%"
            borderRadius="36px"
            mb="40px"
          />
          {/* Navbar skeleton */}
          <Flex
            direction="row"
            justifyContent="start"
            flexWrap="wrap"
            gap="10px"
            mb="5rem"
          >
            <BoxSkeleton
              num={4}
              height="250px"
              width="200px"
              borderRadius="12px"
            />
          </Flex>
          <Flex justifyContent="start" flexWrap="wrap" gap="1rem">
            <BoxSkeleton
              num={3}
              height="250px"
              width="200px"
              borderRadius="12px"
            />
          </Flex>
        </Flex>
      ) : (
        <>
          <Box bg="#FFF !important">
            {/* <Navbar searchValue={searchValue} setSearchValue={setSearchValue} /> */}
            <MainLayout
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            >
              {/* Scrollable row of cards, hidden on mobile */}
              <Box
                display={["none", "block"]}
                overflowX="auto"
                whiteSpace="nowrap"
                mt="2rem"
                sx={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  "-ms-overflow-style": "none",
                  "scrollbar-width": "none",
                }}
              >
                {cardData.map((card, index) => (
                  <Box
                    key={index}
                    display="inline-block"
                    width="250px"
                    height="300px"
                    textAlign="center"
                    mt="120px"
                  >
                    <Image
                      src={card.image}
                      alt={card.name}
                      width="100%"
                      height="100%"
                    />
                    <Text
                      textAlign={"left"}
                      fontWeight={"700"}
                      fontSize={"16px"}
                      mt="0.5rem"
                      ml={"10px"}
                    >
                      {card.name}
                    </Text>
                  </Box>
                ))}
              </Box>
              <Box
                maxWidth={"1200px"}
                display={"flex"}
                justifyContent={"center"}
                // alignItems={'center'}
                flexDirection={"column"}
                margin={"0 auto"}
                padding={["0","0 2rem"]}
              >
                <Box
                  p={[".2rem 0", "0 1rem"]}
                  mx={[".5rem", "2rem"]}
                  mb="0 !important"
                  mt={["85px", "60px"]}
                >
                  <ShopHome searchValue={searchValue} />
                </Box>
              </Box>
            </MainLayout>
          </Box>
          <Footer />
        </>
      )}
    </>
  );
};

export default HomeModule;
