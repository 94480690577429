import React, { useState, useEffect } from "react";
import { Box, Flex, Text, IconButton, Grid, Button } from "@chakra-ui/react";
import MainLayout from "../../components/mainLayout/mainLayout";
import Footer from "../../components/footer/footer";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../assets/previous.svg";
import axiosInstance3 from "../../services/api2";
import GiftCardTopUpItem from "../../components/giftCardTopUpItem/giftCardTopUpItem";
import SkeletonLayout from "../../components/SkeletonLayout";

const GiftCardsTopUp = () => {
  const [giftCards, setGiftCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const fetchGiftCards = async (currentPage) => {
    try {
      const response = await axiosInstance3.get("/games", {
        params: {
          // size: 30,
          page: currentPage,
          productType: "GIFT_CARD",
        },
      });

      const { content, totalPages, last } = response.data.data;

      const mappedData = content
        .filter((item) => item.productType === "GIFT_CARD")
        .map((item) => ({
          id: item.ulid,
          title: item.name,
          image: item.lightModeImageUrl,
          name: item?.name,
          howToRedeem: item?.howToRedeem,
        }));

      setGiftCards((prevItems) =>
        currentPage === 0 ? mappedData : [...prevItems, ...mappedData]
      );

      setTotalPages(totalPages);
      setHasMore(!last);
    } catch (error) {
      console.error("Error fetching gift cards:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGiftCards(0);
  }, []);

  const filteredItems = giftCards.filter((item) =>
    item.title.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleViewMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchGiftCards(nextPage);
  };

  return (
    <MainLayout searchValue={searchValue} setSearchValue={setSearchValue}>
      <Box mt="120px">
        <Box px={{ base: "10px", md: "30px" }}>
          <Flex align="center" mb={4} justifyContent="space-between">
            <Flex align="center">
              <IconButton
                icon={<BackIcon />}
                mr="20px"
                cursor="pointer"
                onClick={handleBackClick}
              />
              <Text fontSize="24px" fontWeight="700">
                Gift Cards Top-Up
              </Text>
            </Flex>
            {hasMore && !loading && (
              <Button bg="white" onClick={handleViewMore}>
                View More
              </Button>
            )}
          </Flex>
          {loading ? (
            <Box mt="50px">
              <SkeletonLayout />
            </Box>
          ) : filteredItems.length === 0 ? (
            <Text textAlign="center" color="gray.500" mt={10}>
              No gift cards available.
            </Text>
          ) : (
            <>
              <Grid
                templateColumns={{
                  base: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                  lg: "repeat(6, 1fr)",
                }}
                gap={4}
              >
                {filteredItems.map((item) => (
                  <GiftCardTopUpItem
                    key={item.id}
                    id={item.id}
                    title={item.title}
                    image={item.image}
                    name={item?.name}
                    howToRedeem={item?.howToRedeem}
                  />
                ))}
              </Grid>
            </>
          )}
        </Box>
      </Box>
      <Box mt={8}>
        <Footer />
      </Box>
    </MainLayout>
  );
};

export default GiftCardsTopUp;
