import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Text, useToast } from '@chakra-ui/react';

const GameVoucherTopUpItem = ({ id, title, image, name, howToRedeem }) => {
  const navigate = useNavigate();
  const toast = useToast();

  const handleClick = () => {
    navigate(`/vouchers/${id}`, { state: { imageUrl: image } });
  };

  const handleVoucherClick = () => {
    try {
      if (!id) {
        throw new Error('No vouchers available for this game.');
      }
      navigate(`/vouchers/${id}`, {
        state: { imageUrl: image, name: name, howToRedeem: howToRedeem },
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      key={id}
      onClick={handleVoucherClick}
      cursor={id ? 'pointer' : 'not-allowed'}
      opacity={id ? '1' : '0.5'}
      maxW="200px"
      mx="auto"
      mb="1rem"
    >
      <Box position="relative" height={['165px', '245px']} width={['129px', '190px']}>
        <Box
          height="100%"
          width="100%"
          backgroundImage={`url(${image})`}
          backgroundSize="cover"
          backgroundPosition="center"
          borderRadius="md"
          overflow="hidden"
          boxShadow="lg"
        />
      
        <Box
          position="absolute"
          top={["-5", "3"]}
          left={["6", "14"]}
          width="100%"
          height="100%"
          clipPath="path('M35 0C32.2386 0 30 2.23858 30 5H5C2.23858 5 0 7.23858 0 10C0 12.7614 2.23858 15 5 15H65C67.7614 15 70 12.7614 70 10C70 7.23858 67.7614 5 65 5H40C40 2.23858 37.7614 0 35 0Z')"
          backgroundColor="white" 
          transform={["scale(0.7)", "scale(1)"]} 
        />
      </Box>

      <Text
        fontSize={['.8rem', '16px']}
        mt=".6rem"
        textAlign="center"
        color="#000119"
        fontWeight="700"
        maxW="171px"
        mx="auto"
      >
        {title}
      </Text>
    </Box>
  );
};

export default GameVoucherTopUpItem;
