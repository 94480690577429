import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HomeSectionRow from '../../components/homeSectionRow/homeSectionRow';
import { Box, Text, Flex, Button } from '@chakra-ui/react';
import axiosInstance3 from '../../services/api2';

const GiftCards = ({ searchValue }) => {
  const [giftCards, setGiftCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();

  const fetchGiftCards = async (currentPage) => {
    try {
      const response = await axiosInstance3.get('/games', {
        params: {
          // size: 30,
          page: currentPage,
          productType: 'GIFT_CARD',
        },
      });

      const { content, totalPages, last } = response.data.data;

      const mappedGiftCards = content.map((item) => ({
        id: item.ulid,
        title: item.name,
        image: item.lightModeImageUrl,
      }));

      setGiftCards((prevItems) =>
        currentPage === 0 ? mappedGiftCards : [...prevItems, ...mappedGiftCards]
      );
      setTotalPages(totalPages);
      setHasMore(!last);
    } catch (error) {
      console.error('Error fetching gift cards:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGiftCards(0);
  }, []);

  const filterItems = (items) => {
    return items.filter((item) =>
      item.title.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  const handleViewMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchGiftCards(nextPage);
  };

  const filteredData = filterItems(giftCards);

  if (filteredData.length === 0 && !loading) {
    return null;
  }

  const handleCardClick = (id, image) => {
    navigate(`/gift-card/${id}`, {
      state: { imageUrl: image },
    });
  };

  return (
    <HomeSectionRow
      title="Gift Cards"
      loading={loading}
      data={filteredData}
      renderItem={(item) => (
        <Box
          key={item.id}
          flexWrap="wrap"
          gap="1.2rem"
          cursor="pointer"
          onClick={() => handleCardClick(item.id, item.image)}
        >
          <Box position="relative" height={['165px', '245px']} width={['129px', '190px']}>
            <Box
              height="100%"
              width="100%"
              backgroundImage={`url(${item.image})`}
              backgroundSize="cover"
              backgroundPosition="center"
              borderRadius="md"
              overflow="hidden"
              boxShadow="lg"
            />
            <Box
              position="absolute"
              top={['-5', '3']}
              left={['6', '14']}
              width="100%"
              height="100%"
              clipPath="path('M35 0C32.2386 0 30 2.23858 30 5H5C2.23858 5 0 7.23858 0 10C0 12.7614 2.23858 15 5 15H65C67.7614 15 70 12.7614 70 10C70 7.23858 67.7614 5 65 5H40C40 2.23858 37.7614 0 35 0Z')"
              backgroundColor="white"
              transform={['scale(0.7)', 'scale(1)']}
            />
          </Box>
          <Text
            fontSize={['.8rem', '16px']}
            mt=".6rem"
            textAlign="center"
            cursor="pointer"
            color="#000119"
            fontWeight="700"
            maxW={{ base: '135px', md: '171px' }}
          >
            {item.title}
          </Text>
        </Box>
      )}
      routePath="/categories/gift-cards"
      footer={
        hasMore && !loading && (
          <Flex justifyContent="center" width="full" mt={4}>
            <Button onClick={handleViewMore} colorScheme="blue" variant="outline">
              View More
            </Button>
          </Flex>
        )
      }
    />
  );
};

export default GiftCards;
