import React from "react";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/react";
import Beneficiary2 from "../beneficiary";

const Beneficiary2Modal = ({isNewBeneficiaryOpen, onNewBeneficiaryClose, phone}) => {

  return (
    <div>
      <Modal
        isOpen={isNewBeneficiaryOpen}
        onClose={onNewBeneficiaryClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent height="auto" mx={["1rem", 0]}>
          <ModalBody>
            <Beneficiary2
              onSelect={onNewBeneficiaryClose}
              phoneNumber={phone}
              onClose={onNewBeneficiaryClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Beneficiary2Modal;
