import { combineReducers } from 'redux';

import userReducer from './userReducer'
import uiReducer from './uiReducers'
import cartReducer from './cartReducer';

const allReducers = combineReducers({
    userData: userReducer,
    uiState: uiReducer,
    cart: cartReducer,
  });
  
  export default allReducers;
  