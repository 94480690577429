import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axiosInstance2 from '../../../services/UnAuthApi';
import {
  Box,
  Text,
  Flex,
  Grid,
  IconButton,
  Drawer,
  Image,
  Divider,
  Button,
  Spinner,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  Input,
  Center,
  InputGroup,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from '@chakra-ui/react';
import SkeletonLayout from '../../../components/SkeletonLayout';
import MainLayout from '../../../components/mainLayout/mainLayout';
import Footer from '../../../components/footer/footer';
import Flag from 'react-world-flags';
import { ReactComponent as BackIcon } from '../../../assets/previous.svg';
import { useToast } from '@chakra-ui/react';
import { CartContext } from '../../myCart/cartContext';
import { useSelector } from 'react-redux';
import buyIcon from '../../../assets/buy.svg';
import cartIcon from '../../../assets/cart.svg';
import Card from '../../../assets/payCard.png';
import Wallet from '../../../assets/payWallet.png';
import EnterPin from '../../../assets/enterPin.png';
import PinSuccess from '../../../assets/pinSuccess.png';
import DefaultImage from '../../../assets/voucher.png';
import Success from '../../../assets/success.png';
import OtpInput from 'react-otp-input';
import { FaRegEnvelope, FaRegCopy } from 'react-icons/fa6';
import { GetWallet } from '../../../services/wallet.api';
import PrimaryButton from '../../../components/primaryButton/primarybutton';
import { useSearchParams } from 'react-router-dom';
import axiosInstance3 from '../../../services/api2';
import PrepaidLoginModal from '../../../components/prepaidTopUp/prepaidLoginModal';

const GiftCardAvailable = () => {
  const { id, countryCode } = useParams();
  const [searchParams] = useSearchParams();
  // const imageUrl = searchParams.get('image');
  const [availableCards, setAvailableCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showPaymentDrawer, setShowPaymentDrawer] = useState(false);
  const [showWalletDrawer, setShowWalletDrawer] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [cartItems, setCartItems] = useState([]);
  const [wallet, setWallet] = useState(0);
  const [otp, setOtp] = useState('');
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { updateCartItems } = useContext(CartContext);
  const [triesLeft, setTriesLeft] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [payLoading, setPayLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showModal, setShowModal] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [refetch, setRefetch] = useState(false); 
  const [showDeliveryDrawer, setShowDeliveryDrawer] = useState(false);
  const [emailIsLoading, setEmailIsLoading] = useState(true);
  const [walletDetails, setWalletDetails] = useState(null);
  const [selectedState, setSelectedState] = useState('');
  const [recipientName, setRecipientName] = useState('');
  const [recipientPhoneNumber, setRecipientPhoneNumber] = useState('');
  const [isSaveDeliveryDetailsChecked, setIsSaveDeliveryDetailsChecked] =
    useState(false);
  const [activeButton, setActiveButton] = useState(false);
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [selectedGiftCard, setSelectedGiftCard] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isCartLoading, setIsCartLoading] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const currentUser = useSelector(({ userData }) => userData?.currentUser);
  const currentEmail = useSelector(
    ({ userData }) => userData?.userProfile?.userProfile?.email
  );
  const currentRecipientId = useSelector(
    ({ userData }) => userData?.userProfile?.userProfile?.id
  );

  const userLocation = useSelector(
    ({ userData }) => userData?.userLocation?.country
  );
  const location = useLocation();
  const { giftCardName, imageUrl, giftCardDesciption } = location.state || {};
  const [imageData, setImageData] = useState(imageUrl || null);

  const handleOpenLoginModal = () => {
    sessionStorage.setItem('image', JSON.stringify(imageData));
    setShowLoginModal(true);
  };
  

  const restoreImage = () => {
    const savedImage = sessionStorage.getItem('image');
    if (savedImage) {
      setImageData(JSON.parse(savedImage)); 
    }
  };

  useEffect(() => {}, [cartItems]);

  useEffect(() => {
    const fetchAvailableGiftCards = async () => {
      try {
        const response = await axiosInstance3.get('/gift-card/available', {
          params: {
            productId: id,
            countryCode: countryCode,
          },
        });

        // Set the state with all the gift cards from the response
        setAvailableCards(response.data.data);
      } catch (error) {
        console.error('Error fetching available gift cards:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAvailableGiftCards();
  }, [id, countryCode, refetch]);

  useEffect(() => { 
    const fetchUserData = async () => { 
      try { 
        if (currentUser) { 
          const storedWallet = localStorage.getItem('wallet'); 
          const storedEmail = localStorage.getItem('email'); 
   
          if (storedWallet && storedEmail) { 
            setWallet(JSON.parse(storedWallet)); 
            setEmail(storedEmail); 
          } else { 
            const balance = await GetWallet(); 
            const availableBalance = balance?.data?.balance?.availableBalance; 
            
            const emailToSet = currentEmail || storedEmail || '';
            setEmail(emailToSet);
   
            setWallet(availableBalance); 
            setWalletDetails(balance?.data?.details); 
   
            localStorage.setItem('wallet', JSON.stringify(availableBalance)); 
            localStorage.setItem('email', emailToSet); 
          } 
        }
      } catch (error) { 
        console.error(error); 
      } finally { 
        setEmailIsLoading(false); 
      } 
    }; 
    
    fetchUserData();
}, [currentUser, currentEmail]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const isLoggedIn = () => {
    const token = localStorage.getItem('GPV1TK');
    return !!token;
  };

  const handleItemClick = (card) => {
    setSelectedGiftCard(card);
    setShowSidebar(true);
  };

  const handleQuantityChange = (e) => {
    const newQuantity = Number(e.target.value);

    if (newQuantity > selectedGiftCard.count) {
      setQuantity(selectedGiftCard.count);
      toast({
        title: '',
        description: `${selectedGiftCard.count} ${
          selectedGiftCard.count === 1 ? 'unit is' : 'units are'
        } available for ${selectedGiftCard.name}.`,
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    } else {
      setQuantity(newQuantity);
    }
  };

  const onClose = () => setShowSidebar(false);
  const onPaymentDrawerClose = () => setShowPaymentDrawer(false);

  const handleEmailChange = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(e.target.value);
    setEmail(e.target.value);
    setValidEmail(isValid);
  };

  const handleBoxClick = () => {
    setSelectedPaymentMethod('wallet');
    setShowWalletDrawer();
    handleContinue();
  };

  const handlePaymentConfirmation = () => {
    // Logic to handle payment confirmation
    setIsPaymentConfirmed(true);
    setShowDrawer(true);
    setShowModal('payment');
  };

  const handleContinue = async () => {
    try {
      const deliveryDetails = {
        state: selectedState,
        address,
        recipientName,
        email,
        recipientPhoneNumber,
        isSaveDeliveryDetailsChecked,
      };

      const priceAfterDiscount =
        selectedGiftCard?.settingsModel?.discountPercent !== 0
          ? selectedGiftCard?.settingsModel?.price -
            (selectedGiftCard?.settingsModel?.price *
              selectedGiftCard?.settingsModel?.discountPercent) /
              100
          : selectedGiftCard?.settingsModel?.price;

      const totalCost = priceAfterDiscount * quantity;

      const canPay = wallet >= totalPrice;
      handleDeliveryDetails(deliveryDetails);
      setActiveButton(canPay);
      setShowDeliveryDrawer(false);
      setShowWalletDrawer(true);
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const totalPrice = selectedGiftCard
    ? Math.round(
        (selectedGiftCard?.settingsModel?.nairaPrice -
          (selectedGiftCard?.settingsModel?.nairaPrice *
            selectedGiftCard?.settingsModel?.discountPercent) /
            100) *
          quantity
      )
    : 0;

  const priceAfterDiscount =
    selectedGiftCard?.settingsModel?.discountPercent !== 0
      ? Math.round(
          selectedGiftCard?.settingsModel?.nairaPrice -
            (selectedGiftCard?.settingsModel?.nairaPrice *
              selectedGiftCard?.settingsModel?.discountPercent) /
              100
        )
      : selectedGiftCard?.settingsModel?.nairaPrice;

  const totalCost = priceAfterDiscount * quantity;

  const handleDeliveryDetails = (deliveryDetails) => {
    // Implement your logic to handle the delivery details here
  };

  //   const handleAddToCart = async (card) => {
  //     if (!isLoggedIn()) {
  //       setShowLoginModal(true);
  //       return;
  //     }

  //     try {
  //       setIsLoading(true);

  //       const priceToUse =
  //         card.settingsModel.discountPercent !== 0
  //           ? card.settingsModel.nairaPrice -
  //             (card.settingsModel.nairaPrice *
  //               card.settingsModel.discountPercent) /
  //               100
  //           : card.settingsModel.nairaPrice;

  //       const cartItem = {
  //         ...card,
  //         price: priceToUse,
  //         checkoutType: "MANUAL",
  //         recipientId: currentRecipientId,
  //         recipientEmail: email,
  //         quantity: quantity,
  //         itemImageUrl: `https://flagcdn.com/${card.country.code.toLowerCase()}.svg`,
  //         itemType: card.settingsModel.type
  //       };

  //       const response = await axiosInstance2.post("/cart", {
  //         cartItems: [cartItem],
  //       });

  //       if (response.data.status === 200) {
  //         setCartItems((prevCartItems) => [...prevCartItems, cartItem]);
  //         updateCartItems((prevCartItems) => [...prevCartItems, cartItem]);

  //         toast({
  //           title: "Added to Cart",
  //           description: `${card.name} has been added to your cart.`,
  //           status: "success",
  //           duration: 3000,
  //           isClosable: true,
  //         });

  //         setShowSuccess(true);
  //       } else {
  //         throw new Error("Failed to add item to cart");
  //       }
  //     } catch (error) {
  //       console.error("Error adding to cart:", error);
  //       toast({
  //         title: "Error",
  //         description: "Failed to add item to cart. Please try again.",
  //         status: "error",
  //         duration: 5000,
  //         isClosable: true,
  //       });
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  const handleAddToCart = async (card, showSuccessModal = true) => {
    try {
      setIsLoading(true);
      setIsCartLoading(true);

      if (!card) {
        card = selectedGiftCard;
      }

      const { settingsModel } = card;
      const priceToUse =
        card.settingsModel.discountPercent !== 0
          ? card.settingsModel.nairaPrice -
            (card.settingsModel.nairaPrice *
              card.settingsModel.discountPercent) /
              100
          : card.settingsModel.nairaPrice;

      const cartItems = [
        {
          ...card,
          price: priceToUse,
          checkoutType: 'MANUAL',
          recipientId: currentRecipientId,
          recipientEmail: email,
          quantity: quantity,
          itemImageUrl: imageUrl,
          itemType: 'GIFT_CARD',
        },
      ];

      const response = await axiosInstance2.post('/cart', {
        cartItems,
      });

      if (response.data.status === 200) {
        setLoadingCart(false);
        setIsConfirmationModalOpen(false);
        if (showSuccessModal) {
          setShowSuccess(true);
        }

        // Update the cart items in the context
        // updateCartItems((prevCartItems) => [...prevCartItems, ...cartItems]);
        updateCartItems((prevCartItems) => [...prevCartItems, ...cartItems]);
      } else {
      }
    } catch (error) {
      handleAddToCartError(error);
    } finally {
      setIsLoading(false);
      setIsCartLoading(false);
      setShowSidebar(false);
    }
  };

  const handleAddToCartError = (error, response) => {
    if (response?.status === 500 || response?.status === 501) {
      toast({
        title: '',
        description:
          'An error occurred while processing this request, please contact administrator',
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    } else if (response?.data?.errors[0]?.includes('profile inactive')) {
      // onOpen();
    } else {
      toast({
        title: 'An Error Occurred',
        description: response?.data?.errors[0],
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handlePayGiftCard = async () => {
    setPayLoading(true);

    try {
      await axiosInstance2.put('/cart/clear');
      console.log('Selected Gift Card:', selectedGiftCard);

      await handleAddToCart(selectedGiftCard, false);

      const data = {
        walletPin: otp,
        checkoutType: 'MANUAL',
      };

      const response = await axiosInstance2.post('/cart/checkout', data);

      if (response.status === 200) {
        setShowSuccessModal(true);
        setShowModal('');
        setShowWalletDrawer(false);
        setShowDeliveryDrawer(false);
        setShowPaymentDrawer(false);
        setShowSidebar(false);
        updateCartItems(cartItems, selectedGiftCard);
      } else {
        throw new Error('Payment failed');
      }
    } catch (error) {
      if (error?.response?.status === 500 || error?.response?.status === 501) {
        toast({
          title: '',
          description:
            'An error occurred while processing this request, please contact administrator',
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'An Error Occurred',
          description: error?.response?.data?.errors[0],
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setPayLoading(false);
      setIsPaymentConfirmed(false);
      setOtp('');
    }
  };

  const handleChange = (e) => {
    setOtp(e);
  };
  return (
    <>
      <Flex direction="column" minHeight="100vh">
        <MainLayout>
          {loading ? (
            <Box mt="120px">
              <SkeletonLayout />
            </Box>
          ) : (
            <Box m={{ base: '10px', md: '30px' }}>
            <Flex align="center" mt="120px" zIndex="1000">
              <IconButton
                icon={<BackIcon />}
                mr="20px"
                ml="10px"
                cursor="pointer"
                onClick={handleBackClick}
              />
              <Text fontSize="24px" fontWeight="700">
                Available Gift Cards for {giftCardName} {countryCode}
              </Text>
            </Flex>
            {availableCards.length > 0 ? (
              <Grid
              templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)', lg: 'repeat(6, 1fr)' }}
              // templateColumns="repeat(auto-fit, minmax(150px, 1fr))"
                spacing={5}
                // gap={7}
                mt="30px"
              >
                {availableCards
                  .sort(
                    (a, b) => a.settingsModel.nairaPrice - b.settingsModel.nairaPrice
                  )
                  .map((card, index) => {
                    const isNigeria = userLocation === 'NG';
                    const price = isNigeria
                      ? card.settingsModel.nairaPrice
                      : card.settingsModel.dollarPrice;
                    const discountPercent = card.settingsModel.discountPercent;
                    const discountPrice = price - (discountPercent / 100) * price;
          
                    return (
                      <Box
                        key={index}
                        m={4}
                        position="relative"
                        textAlign="left"
                        width={{ base: '160px', md: '170px' }}
                        border="1px solid none"
                        borderRadius="10px"
                        paddingX="2px"
                        paddingY="5px"
                        boxShadow="md"
                        onClick={() => handleItemClick(card)}
                        cursor="pointer"
                      >
                        <Box
                          backgroundImage={imageUrl ? `url(${imageUrl})` : 'none'}
                          backgroundSize="cover"
                          backgroundPosition="center"
                          height="200px"
                          width="100%"
                          borderRadius="10px"
                          zIndex="1"
                          position="relative"
                        />
                        <Box
                          position="absolute"
                          top="0"
                          right="0"
                          zIndex="2"
                          backgroundColor="transparent"
                          py={2}
                          pl={2}
                          pr={1.5}
                          borderRadius="full"
                        >
                          <Flag
                            code={card.country.code === 'UK' ? 'GB' : card.country.code}
                            height="60px"
                            width="60px"
                          />
                        </Box>
                        <Text
                          mt={3}
                          fontSize={['.8rem', '.9rem']}
                          color="#000119"
                          fontWeight={'600'}
                          maxW={'171px'}
                        >
                          {card.name}
                        </Text>
                        <Text
                          textColor="#999999"
                          fontWeight="400px"
                          fontSize="14px"
                          fontStyle={'italic'}
                        >
                          {`Remaining: ${card?.count}`}
                        </Text>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Text
                            fontSize={['.8rem', '.9rem']}
                            fontWeight={'700'}
                            maxW={'171px'}
                            color={discountPercent > 0 ? 'gray.500' : 'gray.800'}
                            textDecoration={discountPercent > 0 ? 'line-through' : 'none'}
                          >
                            {isNigeria
                              ? `₦${Math.round(
                                  card.settingsModel.nairaPrice
                                ).toLocaleString()}`
                              : `$${Math.round(
                                  card.settingsModel.dollarPrice
                                ).toLocaleString()}`}.00
                          </Text>
                          {discountPercent > 0 && (
                            <Text fontSize={['.8rem', '.9rem']} fontWeight={'600'} color="black" ml={2}>
                              {isNigeria ? '₦' : '$'}
                              {Math.round(discountPrice).toLocaleString()}
                            </Text>
                          )}
                        </Box>
                        <Button
                          width={'100%'}
                          mt={2}
                          bg={'#002E99'}
                          textColor={'#FFF'}
                          borderRadius={'20px'}
                          py={1}
                          fontWeight={400}
                        >
                          Buy now
                        </Button>
                      </Box>
                    );
                  })}
              </Grid>
            ) : (
              <Flex
                direction="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                height="400px"
                my="20px"
              >
                <Image src={DefaultImage} height="222px" width="222px" alt="Not available" mb="1rem" mt="2rem" />
                <Text fontWeight={'400'} fontSize={'16px'} color="#999999">
                  There are no gift cards available for {giftCardName} {countryCode}.
                </Text>
              </Flex>
            )}
          </Box>
          
          )}
        </MainLayout>
            <Box m={{ base: '10px', md: '30px' }}
      dangerouslySetInnerHTML={{
        __html: giftCardDesciption || '',
      }}
    />
        <Footer mt="auto" />
      </Flex>

      {selectedGiftCard &&
        (() => {
          const { nairaPrice, dollarPrice, discountPercent } =
            selectedGiftCard.settingsModel;
          const price = userLocation === 'NG' ? nairaPrice : dollarPrice;

          const calculateDiscountPrice = (price, discountPercent = 0) =>
            price * (1 - discountPercent / 100);

          const discountPrice = calculateDiscountPrice(price, discountPercent);

          return (
            <Drawer isOpen={showSidebar} placement="right" onClose={onClose}>
              <DrawerOverlay />
              <DrawerContent maxWidth="500px">
                <DrawerCloseButton
                  border={'1px solid gray'}
                  borderRadius="50%"
                />
                <DrawerBody marginTop={'2.5rem'}>
                  <Flex align="center" justify="center">
                    <Box 
                      backgroundSize="cover"
                      backgroundPosition="center"
                      height="240px"
                      width="100%" 
                      backgroundImage={
                              imageUrl ? `url(${imageUrl})` : 'none'
                            }
                      borderRadius="10px"
                    />
                  </Flex>
                  <Text my="24px" fontSize="24px" fontWeight="700">
                    {selectedGiftCard.name}
                  </Text>
                  <Text fontSize="16px" fontWeight="700">
                    Purchase details
                  </Text>
                  <Box
                    bg="#F5F8FF"
                    p="10px"
                    borderRadius="8px"
                    mb="10px"
                    textColor="#999999"
                    fontSize="14px"
                    fontWeight="400"
                  >
                    <Flex justifyContent="space-between">
                      <Text>Price</Text>
                      {/* <Text
                  color={discountPercent > 0 ? 'gray.500' : '#000'}
                  textDecoration={discountPercent > 0 ? 'line-through' : 'none'}
                >
                  {isNigeria ? '₦' : '$'}{price?.toLocaleString()}
                </Text> */}

                      <Text color="black">
                        {userLocation == 'NG' ? '₦' : '$'}
                        {Math.round(discountPrice).toLocaleString()}
                      </Text>
                    </Flex>
                  </Box>
                  <Flex direction="column" gap="10px">
                    <Box>
                      <Text
                        mb="5px"
                        textColor="#999999"
                        fontWeight="400px"
                        fontSize="12px"
                      >
                        Quantity
                      </Text>
                      <Flex
                        alignItems="center"
                        bg="#F5F8FF"
                        p="10px"
                        borderRadius="8px"
                      >
                        <Input
                          flex="1"
                          height="30px"
                          value={quantity || ''}
                          onChange={handleQuantityChange}
                          type="number"
                          min="1"
                          border="none"
                          placeholder="Enter quantity here"
                          _placeholder={{
                            color: '#999999',
                            fontWeight: '400',
                            fontSize: '14px',
                          }}
                          sx={{
                            '&:focus': {
                              outline: 'none',
                              boxShadow: 'none',
                            },
                          }}
                        />
                      </Flex>
                      <Text
                        textColor="#999999"
                        fontWeight="400px"
                        fontSize="14px"
                        fontStyle={'italic'}
                      >
                        {`Remaining: ${selectedGiftCard?.count}`}
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        mb="5px"
                        textColor="#999999"
                        fontWeight="400px"
                        fontSize="12px"
                      >
                        Recipient mail
                      </Text>
                      <Flex
                        alignItems="center"
                        bg="#F5F8FF"
                        p="10px"
                        borderRadius="8px"
                      >
                        <InputGroup flex="1">
                          <Input
                            height="30px"
                            value={email || ''}
                            onChange={handleEmailChange}
                            type="email"
                            border={validEmail ? 'none' : '1px solid red'}
                            placeholder={email ? email : 'user@example.com'}
                            _placeholder={{
                              color: '#999999',
                              fontWeight: '400',
                              fontSize: '14px',
                            }}
                            sx={{
                              '&:focus': {
                                outline: 'none',
                                boxShadow: 'none',
                              },
                            }}
                          />
                          <InputRightElement>
                            <FaRegEnvelope />
                          </InputRightElement>
                        </InputGroup>
                      </Flex>
                      {!validEmail && (
                        <Text color="red" fontSize="12px" mt="2px">
                          A valid email address is required
                        </Text>
                      )}
                    </Box>
                    <Box>
                      <Text
                        mb="5px"
                        textColor="#999999"
                        fontWeight="400px"
                        fontSize="12px"
                      >
                        Total price
                      </Text>
                      <Flex
                        alignItems="center"
                        bg="#F5F8FF"
                        p="10px"
                        borderRadius="8px"
                      >
                        <Box
                          flex="1"
                          textAlign="left"
                          fontWeight="400"
                          color="#999999"
                          fontSize="14px"
                        >
                          {userLocation == 'NG' ? '₦' : '$'}
                          {Math.round(
                            discountPrice * quantity
                          ).toLocaleString()}
                        </Box>
                      </Flex>
                    </Box>
                    <Flex>
                      <Flex
                        mt="1rem"
                        mb="30px"
                        width="100%"
                        flexDirection={'column'}
                      >
                        <Button
                          backgroundColor="#002E99"
                          textColor="white"
                          borderRadius="10px"
                          px="45px"
                          fontSize="16px"
                          fontWeight="700"
                          mr={{ base: '10px', md: '20px' }}
                          width="100%"
                          onClick={() => {
                            if (!isLoggedIn()) {
                              setShowLoginModal(true);
                            } else {
                              setShowPaymentDrawer(true);
                            }
                          }}
                          isDisabled={
                            !validEmail ||
                            email === '' ||
                            !quantity ||
                            quantity < 1
                          }
                        >
                          Buy Now
                          <Image src={buyIcon} mr="5px" alt="Buy Icon" />
                        </Button>

                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mt="1rem"
                        >
                          <Box
                            height="1px"
                            width="49%"
                            backgroundColor="#999"
                          />
                          <Text mx="10px">OR</Text>
                          <Box
                            height="1px"
                            width="49%"
                            backgroundColor="#999"
                          />
                        </Box>

                        <Button
                          border="1px solid #999999"
                          bg="white !important"
                          color="#000000"
                          borderRadius="10px"
                          px="35px"
                          fontSize="16px"
                          fontWeight="700"
                          mr={{ base: '10px', md: '20px' }}
                          mt="1rem"
                          width="100%"
                          onClick={() => {
                            if (!isLoggedIn()) {
                              setShowLoginModal(true);
                            } else {
                              handleAddToCart(selectedGiftCard);
                            }
                          }}
                          isDisabled={
                            isCartLoading ||
                            !validEmail ||
                            email === '' ||
                            !quantity ||
                            quantity < 1
                          }
                        >
                          Add to cart
                          <Image src={cartIcon} mr="5px" alt="Cart Icon" />
                        </Button>
                      </Flex>
                    </Flex>
                  </Flex>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          );
        })()}

      <Drawer
        isOpen={showPaymentDrawer}
        placement="right"
        onClose={onPaymentDrawerClose}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent p="10px" borderRadius="10px">
          <DrawerCloseButton />
          <Text mt="10px" ml="25px" fontWeight="700" fontSize="28px" mb="0">
            Payment Method
          </Text>
          <Text
            ml="25px"
            fontWeight="400"
            fontSize="14px"
            mt="0"
            mb="4"
            textColor="#999999"
          >
            Choose payment mode below to proceed.
          </Text>
          <DrawerBody>
            <Flex direction="column" mb="80px">
              <Box
                cursor={
                  selectedPaymentMethod === 'wallet' ? 'pointer' : 'not-allowed'
                }
                border="1px solid white"
                borderRadius="8px"
                p="10px"
                m="5px"
                mb="35px"
                flex="1"
                boxShadow="md"
                onClick={
                  selectedPaymentMethod === 'wallet'
                    ? () => setSelectedPaymentMethod('card')
                    : undefined
                }
                opacity="0.5"
                filter="grayscale(20%)"
              >
                <Flex alignItems="center">
                  <Image src={Card} w="50px" h="50px" mr="10px" />
                  <Box>
                    <Text fontSize="16px" fontWeight="700">
                      Pay with Card
                    </Text>
                    <Text fontSize="14px" fontWeight="400" color="#999999">
                      Pay with your debit or credit card for fast
                    </Text>
                    <Text fontSize="14px" fontWeight="400" color="#999999">
                      and secure payment.
                    </Text>
                  </Box>
                </Flex>
              </Box>

              <Box
                cursor="pointer"
                border={
                  selectedPaymentMethod === 'wallet'
                    ? '1px solid #002E99'
                    : '1px solid white'
                }
                borderRadius="8px"
                p="10px"
                m="5px"
                flex="1"
                boxShadow="md"
                onClick={handleBoxClick}
              >
                <Flex alignItems="center">
                  <Image src={Wallet} w="50px" h="50px" mr="10px" />
                  <Box>
                    <Text fontSize="16px" fontWeight="700">
                      Pay with Wallet
                    </Text>
                    <Text fontSize="14px" fontWeight="400" color="#999999">
                      Pay with your porteshop in-app wallet for
                    </Text>
                    <Text fontSize="14px" fontWeight="400" color="#999999">
                      seamless checkout.
                    </Text>
                  </Box>
                </Flex>
              </Box>
            </Flex>

            <Flex direction="column">
              {/* <Button
                backgroundColor="#002E99"
                mt="20px"
                onClick={handleProceedToPay}
                isLoading={isLoading}
                textColor="white"
                borderRadius="10px"
                disabled={!selectedPaymentMethod}
              >
                Proceed to Pay
              </Button> */}
              <Button
                border="1px solid #999999"
                backgroundColor="white"
                textColor="#999999"
                mt="20px"
                borderRadius="10px"
                onClick={onPaymentDrawerClose}
              >
                Go Back
              </Button>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Drawer
        isOpen={showWalletDrawer}
        placement="right"
        onClose={() => setShowWalletDrawer(false)}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent p="10px" borderRadius="10px">
          <DrawerCloseButton />
          <Text mt="10px" ml="25px" fontWeight="700" fontSize="28px" mb="0">
            Wallet Checkout
          </Text>
          <Text fontWeight="400" fontSize="14px" ml="25px">
            Confirm purchase details below before you proceed.
          </Text>
          <DrawerBody>
            <Flex direction="column" gap="4">
              {/* Top Part: Wallet Balance */}
              <Box boxShadow="md" p="4" borderRadius="md">
                <Flex alignItems="center" justifyContent="space-between">
                  <Flex alignItems="center">
                    <Image
                      src={Wallet}
                      alt="Wallet Icon"
                      boxSize="50px"
                      mr="2"
                    />
                    <Box>
                      <Text fontWeight="400" fontSize="12px" color="#999999">
                        Wallet Balance
                      </Text>
                      <Text fontWeight="500" fontSize="16px" color="#333333">
                        ₦{wallet?.toLocaleString()}
                      </Text>
                    </Box>
                  </Flex>
                  <Text
                    size="sm"
                    backgroundColor="#002E99"
                    textColor="white"
                    fontSize="12px"
                    fontWeight="700"
                    borderRadius="20px"
                    padding="6px 8px"
                    display="inline-block"
                    onClick={() => setShowModal('topup')}
                    cursor={'pointer'}
                  >
                    Top Up Wallet
                  </Text>
                </Flex>
              </Box>

              {/* Second Part: Item Details */}
              <Box bg="#F5F8FF" p="4" borderRadius="md">
                <Grid templateColumns="1fr 1fr" gap="3">
                  <Text fontWeight="400" fontSize="12px" color="#999999">
                    Gift Card Name
                  </Text>
                  <Text
                    fontWeight="400"
                    fontSize="12px"
                    color="#333333"
                    textAlign="right"
                  >
                    {selectedGiftCard?.name}
                  </Text>

                  <Text fontWeight="400" fontSize="12px" color="#999999">
                    Total Cost
                  </Text>
                  <Text
                    fontWeight="400"
                    fontSize="0.9rem"
                    color="#333333"
                    textAlign="right"
                  >
                    ₦{totalCost?.toLocaleString()}
                  </Text>
                </Grid>

                <Divider borderColor="#E0E0E0" my="2" />

                <Grid templateColumns="1fr 1fr" gap="3">
                  <Text fontWeight="400" fontSize="12px" color="#999999">
                    Wallet Balance After Payment
                  </Text>
                  <Text
                    fontWeight={wallet - totalPrice < 0 ? '600' : '700'}
                    fontSize={wallet - totalPrice < 0 ? '12px' : '0.9rem'}
                    color={wallet - totalPrice < 0 ? 'crimson' : '#333333'}
                    textAlign="right"
                  >
                    {wallet - totalPrice < 0
                      ? 'Insufficient Balance'
                      : `₦${(wallet - totalPrice)?.toLocaleString()}`}
                  </Text>
                </Grid>
              </Box>

              {selectedGiftCard?.deliveryType === 'DIGITAL' && (
                <>
                  {/* Delivery Details */}
                  <Text fontSize="14px" fontWeight="400">
                    Delivery Details
                  </Text>
                  <Box bg="#F5F8FF" p="4" borderRadius="md" boxShadow="md">
                    <Grid templateColumns="1fr 1fr" gap="3">
                      <Text fontWeight="400" fontSize="12px" color="#999999">
                        Email Address
                      </Text>
                      <Text
                        fontWeight="400"
                        fontSize="14px"
                        color="#333333"
                        textAlign="right"
                      >
                        {email}
                      </Text>
                    </Grid>
                  </Box>
                </>
              )}

              <Text mt="3" fontWeight="400" fontSize="14px" color="#999999">
                {selectedGiftCard?.deliveryType === 'WHATSAPP'
                  ? 'Please Note: Your gift card will be delivered via WhatsApp after purchase.'
                  : selectedGiftCard?.deliveryType === 'DIGITAL'
                  ? 'Please Note: Your gift card will be delivered via email after purchase.'
                  : ''}
              </Text>
            </Flex>

            {/* Bottom Part: Buttons */}
            <Flex direction="column" mt="56px">
              <Button
                backgroundColor={activeButton ? '#002E99' : '#CCCCCC'}
                textColor={activeButton ? 'white' : '#666666'}
                mb="24px"
                isDisabled={!activeButton}
                onClick={handlePaymentConfirmation}
              >
                Pay ₦{totalCost?.toLocaleString()} now
              </Button>
              <Button
                backgroundColor="white"
                border="1px solid #999999"
                textColor="#999999"
                onClick={() => setShowWalletDrawer(false)}
              >
                Go Back
              </Button>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {showModal === 'topup' && (
        <Modal isOpen={showModal} onClose={() => setShowModal('')}>
          <ModalOverlay />
          <ModalContent
            width="500px"
            height="auto"
            pt={'3rem'}
            mx={['1rem', 0]}
          >
            <Text textAlign="center" fontWeight="700" fontSize="24px">
              Top Up
            </Text>
            <Text
              textAlign="center"
              fontWeight="400"
              fontSize="16px"
              px={'2rem'}
            >
              Proceed to your Bank app to top-up your Gameporte Wallet
            </Text>

            <ModalCloseButton />
            <ModalBody>
              <Box p="1">
                <Box bg="#F5F8FF" p="2" m="1" borderRadius="md">
                  <Flex justifyContent={'space-between'} alignItems={'center'}>
                    <Text fontWeight="400" fontSize="12px" color="#999999">
                      Account Number
                      <Text fontWeight="400" fontSize="12px" color="#333333">
                        {walletDetails?.virtualBankAccountNumber}
                      </Text>
                    </Text>

                    <Text
                      size="sm"
                      backgroundColor="#002E99"
                      textColor="white"
                      fontSize="12px"
                      fontWeight="700"
                      borderRadius="20px"
                      padding="6px 8px"
                      w={'fit-content'}
                      onClick={() => setShowModal('topup')}
                      cursor={'pointer'}
                      textAlign={'right'}
                    >
                      <Flex
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        onClick={() => {
                          if (
                            navigator?.clipboard &&
                            walletDetails?.virtualBankAccountNumber
                          ) {
                            navigator.clipboard
                              .writeText(walletDetails.virtualBankAccountNumber)
                              .then(() => {
                                setCopied(true);
                                setTimeout(() => {
                                  setCopied(false);
                                }, 2000);
                              });
                          }
                        }}
                      >
                        {!copied ? (
                          <>
                            {' '}
                            <FaRegCopy style={{ marginRight: '.3rem' }} /> Copy
                          </>
                        ) : (
                          'Copied!'
                        )}
                      </Flex>
                    </Text>
                  </Flex>
                </Box>
                <Box bg="#F5F8FF" p="2" m="1" borderRadius="md">
                  <Text fontWeight="400" fontSize="12px" color="#999999">
                    Bank Name
                  </Text>
                  <Text fontWeight="400" fontSize="0.9rem" color="#333333">
                    {walletDetails?.virtualBankName}
                  </Text>
                </Box>
                <Box bg="#F5F8FF" p="2" m="1" borderRadius="md">
                  <Text fontWeight="400" fontSize="12px" color="#999999">
                    Account Name
                  </Text>
                  <Text fontWeight="400" fontSize="0.9rem" color="#333333">
                    {walletDetails?.virtualBankAccountName}
                  </Text>
                </Box>
              </Box>

              <PrimaryButton
                bg="#002E99 !important"
                color="#FFF"
                text="Close"
                width="100%"
                padding="1rem !important"
                marginBottom="30px"
                fontWeight="700"
                fontSize="14px"
                mt=".5rem"
                onClick={() => setShowModal('')}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {showModal === 'payment' && (
        <Modal isOpen={showModal} onClose={() => setShowModal('')}>
          <ModalOverlay />
          <ModalContent width="500px" height="516px">
            <Flex align="center">
              <Image
                textAlign="center"
                justifyContent="center"
                src={EnterPin}
                height="80px"
                width="80px"
                mx="auto"
                my="2rem"
                objectFit="cover"
              />
            </Flex>
            <Text textAlign="center" fontWeight="700" fontSize="24px">
              Enter Pin
            </Text>
            <Text textAlign="center" fontWeight="400" fontSize="16px">
              Enter your PIN to proceed and
            </Text>
            <Text textAlign="center" fontWeight="400" fontSize="16px" mb="24px">
              complete your payment.{' '}
            </Text>
            <ModalCloseButton />
            <ModalBody>
              <Box margin="auto" width={['85%', '70%']}>
                <Flex direction="row" justifyContent="center" gap="1rem">
                  <OtpInput
                    containerStyle={{
                      display: 'flex',
                      margin: '0 auto',
                      justifyContent: 'space-between',
                      gap: '1rem',
                      marginBottom: '30px',
                    }}
                    inputStyle={{
                      type: 'password',
                      height: '58px',
                      width: '58px',
                      background: '#C2D4FF',
                      color: '#000',
                      fontSize: '1.1rem',
                      fontWeight: '600',
                      borderRadius: '12px',
                      border: 'none',
                    }}
                    focusStyle={{
                      boxShadow: 'none',
                      outline: 'none',
                    }}
                    value={otp}
                    onChange={handleChange}
                    numInputs={4}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    isInputSecure={true}
                  />
                </Flex>
              </Box>
              <Flex justifyContent={'center'} my="1.3rem" direction="column">
                <PrimaryButton
                  bg="#002E99 !important"
                  color="#FFF"
                  text="Pay now"
                  width="100%"
                  padding="1rem !important"
                  marginBottom="30px"
                  fontWeight="700"
                  fontSize="14px"
                  onClick={() => handlePayGiftCard()}
                  isLoading={payLoading}
                  spinner={<Spinner size="sm" color="white" />}
                >
                  {' '}
                  {payLoading ? <Spinner size="sm" color="white" /> : 'Pay now'}
                </PrimaryButton>
                <PrimaryButton
                  border="1px solid #999999"
                  bg="white !important"
                  color="#999999"
                  text="Cancel"
                  width="100%"
                  padding="1rem !important"
                  fontWeight="700"
                  fontSize="14px"
                  onClick={() => setShowModal('')}
                />
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      <Modal isOpen={showSuccess} onClose={() => setShowSuccess(false)}>
        <ModalOverlay />
        <ModalContent borderRadius="md" maxWidth="450px" width="450px">
          <ModalHeader fontWeight="700" fontSize="24px" textAlign="center">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Image src={Success} boxSize="150px" alt="Icon" />
            </Box>
            Item added to cart!
          </ModalHeader>
          <ModalBody>
            <Text fontWeight="400" fontSize="14px" textAlign="center">
              Item has been added to cart successfully.
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="center" pb={10} px={6}>
            <Flex direction="column" alignItems="center">
              <Button
                onClick={() => {setShowSuccess(false); setRefetch((prev) => !prev);}}
                backgroundColor="#002E99"
                textColor="white"
                width="420px"
                mb={2}
                maxWidth="420px"
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
      >
        <ModalOverlay />
        <ModalContent width="500px" height="416px">
          <Flex align="center">
            <Image
              textAlign="center"
              justifyContent="center"
              src={PinSuccess}
              height="150px"
              width="150px"
              mx="auto"
              my="2rem"
              objectFit="cover"
            />
          </Flex>
          <ModalBody>
            <Box flexDirection="column" textAlign="center">
              <Text fontWeight="700" fontSize="24px">
                Payment Successful
              </Text>
              <Text fontWeight="400" fontSize="14px">
                Payment made successfully and gift card
              </Text>
              <Text fontWeight="400" fontSize="14px">
                has been sent to your email address.
              </Text>
              <Button
                mt="56px"
                backgroundColor="#002E99"
                textColor="white"
                width="100%"
                onClick={() => {setShowSuccessModal(false); setRefetch((prev) => !prev);}}
              >
                Close
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      <PrepaidLoginModal
          isOpen={showLoginModal}
          onClose={() => {setShowLoginModal(false); restoreImage();}}
          
        />
    </>
  );
};

export default GiftCardAvailable;
