import React, { useRef } from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  useBreakpointValue,
  Image,
  Divider,
  Button
} from "@chakra-ui/react";
import ViewAll from "../../assets/viewAll.png";
import { useNavigate } from "react-router-dom";

const HomeSectionRow = ({ 
  title, 
  data, 
  renderItem, 
  routePath, 
  footer,
  loading 
}) => {
  const scrollRef = useRef(null);
  const navigate = useNavigate();

  const handleScrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const viewAllItemsCount = useBreakpointValue({ base: 3, md: 5 });
  const ShowAllItemsCount = useBreakpointValue({ base: 5, md: 5 });
  const buttonTopPosition = useBreakpointValue({ base: "20px", md: "55px" });

  const handleSeeAllClick = () => {
    if (routePath) {
      navigate(routePath);
    }
  };

  return (
    <>
      <Flex justifyContent={"space-between"} alignItems="center">
        <Text
          fontWeight={"700"}
          color="#000119"
          fontSize={["1rem", "1.9rem"]}
          textAlign={"left"}
        >
          {title}
        </Text>
        <Flex alignItems="center" gap={2}>
          {data?.length >= ShowAllItemsCount && (
            <Text
              color={"#132867"}
              fontSize={"16px"}
              fontWeight={"700"}
              onClick={handleSeeAllClick}
              cursor={"pointer"}
              mr={2}
            >
              See all
            </Text>
          )}
          {footer && (
            <Button
              size="xs"
              bg={"white"}
            >
              View More
            </Button>
          )}
        </Flex>
      </Flex>
      <Divider mt={["2px", "15px"]} />
      <Box>
        <Flex
          position="relative"
          justifyContent={["start", "center"]}
          alignItems="center"
        >
          <Flex
            ref={scrollRef}
            overflowX={"scroll"}
            alignItems={"center"}
            gap={"1.2rem"}
            justifyContent={"left"}
            w={"100%"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "transparent",
              },
            }}
          >
            {data?.map(renderItem)}
          </Flex>
          {data?.length >= viewAllItemsCount && (
            <IconButton
              icon={<Image src={ViewAll} alt="Scroll right" />}
              onClick={handleScrollRight}
              position="absolute"
              top={buttonTopPosition}
              right="0"
              boxSize="130px"
              bg="transparent"
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              aria-label="Scroll right"
              transform="translateX(50%)"
            />
          )}
        </Flex>
      </Box>
    </>
  );
};

export default HomeSectionRow;