import React, { useState, useEffect } from "react";
import { Box, Flex, Text, IconButton, Grid, Button } from "@chakra-ui/react";
import MainLayout from "../../components/mainLayout/mainLayout";
import Footer from "../../components/footer/footer";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../assets/previous.svg";
import GameVoucherTopUpItem from "../../components/gameVoucherTopUp/gameVoucherTopUpItem";
import axiosInstance3 from "../../services/api2";
import SkeletonLayout from "../../components/SkeletonLayout";

const GameTopUp = () => {
  const [gameVouchersTopUp, setGameVouchersTopUp] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const fetchGameVouchers = async (currentPage) => {
    try {
      const response = await axiosInstance3.get("/games", {
        params: {
          // size: 30,
          page: currentPage,
          productType: 'VOUCHER'
        },
      });

      const { content, totalPages, last } = response.data.data;
      
      const mappedData = content
        .filter((item) => item.productType === "VOUCHER")
        .map((item) => ({
          id: item.ulid,
          title: item.name,
          image: item.lightModeImageUrl,
          name: item?.name,
          howToRedeem: item?.howToRedeem,
        }));

      setGameVouchersTopUp(prevItems =>
        currentPage === 0
          ? mappedData
          : [...prevItems, ...mappedData]
      );
      
      setTotalPages(totalPages);
      setHasMore(!last);
    } catch (error) {
      console.error('Error fetching game vouchers:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGameVouchers(0);
  }, []);

  const filteredItems = gameVouchersTopUp.filter((item) =>
    item.title.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleViewMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchGameVouchers(nextPage);
  };

  return (
    <MainLayout searchValue={searchValue} setSearchValue={setSearchValue}>
      <Box mt="120px">
        <Box px={{ base: "10px", md: "30px" }}>
        <Flex align="center" mb={4} justifyContent="space-between">
          <Flex align="center">
            <IconButton
              icon={<BackIcon />}
              mr="20px"
              variant="ghost"
              colorScheme="gray"
              cursor="pointer"
              onClick={handleBackClick}
            />
            <Text 
              fontSize="24px" 
              fontWeight="700"
              color="brand.primary"
            >
              Game Top-Up
            </Text>
          </Flex>

          {hasMore && !loading && (
            <Button
              bg={"white"}
              onClick={handleViewMore}
            >
              View More
            </Button>
          )}
        </Flex>
          
          {loading ? (
            <Box mt="50px">
              <SkeletonLayout />
            </Box>
          ) : filteredItems.length === 0 ? (
            <Text 
              textAlign="center" 
              color="gray.500" 
              mt={10}
            >
              No vouchers available for this game.
            </Text>
          ) : (
            <>
              <Grid
                templateColumns={{ 
                  base: "repeat(2, 1fr)", 
                  md: "repeat(4, 1fr)", 
                  lg: "repeat(6, 1fr)" 
                }}
                gap={4}
              >
                {filteredItems.map((item) => (
                  <GameVoucherTopUpItem
                    key={item.id}
                    id={item.id}
                    title={item.title}
                    image={item.image}
                    name={item?.name}
                    howToRedeem={item?.howToRedeem}
                  />
                ))}
              </Grid>
            </>
          )}
        </Box>
      </Box>
      <Box mt={8}>
        <Footer />
      </Box>
    </MainLayout>
  );
};

export default GameTopUp;