import axios from 'axios';
import axiosInstance2 from '../../services/UnAuthApi';

export const fetchCartItems = async () => {
  try {
    const response = await axiosInstance2.get('/cart');
    return response.data.data?.cartItems;
  } catch (error) {
    throw error;
  }
};
