import { Box, Text, List, ListItem, Image, Flex } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import BeneficiarySearchBar from '../../../components/BeneficiarySearchBar/BeneficiarySearchBar';

const Beneficiary = ({
  onSelect,
  beneficiaries,
  loading,
  hasMore,
  setPage,
}) => {
  const handleScroll = () => {
    if (!loading && hasMore) {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      const clientHeight =
        document.documentElement.clientHeight || window.innerHeight;
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    // Add event listener for scroll
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <Box
      maxWidth={['500px']}
      height="560px"
      width="100%"
      textAlign="left"
      p="28px"
    >
      <Flex direction="row" justifyContent="space-between">
        <Text fontSize="20px" fontWeight="700" mb="16px" pt="12px">
          Select beneficiary
        </Text>
        <Image
          src="/images/close.png"
          width={10}
          height={10}
          onClick={onSelect}
          cursor="pointer"
        />
      </Flex>
      <Box my="4px" maxHeight="400px" overflowY="auto">
        {' '}
        {/* Set maxHeight and overflowY */}
        {/* Your search bar component */}
      </Box>
      <List mt="16px" maxHeight="400px" overflowY="auto">
        {' '}
        {/* Set maxHeight and overflowY */}
        {beneficiaries.map((beneficiary, index) => (
          <ListItem
            key={index}
            padding="8px 0"
            borderBottom="1px solid #eee"
            onClick={() => onSelect(beneficiary)}
            cursor="pointer"
          >
            <Text fontWeight="700" fontSize="14px">
              {beneficiary.fullName}
            </Text>
            <Text color="#999999" fontSize="12px" fontWeight="400">
              {beneficiary.phoneNumber}
            </Text>
          </ListItem>
        ))}
        {loading && <Text>Loading...</Text>}
      </List>
    </Box>
  );
};

export default Beneficiary;
