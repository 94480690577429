import {
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react';

const CustomMenu = ({ data, label, selected, setSelected }) => {
  const handleSelect = (value) => {
    setSelected(value);
  };

  return (
    <>
      {label && (
        <Text mb=".35rem" color={'#999999'} fontSize={'.8rem'}>
          {label}
        </Text>
      )}
      <Menu matchWidth={true}>
        <MenuButton width="100%" boxShadow={'0px 0px 60px 3px #20202014'}>
          {selected}
        </MenuButton>
        <MenuList zIndex={'10'} bg="#FFF">
          {data?.map(({ title, value, icon }, index) => {
            return (
              <>
                {index > 0 && <Divider my=".2rem" />}
                <MenuItem
                  onClick={() => handleSelect(value)}
                  height="43px"
                  fontWeight={'500'}
                  fontSize={'.85rem'}
                >
                  <Flex gap=".5rem" alignItems="center">
                    <Box
                      height={'25px'}
                      width="25px"
                      borderRadius="50%"
                      backgroundImage={icon}
                      backgroundSize="cover"
                    />
                    <Text
                      fontSize={['.85rem', '.9rem']}
                      fontWeight="500"
                      color="#000119"
                    >
                      {title}
                    </Text>
                  </Flex>
                </MenuItem>
              </>
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
};

export default CustomMenu;
