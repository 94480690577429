import axiosInstance2 from './UnAuthApi';
import axiosInstance from './api';

export const GetWallet = async () => {
    try {
        const url = '/wallet';
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const CreateWallet = async (payload) => {
    try {
        const url = '/wallet';
        const res = await axiosInstance.post(url, payload);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const SaveWithdrawalBank = async (payload) => {
    try {
        const url = 'wallet/withdrawal-bank/save';
        const res = await axiosInstance.post(url, payload);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const WithdrawFromWallet = async (payload) => {
    try {
        const url = 'wallet/withdraw';
        const res = await axiosInstance.post(url, payload);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const TranssferFromWallet = async (payload) => {
    try {
        const url = 'wallet/transfer';
        const res = await axiosInstance.post(url, payload);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const GeneratePayoutToken = async (payload) => {
    try {
        const url = 'wallet/transfer/payout-token';
        const res = await axiosInstance.post(url, payload);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const TransferToBank = async (payload) => {
    try {
        const url = 'wallet/transfer/c2b';
        const res = await axiosInstance.post(url, payload);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const TransferToCustomer = async (payload) => {
    try {
        const url = 'wallet/transfer/b2c';
        const res = await axiosInstance.post(url, payload);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const ResetPin = async (payload) => {
    try {
        const url = 'wallet/reset-pin';
        const res = await axiosInstance.post(url, payload);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const ChargeFee = async (payload) => {
    try {
        const url = 'wallet/charge-fee';
        const res = await axiosInstance.post(url, payload);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const VerifyBank = async ({ bankId, accountNumber }) => {
    try {
        const url = `wallet/verify-bank/${bankId}/${accountNumber}`;
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const GetWithrawalBank = async () => {
    try {
        const url = 'wallet/withdrawal-bank/all';
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const GetTransactions = async (page) => {
    try {
        const url = 'wallet/transaction';
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const GetTransactionOverview = async () => {
    try {
        const url = 'wallet/transaction/overview';
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const GetAllTransactions = async () => {
    try {
        const url = 'wallet/transaction/all';
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const GetWalletParentAccount = async () => {
    try {
        const url = 'wallet/parent-accounts';
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const GetwalletDetails = async (username) => {
    try {
        const url = `wallet/details/${username}`;
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const GetBanks = async () => {
    try {
        const url = `wallet/banks`;
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const GetWalletBalance = async () => {
    try {
        const url = `wallet/balance`;
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const GetWalletParentBalance = async () => {
    try {
        const url = `wallet/balance/parent`;
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const DeleteWithdrawalBank = async (bankId) => {
    try {
        const url = `wallet/withdarwal-bank/${bankId}`;
        const res = await axiosInstance.delete(url);
        return res.data;
    } catch (err) {
        throw err;
    }
};
