import {
  Box,
  Text,
  Flex,
  useDisclosure,
  useToast,
  Spinner,
  Switch,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Image,
  Link,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import CustomMenu from '../../../../components/customMenu/customMenu';
import axiosInstance from '../../../../services/api';
import MTN from '../icons/mtn.svg';
import GLO from '../icons/glo.svg';
import Airtel from '../icons/airtel.svg';
import mobile from '../icons/9mobile.svg';
import { ChevronDownIcon } from '@chakra-ui/icons';
import SpinLoader from '../../../../components/spinLoader/spinLoader';
import CustomInput2 from '../../../../components/customInput/customInput';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import { useSelector } from 'react-redux';
import { GetWallet } from '../../../../services/wallet.api';
import Warn from '../../../../assets/warn.svg';
import Success from '../../../../assets/success.svg';
import OtpInput from 'react-otp-input';
import { truncateText } from '../../../../utils/helper';
import Beneficiary from '../../beneficiary/beneficiary';
import Beneficiary2 from '../beneficiary';
import { formatWholeNumber } from '../../../../utils/toLocaleString';
import axiosInstance2 from '../../../../services/UnAuthApi';
import DataTabs from './dataTabs';
import BeneficiaryModal from '../modals/beneficiaryModal';
import Beneficiary2Modal from '../modals/beneficiary2Modal';

const quickPicks = [50, 100, 200, 500, 1000, 2000];
const purchaseType = ['AIRTIME', 'DATA', 'VOUCHER'];

const DataPurchase = ({ selectedIndex }) => {
  const [billers, setBillers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBiller, setSelectedBiller] = useState(selectedIndex);
  const airtimeId = JSON.parse(localStorage.getItem('airtimeId'));
  const [wallet, setWallet] = useState(null);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const currentUser = useSelector(({ userData }) => userData?.currentUser);
  const [type, setType] = useState(purchaseType[0]);
  const [plans, setPlans] = useState([]);
  const [loading2, setLoading2] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [request, setRequest] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [fullName, setFullName] = useState('');
  const [loadingx, setLoadingx] = useState(false);
  const [saveBeneficiary, setSaveBeneficiary] = useState(false);
  const [phone, setPhone] = useState('');
  const [amount, setAmount] = useState('');
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [success, setSuccess] = useState(false);

  const [otp, setOtp] = useState('');

  const handleSaveBeneficiary = async (data) => {
    let { phoneNumber, fullName, type } = data;
    const response = axiosInstance.post('/vas/beneficiary', data);
  };

  // const saveBeneficiary = async() => {
  //   try {
  //     const {data} = await axiosInstance
  //   } catch (error) {
  //   }
  // }
  const isLoggedIn = () => {
    const token = localStorage.getItem('GPV1TK');
    return !!token;
  };

  const {
    isOpen: isBeneficiaryOpen,
    onOpen: onBeneficiaryOpen,
    onClose: onBeneficiaryClose,
  } = useDisclosure();

  const {
    isOpen: isNewBeneficiaryOpen,
    onOpen: onNewBeneficiaryOpen,
    onClose: onNewBeneficiaryClose,
  } = useDisclosure();

  const handleChange = (e) => {
    setOtp(e);
  };

  const beneficiaryDisplay = () => {
    onBeneficiaryOpen();
  };

  const getBillers = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get('vas/billers?category=data');
      setBillers(data?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getWallet = async () => {
    setLoading(true);
    try {
      const res = await GetWallet();
      setWallet(res?.data?.balance);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getBeneficiaries = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/vas/beneficiary');
      setBeneficiaries(response?.data?.data?.content);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  function getLastTenDigits(phoneNumber) {
    // Remove any non-digit characters from the phone number
    const digitsOnly = phoneNumber.replace(/\D/g, '');

    // Extract the last 10 digits
    const lastTenDigits = digitsOnly.slice(-10);

    return lastTenDigits;
  }

  const handleBeneficiarySelect = (beneficiary) => {
    if (beneficiary && beneficiary.phoneNumber) {
      setPhone(`${getLastTenDigits(beneficiary.phoneNumber)}`);
    } else {
      setPhone(''); // Set phone to empty string if beneficiary is undefined
    }
    onBeneficiaryClose();
  };
  const getBillerImage = (name) => {
    switch (name) {
      case 'MTN':
        return MTN;
      case 'GLO':
        return GLO;
      case 'Airtel':
        return Airtel;
      case '9mobile':
        return mobile;
      default:
        return null;
    }
  };
  const selectedBillerName =
    selectedBiller?.attributes?.name ||
    billers.find((biller) => biller.attributes.name === selectedIndex)
      ?.attributes?.name;

  const selectedBillerImage = getBillerImage(selectedBillerName);

  useEffect(() => {
    getWallet();
  }, [request]);

  useEffect(() => {
    getBillers();
    getBeneficiaries();
  }, []);

  const getPlans = async () => {
    setLoading2(true);
    try {
      const { data } = await axiosInstance.get(
        `vas/billers/${
          selectedBiller?.id ||
          billers.find((biller) => biller.attributes.name === selectedIndex)?.id
        }/products?category=data`
      );
      setPlans(data?.data);
    } catch (error) {
    } finally {
      setLoading2(false);
    }
  };

  useEffect(() => {
    if (billers?.length > 0) {
      getPlans();
    }
  }, [billers, selectedBiller]);

  const billersData = billers
    ?.filter((i) => {
      const selectedBillerId = selectedBiller?.id;
      const airtimeBillerId =
        airtimeId !== null && airtimeId !== undefined
          ? billers[airtimeId]?.id
          : null;

      if (selectedBillerId) {
        return i.id !== selectedBillerId;
      } else if (airtimeBillerId) {
        return true;
      }

      return true; // Include all items if both selectedBiller?.id and airtimeId are null
    })
    ?.map((i) => ({
      title: i?.attributes?.name,
      value: {
        id: i?.id,
        type: i?.type,
        attributes: i?.attributes,
      },
      icon:
        i?.attributes?.name === 'MTN'
          ? MTN
          : i?.attributes?.name === 'GLO'
          ? GLO
          : i?.attributes?.name === 'Airtel'
          ? Airtel
          : i?.attributes?.name === '9mobile'
          ? mobile
          : null || MTN,
    }));

  // const plansData = plans.map()

  const handleBuy = (val) => {
    if (
      phone !== '' &&
      wallet?.availableBalance >= val?.attributes?.price?.maximumAmount
    ) {
      setSelectedPlan(val);
      setAmount(val?.attributes?.price?.maximumAmount);
      onOpen();
    } else if (
      wallet?.availableBalance < val?.attributes?.price?.maximumAmount
    ) {
      toast({
        description: 'Balance Insufficient',
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        description: 'Enter recipient phone number',
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const purchase = async () => {
    setLoadingx(true);
    const datax = {
      amount: selectedPlan?.attributes?.price?.maximumAmount,
      productSlug: selectedPlan?.attributes?.slug,
      phoneNumber:
        phone.charAt(0) === '0' ? '234' + phone.slice(1) : '234' + phone,
      walletPin: otp,
    };
    if (saveBeneficiary) {
      datax.fullName = fullName;
    }
    try {
      const { data } = await axiosInstance.post('vas/data', datax);

      const isBeneficiary = beneficiaries.some(
        (beneficiary) => beneficiary.phoneNumber === datax.phoneNumber
      );

      if (isBeneficiary) {
        setSaveBeneficiary(false);
      } else {
        if (saveBeneficiary) {
          await handleSaveBeneficiary({
            ...datax,
            type: purchaseType[0],
          });
        } else {
          onNewBeneficiaryOpen();
        }
      }
      onOpen();
      setSuccess(true);
      // setAmount("");
      // setPhone("");
      setFullName('');

      if (!isBeneficiary) {
        // If the phone number is not in beneficiaries, open the modal to add a new beneficiary
        onNewBeneficiaryOpen();
      } else {
        // If the phone number is in beneficiaries, show payment success modal
        onOpen();
        setSuccess(true);
      }
    } catch (error) {
      if (error?.response?.status === 500 || error?.response?.status === 501) {
        toast({
          title: '',
          description:
            'An error occurred while processing this request, please contact administrator',
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'An Error Occurred',
          description: error?.response?.data?.errors[0],
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      }
      setRequest(Math.floor(Math.random() * 90) + 10);
    } finally {
      setLoadingx(false);
    }
  };

  const borderColor =
    selectedBiller?.attributes?.name === 'Airtel' || selectedBiller === 'Airtel'
      ? '#FF5252'
      : selectedBiller?.attributes?.name === 'MTN' || selectedBiller === 'MTN'
      ? 'goldenrod'
      : '#15A336';

  const borderTop = `6px solid ${borderColor}`;
  return (
    <>
      {loading ? (
        <SpinLoader color="#002E99" />
      ) : (
        <>
          {!isLoggedIn() ? (
            <Text>
              Kindly{' '}
              <Link
                href={'/login'}
                style={{
                  fontWeight: '700',
                  cursor: 'pointer',
                  color: '#002E99',
                  opacity: '1 !important',
                }}
              >
                {' '}
                Log in{' '}
              </Link>{' '}
              or{' '}
              <Link
                href="https://app.gameporte.com/"
                isExternal
                style={{
                  fontWeight: '700',
                  cursor: 'pointer',
                  color: '#002E99',
                  opacity: '1 !important',
                }}
              >
                Create an account{' '}
              </Link>{' '}
              to purchase Data
            </Text>
          ) : (
            <Text
              mt="-.5rem"
              fontSize={'.87rem'}
              color="#000119"
              opacity={'.5'}
            >
              Enter purchase details below to proceed.
            </Text>
          )}
          <br />
          <br />
          <CustomMenu
            label={'Network Provider'}
            setSelected={setSelectedBiller}
            data={billersData}
            selected={
              <Flex
                backgroundSize="cover"
                bg="#F5F8FF"
                height="50px"
                alignItems="center"
                borderRadius={'8px !important'}
                px=".65rem"
                justifyContent={'space-between'}
              >
                <Flex gap=".5rem" alignItems="center">
                  <Box
                    height={'25px'}
                    width="25px"
                    borderRadius="50%"
                    backgroundImage={`url(${selectedBillerImage})`}
                    backgroundSize="cover"
                  />
                  <Text
                    fontSize={['.85rem', '.9rem']}
                    fontWeight="500"
                    color="#000119"
                  >
                    {selectedBillerName}
                  </Text>
                </Flex>
                <ChevronDownIcon />
              </Flex>
            }
          />

          <CustomInput2
            type={'tel'}
            required
            bg="#F5F8FF"
            placeholder={'Enter phone number'}
            onChange={(e) => setPhone(e.target.value)}
            border="0"
            borderRadius={'8px !important'}
            _placeholder={{ color: '#999999', fontSize: '.85rem' }}
            label="Phone number"
            label3={currentUser && 'Select Beneficiary'}
            beneficialOnClick={beneficiaryDisplay}
            minLength={11}
            maxLength={11}
            mb="1rem"
            value={phone || ''}
          />

          {saveBeneficiary && (
            <>
              <CustomInput2
                value={fullName}
                label={'Full Name'}
                type={'text'}
                bg="#F5F8FF"
                placeholder={'Enter full name'}
                border="0"
                borderRadius={'8px !important'}
                _placeholder={{ color: '#999999', fontSize: '.85rem' }}
                setValue={(e) => setFullName(e.target.value)}
                onChange={(e) => setFullName(e.target.value)}
              />
            </>
          )}

          <Box maxWidth="800px" mx="auto" mt=".5rem">
            <DataTabs
              selectedBiller={selectedBiller}
              loading2={loading2}
              plans={plans}
              handleBuy={handleBuy}
              borderTop={borderTop}
            />
          </Box>

          <CustomInput2
            type={'tel'}
            required
            bg="#F5F8FF"
            placeholder={'0.00'}
            border="0"
            borderRadius={'8px !important'}
            _placeholder={{ color: '#999999', fontSize: '.85rem' }}
            label="Amount"
            label3="Fee: 0"
            value={amount}
            isDisabled
          />

          <br />
          {currentUser && (
            <Flex alignItems="center">
              <Switch
                isChecked={saveBeneficiary}
                onChange={(e) => setSaveBeneficiary(e.target.checked)}
              />
              <Text ml={2} fontSize="14px" fontWeight="400" color="#999999">
                Save Beneficiary
              </Text>
            </Flex>
          )}
          <br />
          {currentUser && (
            <Text fontSize={'.87rem'} color="#000119" opacity={'.5'}>
              Wallet Balance:{' '}
              <strong
                style={{
                  color: wallet?.availableBalance < amount ? 'red' : '',
                  opacity: '1 !important',
                }}
              >
                {' '}
                {wallet
                  ? `₦${formatWholeNumber(wallet?.availableBalance)}`
                  : 'loading...'}
              </strong>
            </Text>
          )}
          <br />
          <br />
        </>
      )}

      <Modal
        isCentered
        motionPreset="slideInBottom"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={'1rem'} textTransform={'capitalize'}>
            {}
          </ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody>
            {success ? (
              <>
                <Image src={Success} width={'25%'} mb="1rem" mx="auto" />
                <Text
                  textAlign={'center'}
                  fontSize={'1.2rem'}
                  mb=".2rem"
                  color={'#000119'}
                  fontWeight={'700'}
                >
                  Payment Successful!
                </Text>
                <Text
                  mb="3rem"
                  textAlign={'center'}
                  color={'#475467'}
                  fontWeight={'500'}
                >
                  Data purchase has been processed successfully.
                </Text>
                <Flex justifyContent={'center'} my="1.3rem">
                  <PrimaryButton
                    onClick={onClose}
                    bg="#002E99 !important"
                    color="#FFF"
                    text="Close"
                    width="100%"
                    padding="1rem !important"
                    loading={false}
                  />
                </Flex>
              </>
            ) : (
              <>
                <Image
                  src={Warn}
                  width={'15%'}
                  mb="1rem"
                  bg="#F2994A1F"
                  borderRadius={'50%'}
                  p=".8rem"
                  mx="auto"
                />
                <Text
                  textAlign={'center'}
                  fontSize={'1.2rem'}
                  mb=".2rem"
                  color={'#000119'}
                  fontWeight={'700'}
                >
                  Puchase {selectedPlan?.attributes?.slug} data plan for ₦
                  {formatWholeNumber(
                    selectedPlan?.attributes?.price?.maximumAmount
                  )}
                </Text>
                {/* <Text textAlign={"center"} color={"#475467"} fontWeight={"500"} mb="1rem">Do you want to proceed and top-up ₦{selectedPlan?.attributes?.price?.maximumAmount?.toLocaleString()} airtime on {phone}</Text> */}

                <Box margin="2.5rem auto" width={['85%', '70%']}>
                  <Text
                    textAlign={'center'}
                    mb=".5rem"
                    fontWeight={'500'}
                    fontSize={'.9rem'}
                  >
                    Enter wallet pin
                  </Text>
                  <OtpInput
                    containerStyle={{
                      display: 'flex',
                      margin: '0 auto',
                      justifyContent: 'space-between',
                      gap: '1rem',
                    }}
                    inputStyle={{
                      height: '58px',
                      width: '58px',
                      background: '#F5F8FF',
                      color: '#000',
                      fontSize: '1.1rem',
                      fontWeight: '600',
                      borderRadius: '12px',
                      border: 'none',
                    }}
                    focusStyle={{
                      boxShadow: 'none',
                      outline: 'none',
                    }}
                    value={otp}
                    onChange={handleChange}
                    numInputs={4}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    isInputSecure={true}
                  />
                </Box>

                <Flex justifyContent={'center'} my="1.3rem">
                  <PrimaryButton
                    onClick={purchase}
                    bg="#002E99 !important"
                    color="#FFF"
                    text="Top Up"
                    width="100%"
                    padding="1rem !important"
                    loading={loadingx}
                  />
                </Flex>
              </>
            )}
            {success === false && (
              <Text
                pb="1rem"
                textAlign={'center'}
                fontSize={'.9rem'}
                fontWeight={'500'}
                cursor={'pointer'}
                onClick={onClose}
              >
                Cancel
              </Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* <Modal
        isCentered
        motionPreset="slideInBottom"
        isOpen={isBeneficiaryOpen}
        onClose={onBeneficiaryClose}
      >
        <ModalOverlay />
        <ModalContent height="auto" mx={["1rem", 0]}>
          <ModalBody>
            <Beneficiary
              onSelect={handleBeneficiarySelect}
              beneficiaries={beneficiaries}
              hasMore={hasMore}
              loading={loading}
              setPage={setPage}
            />
          </ModalBody>
        </ModalContent>
      </Modal> */}

      <BeneficiaryModal
        isBeneficiaryOpen={isBeneficiaryOpen}
        onBeneficiaryClose={onBeneficiaryClose}
        handleBeneficiarySelect={handleBeneficiarySelect}
        beneficiaries={beneficiaries}
        hasMore={hasMore}
        loading={loading}
        setPage={setPage}
      />

      {/* <Modal
        isOpen={isNewBeneficiaryOpen}
        onClose={onNewBeneficiaryClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent height="auto" mx={["1rem", 0]}>
          <ModalBody>
            <Beneficiary2
              onSelect={onNewBeneficiaryClose}
              phoneNumber={phone}
              onClose={onNewBeneficiaryClose}
            />
          </ModalBody>
        </ModalContent> 
      </Modal> */}
      <Beneficiary2Modal
        isNewBeneficiaryOpen={isNewBeneficiaryOpen}
        onNewBeneficiaryClose={onNewBeneficiaryClose}
        phone={phone}
      />
    </>
  );
};

export default DataPurchase;
