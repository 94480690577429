import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Text, useToast } from '@chakra-ui/react';

const GiftCardTopUpItem = ({ id, title, image, name, howToRedeem }) => {
  const navigate = useNavigate();
  const toast = useToast();

  const handleGiftCardClick = () => {
    try {
      if (!id) {
        throw new Error('No gift cards available.');
      }
      navigate(`/gift-card/${id}`, {
        state: { imageUrl: image, name: name, howToRedeem: howToRedeem },
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      key={id}
      onClick={handleGiftCardClick}
      cursor={id ? 'pointer' : 'not-allowed'}
      opacity={id ? '1' : '0.5'}
      border="1px solid none"
      borderRadius="10px"
      paddingX="2px"
      paddingY="5px"
      boxShadow="md"
      maxW="200px"
      mx="auto"
      mb="1rem"
      position="relative" 
    >
      <Box
        height={['165px', '200px']}
        width={['129px', '170px']}
        overflow="hidden"
        backgroundImage={`url(${image})`}
        backgroundSize="cover"
        backgroundPosition="center"
        borderRadius="md"
        position="relative"
      >
      
        <Box
          position="absolute"
          top={["-5", "0"]} 
          left={["5", "12"]}
          width="100%"
          height="100%"
          clipPath="path('M35 0C32.2386 0 30 2.23858 30 5H5C2.23858 5 0 7.23858 0 10C0 12.7614 2.23858 15 5 15H65C67.7614 15 70 12.7614 70 10C70 7.23858 67.7614 5 65 5H40C40 2.23858 37.7614 0 35 0Z')" 
          backgroundColor="white" 
          transform={["scale(0.7)", "scale(1)"]}
        />
      </Box>

      <Text
        fontSize={['.8rem', '16px']}
        mt=".6rem"
        textAlign="center"
        color="#000119"
        fontWeight="700"
        maxW="171px"
        mx="auto"
      >
        {title}
      </Text>
    </Box>
  );
};

export default GiftCardTopUpItem;
