import React, { useState } from 'react';
import HomeSectionRow from '../../components/homeSectionRow/homeSectionRow';
import { Box, Text, Flex } from '@chakra-ui/react';
import Glo from '../../assets/glo.png';
import Airtel from '../../assets/airtel.png';
import Mobile9 from '../../assets/9mobile.png';
import Mtn from '../../assets/mtn.png';
import AirtimeData from '../../pages/products/airtimeData/airtimeData';
import CustomDrawer from '../customDrawer/customDrawer';
import PrepaidLoginModal from './prepaidLoginModal';

const isLoggedIn = () => {
  const token = localStorage.getItem('GPV1TK');
  return !!token;
};

const prepaidData = [
  { title: 'GLO', image: Glo },
  { title: 'Airtel', image: Airtel },
  { title: '9mobile', image: Mobile9 },
  { title: 'MTN', image: Mtn },
];

const PrepaidTopUp = ({ searchValue, selectedIndex, setSelectedIndex }) => {
  const [showLoginModal, setShowLoginModal] = useState(false);

  const filterItems = (items) => {
    return items.filter((item) => 
      item.title.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  const filteredData = filterItems(prepaidData);

  if (filteredData.length === 0) {
    return null;
  }


  return (
    <>
      <HomeSectionRow
        title="Prepaid Top-up"
        data={filterItems(prepaidData)}
        renderItem={(item, index) => (
          <>
            <CustomDrawer
              key={item.title}
              position={'right'}
              title={'Prepaid Top-Up'}
              triggerClose={false}
              isOpen
              toggleElement={
                <Flex
                  flexWrap="wrap"
                  justifyContent="center"
                  alignItems="center"
                  gap="1.2rem"
                >
                  <Box
                    cursor={'pointer'}
                    onClick={() => {
                      localStorage.setItem('airtimeId', JSON.stringify(index));
                      setSelectedIndex(item.title);
                    }}
                  >
                    <Box
                      height={['165px', '245px']}
                      width={['129px', '190px']}
                      overflow="hidden"
                      backgroundImage={`url(${item.image})`}
                      backgroundSize="cover"
                      backgroundPosition="center"
                      borderRadius="md"
                    />
                    <Text
                      fontSize={['.8rem', '16px']}
                      mt=".6rem"
                      textAlign={'center'}
                      cursor={'pointer'}
                      color="#000119"
                      fontWeight={'700'}
                      maxW={'171px'}
                    >
                      {item.title}
                    </Text>
                  </Box>
                </Flex>
              }
              content={<AirtimeData selectedIndex={selectedIndex} />}
            />

            {/* {!isLoggedIn() && (
              <Flex
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                gap="1.2rem"
              >
                <Box
                  cursor={'pointer'}
                  onClick={() => setShowLoginModal(true)}
                  gap={'.9rem !important'}
                >
                  <Box
                    height={['165px', '245px']}
                    width={['129px', '171px']}
                    overflow="hidden"
                    backgroundImage={`url(${item.image})`}
                    backgroundSize="cover"
                    backgroundPosition="center"
                    borderRadius="md"
                  />
                  <Text
                    fontSize={['.8rem', '.9rem']}
                    mt=".6rem"
                    textAlign={'center'}
                    cursor={'pointer'}
                    color="#000119"
                    fontWeight={'600'}
                    maxW={'171px'}
                  >
                    {item.title}
                  </Text>
                </Box>
              </Flex>
            )} */}
          </>
        )}
      />

      <PrepaidLoginModal
        isOpen={showLoginModal}
        onClose={() => setShowLoginModal(false)}
      />
    </>
  );
};

export default PrepaidTopUp;
